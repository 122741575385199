import { FC } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth, AuthContextValue } from "../context/AuthContext";
import Loading from "../components/loader/loader";
import MasterLayout from "../components/layout/masterLayout";

// Since your component doesn't have any specific props, you can use an empty interface
interface UserRouterProps {}

const UserRouter: FC<UserRouterProps> = () => {
  const { user, isLoading } = useAuth() as AuthContextValue;
  let location = useLocation();

  if (isLoading) {
    return <Loading />;
  }
  if (user === null) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <MasterLayout>
      <Outlet />
    </MasterLayout>
  );
};

export default UserRouter;
