import DataByUser from "../components/data/DataByUser";
import { useState } from "react";
import StrategyDataByUser from "../components/data/StrategyDataByUser";
import ConsultancyDataByUser from "../components/data/ConsultancyDataByUser";

export default function MyData() {
  const [activeTable, setActiveTable] = useState("DataTable");

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Benim Datam
          </h1>
        </div>
        <div className="ml-4 py-4">
          <button
            className="bg-gray-600 text-white text-sm px-4 py-2 rounded-md mr-4"
            onClick={() => setActiveTable("DataTable")}
          >
            Genel Data{" "}
          </button>
          <button
            className="bg-gray-600 text-white text-sm px-4 py-2 rounded-md mr-4"
            onClick={() => setActiveTable("FormDataTable")}
          >
            Strateji Görüşmesi Datası
          </button>
          <button
            className="bg-gray-600 text-white text-sm px-4 py-2 rounded-md mr-4"
            onClick={() => setActiveTable("ConsultancyFormDataTable")}
          >
            Danışmanlık Görüşmesi Datası
          </button>
        </div>
        {activeTable === "DataTable" && (
          <div>
            <h1 className="text-4xl text-left ml-4 font-bold tracking-tight text-gray-900">
              Genel Data
            </h1>
            <div className="py-4">
              <DataByUser />
            </div>
          </div>
        )}
        {activeTable === "FormDataTable" && (
          <div>
            <h1 className="text-4xl text-left ml-4 font-bold tracking-tight text-gray-900">
              Strateji Görüşmesi Datası
            </h1>
            <div className="py-4">
              <StrategyDataByUser />
            </div>
          </div>
        )}
        {activeTable === "ConsultancyFormDataTable" && (
          <div>
            <h1 className="text-4xl text-left ml-4 font-bold tracking-tight text-gray-900">
              Danışmanlık Görüşmesi Datası
            </h1>
            <div className="py-4">
              <ConsultancyDataByUser />
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
