import React from "react";

interface Props {
  data: any;
}
const AdStatsTable: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl my-8">
      {/* <div className="flow-root"> */}
      <div className="relative overflow-x-auto overflow-y-auto">
        <div className="inline-block min-w-full align-middle"></div>
        <table className="min-w-full leading-normal">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Pazaryeri
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Tarih
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Ücret
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Para Birimi
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((order: any, index: number) => {
              return (
                <tr key={index} className="bg-white">
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {order.marketplaceName}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {new Date(order.dailyVersionDate).toLocaleDateString(
                      "tr-TR"
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {Number(order.dailyTransactionValueAmount).toFixed(2)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {order.dailyTransactionValueCurrency}
                  </td>
                  
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdStatsTable;
