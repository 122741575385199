import React, { useState, useEffect } from "react";
import axios from "axios";
import HabitForm from "../components/habit/HabitForm";
import Habits from "../components/habit/Habits";
import { useAuth, AuthContextValue } from "../context/AuthContext";

interface Habit {
  id: string;
  name: string;
  days: {
    day: number;
    month: string;
    year: number;
    checked: boolean;
  }[];
}

const HabitPage: React.FC = () => {
  const [habits, setHabits] = useState<Habit[]>([]);
  const { user } = useAuth() as AuthContextValue;

  const url = process.env.REACT_APP_API_URL;
  const fetchHabits = async () => {
    try {
      const response = await axios.get(`${url}/api/habits`, {
        params: { userId: user?.id },
      });
      const habitsData = response.data.data;
      // Map the habit objects to have an 'id' property instead of '_id'
      const mappedHabits = habitsData.map((habit: any) => ({
        id: habit._id,
        name: habit.name,
        days: habit.days,
      }));

      setHabits(mappedHabits);
    } catch (error) {
      console.error("Error fetching habits:", error);
      return [];
    }
  };
  const onHabitsChange = () => {
    fetchHabits();
  };
  const refetchHabits = (updatedHabits: Habit[]) => {
    // Update the state with the new habits array
    setHabits(updatedHabits);
  };
  useEffect(() => {
    fetchHabits();
  }, []);

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Zinciri Kırma (Alışkanlık Takibi)
          </h1>
        </div>
        <div className="py-4">
        <HabitForm onHabitAdded={onHabitsChange} userId={user?.id} />
        <Habits habits={habits} onHabitAdded={refetchHabits} onHabitDeleted={onHabitsChange} />
        </div>
      </main>
    </div>
  );
}

export default HabitPage;