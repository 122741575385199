import ActiveMembersChart from "../components/members/ActiveMembersChart";
import MemberStats from "../components/members/MemberStats";
import MemberTable from "../components/members/MemberTable";
import UsageStats from "../components/metrics/UsageStats";

export default function AllMembers() {

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Rexven üyelerimiz
          </h1>
        </div>
        <div>
        <UsageStats/>
        </div>
        <div className="py-4">
        <MemberTable/>
        </div>
      </main>
    </div>
  );
}
