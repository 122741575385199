import { useState, useEffect } from "react";
import axios from "axios";
import {formatDateTR} from "../../helpers/date";
import { useAuth, AuthContextValue } from "../../context/AuthContext";
import { TrashIcon, PencilIcon } from "@heroicons/react/20/solid";


interface Todo {
  _id: string
  name: string;
  date: string;
  completed: boolean;
};

export default function TodoList(): JSX.Element {
  const [todos, setTodos] = useState<Todo[]>([]);
  const [newTodo, setNewTodo] = useState<string>("");
  const [editingTodo, setEditingTodo] = useState<Todo | null>(null);

  const url = process.env.REACT_APP_API_URL;
  const { user } = useAuth() as AuthContextValue;

  
  const handleAddTodo = async (): Promise<void> => {
    if (editingTodo) {
      handleUpdateTodo();
      return;
    }

    if (newTodo.trim()) {
      try {
        const response = await axios.post<{ data: Todo }>(`${url}/api/todos`, {
          name: newTodo,
          date: new Date().toLocaleString('en-US', { timeZone: 'Europe/Istanbul' }),
          completed: false,
          user: user?.id,
        });

        const createdTodo = response.data.data;
        setTodos([...todos, createdTodo]);
        setNewTodo("");
      } catch (error) {
        console.error("Error creating todo:", error);
      }
    }
  };

  const handleDeleteTodo = async (id: string): Promise<void> => {
    await axios.delete(`${url}/api/todos/${id}`);
    setTodos(todos.filter((todo) => todo._id !== id));
  };

  const handleToggleComplete = async (id: string): Promise<void> => {
    const todo = todos.find((todo) => todo._id === id);
    if (todo) {
      const updatedTodo = { ...todo, completed: !todo.completed };
      await axios.put(`${url}/api/todos/${id}`, updatedTodo);
      setTodos(
        todos.map((todo) =>
          todo._id === id ? { ...todo, completed: !todo.completed } : todo
        )
      );
    }
  };

  const handleEditStart = (id: string): void => {
    const todoToEdit = todos.find((todo) => todo._id === id);
    if (todoToEdit) {
      setEditingTodo(todoToEdit);
      setNewTodo(todoToEdit.name);
    }
  };

  const handleUpdateTodo = async (): Promise<void> => {
    if (newTodo.trim() && editingTodo) {
      try {
        await axios.put(`${url}/api/todos/${editingTodo._id}`, {
          name: newTodo,
          date: editingTodo.date,
          completed: editingTodo.completed,
        });
        setTodos(
          todos.map((todo) =>
            todo._id === editingTodo._id ? { ...todo, name: newTodo } : todo
          )
        );
      } catch (error) {
        console.error("Error updating todo:", error);
      }
      setEditingTodo(null);
      setNewTodo("");
    }
  };

  useEffect(() => {
    
    const fetchTodos = async (): Promise<void> => {
      const response = await axios.get<{ data: Todo[] }>(`${url}/api/todos`, {
        params: { userId: user?.id },
      });
      setTodos(response.data.data);
    };

    fetchTodos();
  }, []);

  return (
    <div>
      
      <div className="bg-white drop-shadow-2xl py-12 max-w-6xl mx-auto mt-10 rounded-xl ">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-black">
                Yapacak Listem (to-do)
              </h1>
              <p className="mt-2 text-sm text-gray-600">
                Günlük olarak yapmanız gereken işleri takip edebilirsiniz
              </p>
            </div>
            <div className="sm:flex-auto">
              <input
                type="text"
                value={newTodo}
                onChange={(e) => setNewTodo(e.target.value)}
                className="border border-red-600 p-2 rounded w-full"
                placeholder="Yeni iş ekle ... "
              />
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                onClick={handleAddTodo}
                className="block rounded-md bg-yellow-400 text-black py-2 px-3 text-center text-sm font-semibold hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
              >
                {editingTodo ? "Düzenle" : "Ekle"}
              </button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-black sm:pl-0"
                      >
                        Yapılacak iş
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 px-3 text-left text-sm font-semibold text-black"
                      >
                        Tarih
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Düzenle</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-800">
                    {todos.map((todo) => (
                      <tr key={todo._id}>
                        <td
                          className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-black sm:pl-0 ${
                            todo.completed ? "line-through" : ""
                          }`}
                        >
                          {todo.name}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-800">
                          {formatDateTR(todo.date)}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <button
                            onClick={() => handleToggleComplete(todo._id)}
                            className="text-red-600 hover:text-gray-900"
                          >
                            Tamamla
                            <span className="sr-only">, {todo.name}</span>
                          </button>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <button
                            onClick={() => handleEditStart(todo._id)}
                            className="p-1 text-gray-700 hover:text-red-600"
                          >
                            <PencilIcon className="h-8 w-6" />
                          </button>
                        </td>
                        <td
                          className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium
sm:pr-0"
                        >
                          <button
                            onClick={() => handleDeleteTodo(todo._id)}
                            className="p-1 text-gray-700 hover:text-red-600"
                          >
                            <TrashIcon className="h-8 w-6" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
