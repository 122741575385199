import { useState, useEffect } from "react";
import axios from "axios";
import { formatDateTR } from "../../helpers/date";
import Pagination from "../general/Pagination";
import { Link } from "react-router-dom";
import SearchBar from "../general/SearchBar";

export interface UserPackage {
  status: string;
  packagePlanId: number;
  cancelDate: string | null;
  packagePlan: PackagePlan;
}

export interface PackagePlan {
  duration: number;
}
export interface BillingAddresses {
  gsmNumber: string;
}
export interface UserTracks {
  totalTimes: string;
  page: string;
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gsmNumber: string | null;
  status: string;
  createdAt: string;
  userPackages: UserPackage[];
  billingAddresses: BillingAddresses[];
  userTracks: UserTracks[];
}

export interface User {
  _id: string;
  name: string;
  email: string;
  clients: string[];
  role: string[];
}

export default function PortfolioTable() {
  const [members, setMembers] = useState<Member[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [search, setSearch] = useState("");

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMembers = async (): Promise<void> => {
      const response = await axios.get<{
        data: { total: number; portfolioMembers: Member[] };
      }>(
        `${url}/api/portfolio/all?page=${page}&limit=${limit}&search=${search}`
      );
      setMembers(response.data.data.portfolioMembers);
      setTotalPageCount(Math.ceil(response.data.data.total / limit));
    };

    const fetchUsers = async (): Promise<void> => {
      const response = await axios.get<{ data: User[] }>(
        `${url}/api/admin/get-users`
      );
      const agents = response.data.data.filter((user) =>
        user.role.includes("agent")
      );
      setUsers(agents);
    };

    fetchMembers();
    fetchUsers();
  }, [page, limit, url, search]);

  const handleAssign = async (memberId: string, userId: string) => {
    await axios.post(`${url}/api/portfolio/assign-member`, {
      memberId,
      userId,
    });

    const previousAssignedUser = users.find((user) =>
      user.clients.includes(memberId)
    );

    const updatedUsers = users.map((user) => {
      if (user._id === userId) {
        return { ...user, clients: [...user.clients, memberId] };
      } else if (
        previousAssignedUser &&
        user._id === previousAssignedUser._id
      ) {
        return {
          ...user,
          clients: user.clients.filter((client) => client !== memberId),
        };
      } else {
        return user;
      }
    });

    setUsers(updatedUsers);

    setMembers(
      members.map((member) =>
        member.id === memberId
          ? {
              ...member,
              assignedUser: updatedUsers.find(
                (user) => user._id === userId
              ) || {
                _id: userId,
                name: updatedUsers.find((user) => user._id === userId)?.name,
                email: "",
                clients: [memberId],
              },
            }
          : member
      )
    );
  };

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();

    setFilteredMembers(
      members.filter(
        (member) =>
          member.firstName.toLowerCase().includes(lowercasedSearch) ||
          member.lastName.toLowerCase().includes(lowercasedSearch) ||
          member.email.toLowerCase().includes(lowercasedSearch)
      )
    );

    setFilteredUsers(
      users.filter(
        (user) =>
          user.name.toLowerCase().includes(lowercasedSearch) ||
          user.email.toLowerCase().includes(lowercasedSearch)
      )
    );
  }, [search, members, users]);

  const onSearch = (searchValue: any) => {
    setSearch(searchValue);
  };

  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <SearchBar onSearch={onSearch} />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Sıra No
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 " 
                    >
                      İsim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900" 
                    >
                      Soyisim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Katılış Tarihi
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Telefon
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Aktif mi?
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Ay/Yıl
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Geçirdiği Süre
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Satış Temsilcisi
                    </th>
                    <th
                      scope="col"
                      className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Detay</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {members.map((member) => (
                    <tr key={member.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        {member.id}
                      </td>
                      <td className="px-2 py-2 text-sm font-medium text-gray-900 overflow-hidden ellipsis max-w-sm">
                        <Link
                          to={`/members/${member.id}`}
                          target="_blank"
                          className="text-red-600 hover:text-red-900"
                        >
                          {member.firstName.length > 15 ? `${member.firstName.substring(0, 15)}...` : member.firstName}
                          <span className="sr-only">, {member.id}</span>
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                      {member.lastName.length > 15 ? `${member.lastName.substring(0, 15)}...` : member.lastName}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.email}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {formatDateTR(member.createdAt)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.gsmNumber
                          ? member.gsmNumber
                          : member.billingAddresses[0].gsmNumber
                          ? member.billingAddresses[0].gsmNumber
                          : " - "}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userPackages && member.userPackages.length > 0
                          ? member.userPackages[member.userPackages.length - 1].status === "active"
                            ? "Aktif"
                            : "Pasif"
                          : "N/A"}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userPackages.length > 0 &&
                        member.userPackages[0].packagePlan
                          ? member.userPackages[0].packagePlan.duration === 1
                            ? "Aylık"
                            : member.userPackages[0].packagePlan.duration === 12
                            ? "Yıllık"
                            : member.userPackages[0].packagePlan.duration
                          : "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userTracks[0]?.totalTimes} dakika
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        <select
                          value={
                            users.find((user) =>
                              user.clients.includes(member.id)
                            )?._id || ""
                          }
                          onChange={(e) =>
                            handleAssign(member.id, e.target.value)
                          }
                          className="w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 text-sm"
                        >
                          <option value="">Seçiniz</option>
                          {users.map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      {/* <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <Link
                          to={`/members/${member.id}`}
                          className="text-red-600 hover:text-red-900"
                        >
                          Detay
                          <span className="sr-only">, {member.id}</span>
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          totalPageCount={totalPageCount}
        />
      </div>
    </div>
  );
}
