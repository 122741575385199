import { ApexOptions } from 'apexcharts';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

interface data {
  data: any
}
const StateChart: React.FC<data> = ({ data }) => {
  // Add packageStatus state
  const [chartData, setChartData] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      title: {
        text: 'Kullanıcı Sayısı',
      },
    },
    yaxis: {
      title: {
        text: 'Ülkeler',
      },

    },
    series: [{
      name: 'Kullanıcı Sayısı',
      data: [],
    }],
  });

  useEffect(() => {
    if (data) {
      const StateStats = data.data.StateStats;

      const categories = Object.keys(StateStats);
      const seriesData = Object.values(StateStats).map(value => Number(value));

      setChartData(prevChartData => ({
        ...prevChartData,
        xaxis: {
          ...prevChartData.xaxis,
          categories: categories,
        },
        series: [{
          name: 'Kullanıcı Sayısı',
          data: seriesData,
        }],
      }));
    }
  }, [data]);



  return (
    <div>
      <Chart options={chartData} series={chartData.series} type="bar" height={2500} />
    </div>
  );
};

export default StateChart;
