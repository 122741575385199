import { useState, useEffect, ChangeEvent } from "react";
import axios from "axios";
type Year = "2023" | "2024";
type Month =
  | "Ocak"
  | "Şubat"
  | "Mart"
  | "Nisan"
  | "Mayıs"
  | "Haziran"
  | "Temmuz"
  | "Ağustos"
  | "Eylül"
  | "Ekim"
  | "Kasım"
  | "Aralık";
type ExchangeRates = {
  [year in Year]: {
    [month in Month]: number;
  };
};
export default function FinanceTable() {
  const [monthlyRevenue, setMonthlyRevenue] = useState<any>({});
  const [providerRevenue, setProviderRevenue] = useState<any>({});
  const [affiliateRevenue, setAffiliateRevenue] = useState<any>({});
  const [eliteRevenue, setEliteRevenue] = useState<any>({});
  const [exclusiveRevenue, setExclusiveRevenue] = useState<any>({});
  const [ecommerceRevenue, setEcommerceRevenue] = useState<any>({});
  const [apiRevenue, setApiRevenue] = useState<any>({});
  const [otherRevenue, setOtherRevenue] = useState<any>({});
  const [serverCost, setServerCost] = useState<any>({});
  const [officeCost, setOfficeCost] = useState<any>({});
  const [foodCost, setFoodCost] = useState<any>({});
  const [paymentCost, setPaymentCost] = useState<any>({});
  const [facebookCost, setFacebookCost] = useState<any>({});
  const [googleCost, setGoogleCost] = useState<any>({});
  const [twitterCost, setTwitterCost] = useState<any>({});
  const [ecommerceSalaryCost, setEcommerceSalaryCost] = useState<any>({});
  const [marketingSalaryCost, setMarketingSalaryCost] = useState<any>({});
  const [saleSalaryCost, setSaleSalaryCost] = useState<any>({});
  const [supportSalaryCost, setSupportSalaryCost] = useState<any>({});
  const [developerSalaryCost, setDeveleoperSalaryCost] = useState<any>({});
  const [ikSalaryCost, setIkSalaryCost] = useState<any>({});
  const [financeSalaryCost, setFinanceSalaryCost] = useState<any>({});
  const [sgkCost, setSgkCost] = useState<any>({});
  const [bonusCost, setBonusCost] = useState<any>({});
  const [accountingCost, setAccountingCost] = useState<any>({});
  const [taxCost, setTaxCost] = useState<any>({});
  const [otherCost, setOtherCost] = useState<any>({});
  const [selectedYear, setSelectedYear] = useState(2024);
  const [currency, setCurrency] = useState<"TL" | "USD">("TL");

  const exchangeRates: ExchangeRates = {
    "2023": {
      Ocak: 18.81,
      Şubat: 18.88,
      Mart: 19.17,
      Nisan: 19.45,
      Mayıs: 20.7,
      Haziran: 26.05,
      Temmuz: 26.92,
      Ağustos: 26.6,
      Eylül: 27.4,
      Ekim: 28.3,
      Kasım: 28.88,
      Aralık: 29.47,
    },
    "2024": {
      Ocak: 30.36,
      Şubat: 31.75,
      Mart: 32.5,
      Nisan: 32.5,
      Mayıs: 32.5,
      Haziran: 32.5,
      Temmuz: 30.75,
      Ağustos: 30.75,
      Eylül: 30.75,
      Ekim: 30.75,
      Kasım: 30.75,
      Aralık: 30.75,
    },
  };

  useEffect(() => {
    const fetchMonthlyRevenue = async () => {
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${url}/api/payments/monthly?year=${selectedYear}`
      );
      const revenueData = response.data.data.monthlyRevenue;
      setMonthlyRevenue(revenueData);
    };

    const fetchRevenuesAndCosts = async () => {
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${url}/api/finance/get-all?year=${selectedYear}`
      );
      const allFinances = response.data.data;

      let monthlyProviderRevenue: any = {};
      let monthlyAffiliateRevenue: any = {};
      let monthlyOtherRevenue: any = {};
      let monthlyEliteRevenue: any = {};
      let monthlyExclusiveRevenue: any = {};
      let monthlyEcommerceRevenue: any = {};
      let monthlyApiRevenue: any = {};
      let monthlyServerCost: any = {};
      let monthlyOfficeCost: any = {};
      let monthlyFoodCost: any = {};
      let monthlyPaymentCost: any = {};
      let monthlyFacebookCost: any = {};
      let monthlyGoogleCost: any = {};
      let monthlyTwitterCost: any = {};
      let monthlyEcommerceSalaryCost: any = {};
      let monthlyMarketingSalaryCost: any = {};
      let monthlySaleSalaryCost: any = {};
      let monthlySupportSalaryCost: any = {};
      let monthlyDeveloperSalaryCost: any = {};
      let monthlyIkSalaryCost: any = {};
      let monthlyFinanceSalaryCost: any = {};
      let monthlySgkCost: any = {};
      let monthlyBonusCost: any = {};
      let monthlyAccountingCost: any = {};
      let monthlyTaxCost: any = {};
      let monthlyOtherCost: any = {};

      allFinances.forEach((finance: any) => {
        const month = new Date(finance.date).toLocaleString("tr-TR", {
          month: "long",
        });

        if (finance.category === "Hizmet Sağlayıcı Geliri") {
          monthlyProviderRevenue[month] =
            (monthlyProviderRevenue[month] || 0) + finance.amount;
        } else if (finance.category === "Affiliate Geliri") {
          monthlyAffiliateRevenue[month] =
            (monthlyAffiliateRevenue[month] || 0) + finance.amount;
        } else if (finance.category === "Diğer Gelirler") {
          monthlyOtherRevenue[month] =
            (monthlyOtherRevenue[month] || 0) + finance.amount;
        } else if (finance.category === "Rexven Elite") {
          monthlyEliteRevenue[month] =
            (monthlyEliteRevenue[month] || 0) + finance.amount;
        } else if (finance.category === "Rexven Exclusive") {
          monthlyExclusiveRevenue[month] =
            (monthlyExclusiveRevenue[month] || 0) + finance.amount;
        } else if (finance.category === "E-ticaret Geliri") {
          monthlyEcommerceRevenue[month] =
            (monthlyEcommerceRevenue[month] || 0) + finance.amount;
        }else if (finance.category === "API Geliri") {
          monthlyApiRevenue[month] =
            (monthlyApiRevenue[month] || 0) + finance.amount;
        } else if (finance.category === "Sunucu - Server") {
          monthlyServerCost[month] =
            (monthlyServerCost[month] || 0) + finance.amount;
        } else if (finance.category === "Kira - Aidat - Fatura") {
          monthlyOfficeCost[month] =
            (monthlyOfficeCost[month] || 0) + finance.amount;
        } else if (finance.category === "Yemek - Ödül") {
          monthlyFoodCost[month] =
            (monthlyFoodCost[month] || 0) + finance.amount;
        } else if (finance.category === "Ödeme Altyapısı") {
          monthlyPaymentCost[month] =
            (monthlyPaymentCost[month] || 0) + finance.amount;
        } else if (finance.category === "Facebook Reklamları") {
          monthlyFacebookCost[month] =
            (monthlyFacebookCost[month] || 0) + finance.amount;
        } else if (finance.category === "Google Reklamları") {
          monthlyGoogleCost[month] =
            (monthlyGoogleCost[month] || 0) + finance.amount;
        } else if (finance.category === "Twitter Reklamları") {
          monthlyTwitterCost[month] =
            (monthlyTwitterCost[month] || 0) + finance.amount;
        } else if (finance.category === "Maaş - E-ticaret") {
          monthlyEcommerceSalaryCost[month] =
            (monthlyEcommerceSalaryCost[month] || 0) + finance.amount;
        } else if (finance.category === "Maaş - Pazarlama") {
          monthlyMarketingSalaryCost[month] =
            (monthlyMarketingSalaryCost[month] || 0) + finance.amount;
        } else if (finance.category === "Maaş - Satış") {
          monthlySaleSalaryCost[month] =
            (monthlySaleSalaryCost[month] || 0) + finance.amount;
        } else if (finance.category === "Maaş - Destek") {
          monthlySupportSalaryCost[month] =
            (monthlySupportSalaryCost[month] || 0) + finance.amount;
        } else if (finance.category === "Maaş - Yazılım") {
          monthlyDeveloperSalaryCost[month] =
            (monthlyDeveloperSalaryCost[month] || 0) + finance.amount;
        } else if (finance.category === "Maaş - IK ve Operasyon") {
          monthlyIkSalaryCost[month] =
            (monthlyIkSalaryCost[month] || 0) + finance.amount;
        } else if (finance.category === "Maaş - Finans ve Hukuk") {
          monthlyFinanceSalaryCost[month] =
            (monthlyFinanceSalaryCost[month] || 0) + finance.amount;
        } else if (finance.category === "SGK") {
          monthlySgkCost[month] = (monthlySgkCost[month] || 0) + finance.amount;
        } else if (finance.category === "Prim") {
          monthlyBonusCost[month] =
            (monthlyBonusCost[month] || 0) + finance.amount;
        } else if (finance.category === "Muhasebe") {
          monthlyAccountingCost[month] =
            (monthlyAccountingCost[month] || 0) + finance.amount;
        } else if (finance.category === "Vergi") {
          monthlyTaxCost[month] = (monthlyTaxCost[month] || 0) + finance.amount;
        } else if (finance.category === "Diğer Giderler") {
          monthlyOtherCost[month] =
            (monthlyOtherCost[month] || 0) + finance.amount;
        }
      });

      setProviderRevenue(monthlyProviderRevenue);
      setAffiliateRevenue(monthlyAffiliateRevenue);
      setOtherRevenue(monthlyOtherRevenue);
      setEliteRevenue(monthlyEliteRevenue);
      setExclusiveRevenue(monthlyExclusiveRevenue);
      setEcommerceRevenue(monthlyEcommerceRevenue);
      setApiRevenue(monthlyApiRevenue);
      setServerCost(monthlyServerCost);
      setOfficeCost(monthlyOfficeCost);
      setFoodCost(monthlyFoodCost);
      setPaymentCost(monthlyPaymentCost);
      setFacebookCost(monthlyFacebookCost);
      setGoogleCost(monthlyGoogleCost);
      setTwitterCost(monthlyTwitterCost);
      setEcommerceSalaryCost(monthlyEcommerceSalaryCost);
      setMarketingSalaryCost(monthlyMarketingSalaryCost);
      setSaleSalaryCost(monthlySaleSalaryCost);
      setSupportSalaryCost(monthlySupportSalaryCost);
      setDeveleoperSalaryCost(monthlyDeveloperSalaryCost);
      setIkSalaryCost(monthlyIkSalaryCost);
      setFinanceSalaryCost(monthlyFinanceSalaryCost);
      setSgkCost(monthlySgkCost);
      setBonusCost(monthlyBonusCost);
      setAccountingCost(monthlyAccountingCost);
      setTaxCost(monthlyTaxCost);
      setOtherCost(monthlyOtherCost);
    };
    fetchRevenuesAndCosts();
    fetchMonthlyRevenue();
  }, [selectedYear]);

  const months: Month[] = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const calculateMonthlyGrowth = (
    currentMonthRevenue: number,
    previousMonthRevenue: number
  ): string => {
    if (previousMonthRevenue === 0 || currentMonthRevenue === 0) {
      return "-";
    }

    const growth = currentMonthRevenue - previousMonthRevenue;
    const growthPercentage = (growth / currentMonthRevenue) * 100;

    return growthPercentage.toFixed(2);
  };
  const handleYearChange = (event: any) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };
  const handleCurrencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value as "TL" | "USD");
  };

  const convertCurrency = (amount: number, month: Month, year: Year) => {
    const rate = exchangeRates[year][month];
    return currency === "USD" ? amount / rate : amount;
  };

  return (
    <div className="bg-white">
      <div className="flex justify-between items-center mb-4">
        <div>
          <select
            id="yearSelect"
            value={selectedYear}
            onChange={handleYearChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>
        <div>
          <select
            id="currencySelect"
            value={currency}
            onChange={handleCurrencyChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="TL">TL</option>
            <option value="USD">USD</option>
          </select>
        </div>
      </div>
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-800">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Başlık
                    </th>
                    {months.map((month) => (
                      <th
                        key={month}
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {month}
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Toplam
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Rexven Premium Geliri
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          monthlyRevenue[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}

                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(monthlyRevenue)
                        .reduce(
                          (acc, month) =>
                            acc +
                            convertCurrency(
                              monthlyRevenue[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Hizmet Sağlayıcı Geliri
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          providerRevenue[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(providerRevenue)
                        .reduce(
                          (acc, month) =>
                            acc +
                            convertCurrency(
                              providerRevenue[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Affiliate Geliri
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          affiliateRevenue[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(affiliateRevenue)
                        .reduce(
                          (acc, month) =>
                            acc +
                            convertCurrency(
                              affiliateRevenue[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Rexven Elite Geliri
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          eliteRevenue[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(eliteRevenue)
                        .reduce(
                          (acc, month) =>
                            acc +
                            convertCurrency(
                              eliteRevenue[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Rexven Exclusive Geliri
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          exclusiveRevenue[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(exclusiveRevenue)
                        .reduce(
                          (acc, month) =>
                            acc +
                            convertCurrency(
                              exclusiveRevenue[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      E-ticaret Geliri
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          ecommerceRevenue[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(ecommerceRevenue)
                        .reduce(
                          (acc, month) =>
                            acc +
                            convertCurrency(
                              ecommerceRevenue[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      API Geliri
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          apiRevenue[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(apiRevenue)
                        .reduce(
                          (acc, month) =>
                            acc +
                            convertCurrency(
                              apiRevenue[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Diğer Gelirler
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          otherRevenue[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(otherRevenue)
                        .reduce(
                          (acc, month) =>
                            acc +
                            convertCurrency(
                              otherRevenue[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-rose-200">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Toplam Gelir
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          (monthlyRevenue[month] || 0) +
                            (providerRevenue[month] || 0) +
                            (otherRevenue[month] || 0) +
                            (eliteRevenue[month] || 0) +
                            (exclusiveRevenue[month] || 0) +
                            (ecommerceRevenue[month] || 0) +
                            (apiRevenue[month] || 0) ,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(
                        months.reduce(
                          (acc, month) => ({
                            ...acc,
                            [month]:
                              (monthlyRevenue[month] || 0) +
                              (providerRevenue[month] || 0) +
                              (otherRevenue[month] || 0) +
                              (eliteRevenue[month] || 0) +
                              (exclusiveRevenue[month] || 0) +
                              (ecommerceRevenue[month] || 0)+
                              (apiRevenue[month] || 0),
                          }),
                          {}
                        )
                      )
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              (monthlyRevenue[month] || 0) +
                                (providerRevenue[month] || 0) +
                                (otherRevenue[month] || 0) +
                                (eliteRevenue[month] || 0) +
                                (exclusiveRevenue[month] || 0) +
                                (ecommerceRevenue[month] || 0) +
                                (apiRevenue[month] || 0),
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  <tr className="bg-gray-200">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Aylık Büyüme
                    </td>
                    {months.map((month, index) => {
                      const currentMonthRevenueConverted = convertCurrency(
                        (monthlyRevenue[month] || 0) +
                          (providerRevenue[month] || 0) +
                          (otherRevenue[month] || 0) +
                          (eliteRevenue[month] || 0) +
                          (exclusiveRevenue[month] || 0) +
                          (ecommerceRevenue[month] || 0)  +
                          (apiRevenue[month] || 0),
                        month,
                        selectedYear.toString() as Year
                      );

                      const previousMonthRevenueConverted =
                        index === 0
                          ? 0
                          : convertCurrency(
                              (monthlyRevenue[months[index - 1]] || 0) +
                                (providerRevenue[months[index - 1]] || 0) +
                                (otherRevenue[months[index - 1]] || 0) +
                                (eliteRevenue[months[index - 1]] || 0) +
                                (exclusiveRevenue[months[index - 1]] || 0) +
                                (ecommerceRevenue[months[index - 1]] || 0) +
                                (apiRevenue[months[index - 1]] || 0),
                              months[index - 1] as Month,
                              selectedYear.toString() as Year
                            );

                      const growthPercentage = calculateMonthlyGrowth(
                        currentMonthRevenueConverted,
                        previousMonthRevenueConverted
                      );

                      return (
                        <td
                          key={month}
                          className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                        >
                          {growthPercentage}%
                        </td>
                      );
                    })}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {(() => {
                        let totalGrowth = 0;
                        let growthCount = 0;
                        months.forEach((month, index) => {
                          const current = convertCurrency(
                            (monthlyRevenue[month] || 0) +
                              (providerRevenue[month] || 0) +
                              (otherRevenue[month] || 0) +
                              (eliteRevenue[month] || 0) +
                              (exclusiveRevenue[month] || 0) +
                              (ecommerceRevenue[month] || 0) +
                              (apiRevenue[month] || 0),
                            month,
                            selectedYear.toString() as Year
                          );
                          const previous =
                            index === 0
                              ? 0
                              : convertCurrency(
                                  (monthlyRevenue[months[index - 1]] || 0) +
                                    (providerRevenue[months[index - 1]] || 0) +
                                    (otherRevenue[months[index - 1]] || 0) +
                                    (eliteRevenue[months[index - 1]] || 0) +
                                    (exclusiveRevenue[months[index - 1]] || 0) +
                                    (ecommerceRevenue[months[index - 1]] || 0) +
                                    (apiRevenue[months[index - 1]] || 0),
                                  months[index - 1] as Month,
                                  selectedYear.toString() as Year
                                );
                          const growth = calculateMonthlyGrowth(
                            current,
                            previous
                          );
                          if (growth !== "-") {
                            totalGrowth += parseFloat(growth);
                            growthCount++;
                          }
                        });
                        return growthCount > 0
                          ? (totalGrowth / growthCount).toFixed(2) + "% (ort.)"
                          : "-";
                      })()}
                    </td>
                  </tr>
                  {/* Boşluk için boş bir satır */}
                  <tr>
                    <td className="py-3" colSpan={13}></td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      IT - Sunucu - Server
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          serverCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(serverCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              serverCost[month],
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Ofis - Kira - Fatura
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          officeCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(officeCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              officeCost[month],
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Yemek/Multi - Ödül
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          foodCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(foodCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              foodCost[month],
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Ödeme Altyapısı
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          paymentCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(paymentCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              paymentCost[month],
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  {/* Boşluk için boş bir satır */}
                  <tr>
                    <td className="py-3" colSpan={13}></td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Facebook Reklamları
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          facebookCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(facebookCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              facebookCost[month],
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Google Reklamları
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          googleCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(googleCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              googleCost[month],
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Twitter Reklamları
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          twitterCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(twitterCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              twitterCost[month],
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  <tr className="bg-rose-100">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Toplam Reklam
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          (facebookCost[month] || 0) +
                            (googleCost[month] || 0) +
                            (twitterCost[month] || 0),
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys({
                        ...facebookCost,
                        ...googleCost,
                        ...twitterCost,
                      })
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              (facebookCost[month] || 0) +
                                (googleCost[month] || 0) +
                                (twitterCost[month] || 0),
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  {/* Boşluk için boş bir satır */}
                  <tr>
                    <td className="py-3" colSpan={13}></td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Maaş (E-Ticaret)
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          ecommerceSalaryCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(ecommerceSalaryCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              ecommerceSalaryCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Maaş (Pazarlama)
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          marketingSalaryCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(marketingSalaryCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              marketingSalaryCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Maaş (Satış)
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          saleSalaryCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(saleSalaryCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              saleSalaryCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Maaş (Destek)
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          supportSalaryCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(supportSalaryCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              supportSalaryCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Maaş (Yazılım)
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          developerSalaryCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(developerSalaryCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              developerSalaryCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Maaş (IK ve Operasyon)
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          ikSalaryCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(ikSalaryCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              ikSalaryCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Maaş (Finans ve Hukuk)
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          financeSalaryCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(financeSalaryCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              financeSalaryCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Personel SGK
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          sgkCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(sgkCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              sgkCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Personel Prim
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          bonusCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(bonusCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              bonusCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  <tr className="bg-rose-100">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Toplam Personel Gideri
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          (ecommerceSalaryCost[month] || 0) +
                            (marketingSalaryCost[month] || 0) +
                            (saleSalaryCost[month] || 0) +
                            (supportSalaryCost[month] || 0) +
                            (developerSalaryCost[month] || 0) +
                            (ikSalaryCost[month] || 0) +
                            (financeSalaryCost[month] || 0) +
                            (sgkCost[month] || 0) +
                            (bonusCost[month] || 0),
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {[
                        ecommerceSalaryCost,
                        marketingSalaryCost,
                        saleSalaryCost,
                        supportSalaryCost,
                        developerSalaryCost,
                        ikSalaryCost,
                        financeSalaryCost,
                        sgkCost,
                        bonusCost,
                      ]
                        .reduce(
                          (total, obj) =>
                            total +
                            Object.keys(obj).reduce(
                              (totalMonth, month) =>
                                totalMonth +
                                convertCurrency(
                                  obj[month] || 0,
                                  month as Month,
                                  selectedYear.toString() as Year
                                ),
                              0
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  {/* Boşluk için boş bir satır */}
                  <tr>
                    <td className="py-3" colSpan={13}></td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Muhasebe
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          accountingCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(accountingCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              accountingCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Vergi
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          taxCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(taxCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              taxCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Diğer Giderler
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {convertCurrency(
                          otherCost[month] || 0,
                          month,
                          selectedYear.toString() as Year
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {Object.keys(otherCost)
                        .reduce(
                          (total, month) =>
                            total +
                            convertCurrency(
                              otherCost[month] || 0,
                              month as Month,
                              selectedYear.toString() as Year
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  {/* Boşluk için boş bir satır */}
                  <tr>
                    <td className="py-3" colSpan={13}></td>
                  </tr>
                  <tr className="bg-rose-200">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Toplam Gider
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {[
                          serverCost[month] || 0,
                          officeCost[month] || 0,
                          foodCost[month] || 0,
                          facebookCost[month] || 0,
                          googleCost[month] || 0,
                          twitterCost[month] || 0,
                          ecommerceSalaryCost[month] || 0,
                          marketingSalaryCost[month] || 0,
                          saleSalaryCost[month] || 0,
                          supportSalaryCost[month] || 0,
                          developerSalaryCost[month] || 0,
                          ikSalaryCost[month] || 0,
                          financeSalaryCost[month] || 0,
                          sgkCost[month] || 0,
                          bonusCost[month] || 0,
                          accountingCost[month] || 0,
                          taxCost[month] || 0,
                          otherCost[month] || 0,
                        ]
                          .reduce(
                            (total, cost) =>
                              total +
                              convertCurrency(
                                cost,
                                month,
                                selectedYear.toString() as Year
                              ),
                            0
                          )
                          .toLocaleString("tr-TR", {
                            style: "currency",
                            currency: currency === "USD" ? "USD" : "TRY",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {[
                        serverCost,
                        officeCost,
                        foodCost,
                        facebookCost,
                        googleCost,
                        twitterCost,
                        ecommerceSalaryCost,
                        marketingSalaryCost,
                        saleSalaryCost,
                        supportSalaryCost,
                        developerSalaryCost,
                        ikSalaryCost,
                        financeSalaryCost,
                        sgkCost,
                        bonusCost,
                        accountingCost,
                        taxCost,
                        otherCost,
                      ]
                        .reduce(
                          (total, obj) =>
                            total +
                            Object.keys(obj).reduce(
                              (sum, month) =>
                                sum +
                                convertCurrency(
                                  obj[month] || 0,
                                  month as Month,
                                  selectedYear.toString() as Year
                                ),
                              0
                            ),
                          0
                        )
                        .toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </td>
                  </tr>

                  {/* Boşluk için boş bir satır */}
                  <tr>
                    <td className="py-3" colSpan={13}></td>
                  </tr>
                  <tr className="bg-rose-200">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Toplam Kar/Zarar
                    </td>
                    {months.map((month) => (
                      <td
                        key={month}
                        className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900"
                      >
                        {(
                          [
                            monthlyRevenue[month] || 0,
                            providerRevenue[month] || 0,
                            otherRevenue[month] || 0,
                            eliteRevenue[month] || 0,
                            exclusiveRevenue[month] || 0,
                            ecommerceRevenue[month] || 0,
                            apiRevenue[month] || 0,
                          ].reduce(
                            (total, revenue) =>
                              total +
                              convertCurrency(
                                revenue,
                                month,
                                selectedYear.toString() as Year
                              ),
                            0
                          ) -
                          [
                            serverCost[month] || 0,
                            officeCost[month] || 0,
                            foodCost[month] || 0,
                            facebookCost[month] || 0,
                            googleCost[month] || 0,
                            twitterCost[month] || 0,
                            ecommerceSalaryCost[month] || 0,
                            marketingSalaryCost[month] || 0,
                            saleSalaryCost[month] || 0,
                            supportSalaryCost[month] || 0,
                            developerSalaryCost[month] || 0,
                            ikSalaryCost[month] || 0,
                            financeSalaryCost[month] || 0,
                            sgkCost[month] || 0,
                            bonusCost[month] || 0,
                            accountingCost[month] || 0,
                            taxCost[month] || 0,
                            otherCost[month] || 0,
                          ].reduce(
                            (total, cost) =>
                              total +
                              convertCurrency(
                                cost,
                                month,
                                selectedYear.toString() as Year
                              ),
                            0
                          )
                        ).toLocaleString("tr-TR", {
                          style: "currency",
                          currency: currency === "USD" ? "USD" : "TRY",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </td>
                    ))}
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      {(() => {
                        const totalRevenues = [
                          monthlyRevenue,
                          providerRevenue,
                          otherRevenue,
                          eliteRevenue,
                          exclusiveRevenue,
                          ecommerceRevenue,
                          apiRevenue,
                        ].reduce(
                          (total, obj) =>
                            total +
                            Object.keys(obj).reduce(
                              (sum, month) =>
                                sum +
                                convertCurrency(
                                  obj[month] || 0,
                                  month as Month,
                                  selectedYear.toString() as Year
                                ),
                              0
                            ),
                          0
                        );

                        const totalCosts = [
                          serverCost,
                          officeCost,
                          foodCost,
                          facebookCost,
                          googleCost,
                          twitterCost,
                          ecommerceSalaryCost,
                          marketingSalaryCost,
                          saleSalaryCost,
                          supportSalaryCost,
                          developerSalaryCost,
                          ikSalaryCost,
                          financeSalaryCost,
                          sgkCost,
                          bonusCost,
                          accountingCost,
                          taxCost,
                          otherCost,
                        ].reduce(
                          (total, obj) =>
                            total +
                            Object.keys(obj).reduce(
                              (sum, month) =>
                                sum +
                                convertCurrency(
                                  obj[month] || 0,
                                  month as Month,
                                  selectedYear.toString() as Year
                                ),
                              0
                            ),
                          0
                        );

                        return (totalRevenues - totalCosts).toLocaleString(
                          "tr-TR",
                          {
                            style: "currency",
                            currency: currency === "USD" ? "USD" : "TRY",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }
                        );
                      })()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
