import { useState, useEffect } from "react";
import axios from "axios";

export interface DimensionValue {
  value: string;
  oneValue: string;
}
interface OrganicUser {
  month: string;
  organic: number;
}

export interface MetricValue {
  value: string;
  oneValue: string;
}

export interface PageView {
  dimensionValues: DimensionValue[];
  metricValues: MetricValue[];
  month: string;
}
interface NewMember {
  month: string;
  newMembers: number;
}
interface ActiveMember {
  month: string;
  newActiveMembers: number;
}
interface GsmVerified {
  month: string;
  newGsmVerified: number;
}

interface MonthlyExpense {
  month: string;
  amount: number;
}
interface ChurnRateStat {
  activeMembers: number;
  leftMembers: number;
  churnRate: number;
  growthRates: number;
}
interface DataStats {
    [key: string]: string; 
  }

const salesTeamResults = [
  { month: "Ocak", year: 2023, sales: 138 },
  { month: "Şubat", year: 2023, sales: 82 },
  { month: "Mart", year: 2023, sales: 148 },
  { month: "Nisan", year: 2023, sales: 146 },
  { month: "Mayıs", year: 2023, sales: 129 },
  { month: "Haziran", year: 2023, sales: 172 },
  { month: "Temmuz", year: 2023, sales: 186 },
  { month: "Ağustos", year: 2023, sales: 112 },
  { month: "Eylül", year: 2023, sales: 99 },
  { month: "Ekim", year: 2023, sales: 153 },
  { month: "Kasım", year: 2023, sales: 125 },
  { month: "Aralık", year: 2023, sales: 166 },
  { month: "Ocak", year: 2024, sales: 128 },
  { month: "Şubat", year: 2024, sales: 68 },
  { month: "Mart", year: 2024, sales: 92 },
  { month: "Nisan", year: 2024, sales: 67 },
  { month: "Mayıs", year: 2024, sales: 99 },
  { month: "Haziran", year: 2024, sales: 0 },
  { month: "Temmuz", year: 2024, sales: 0 },
  { month: "Ağustos", year: 2024, sales: 0 },
  { month: "Eylül", year: 2024, sales: 0 },
  { month: "Ekim", year: 2024, sales: 0 },
  { month: "Kasım", year: 2024, sales: 0 },
  { month: "Aralık", year: 2024, sales: 0 },
];

export default function UserMetrics() {
  const [newMembersByMonth, setNewMembersByMonth] = useState<NewMember[]>([]);
  const [newActiveMembersByMonth, setActiveNewMembersByMonth] = useState<
    ActiveMember[]
  >([]);
  const [newGsmVerifiedByMonth, setGsmVerifiedByMonth] = useState<
    GsmVerified[]
  >([]);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [filteredSalesTeamResults, setFilteredSalesTeamResults] = useState<
    { month: string; year: number; sales: number }[]
  >([]);
  const [monthlyExpenses, setMonthlyExpenses] = useState<MonthlyExpense[]>([]);

  const [organicUsers, setOrganicUsers] = useState<OrganicUser[]>([]);
  const [churnRates, setChurnRates] = useState<ChurnRateStat[]>([]);

  useEffect(() => {
    const fetchChurnRates = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/metrics/churn-rate`;
      try {
        const response = await axios.get(url);
        const monthlyData = response.data.data;
        // Filtreleme işlemi burada yapılacak
        const filteredChurnData = Object.keys(monthlyData)
          .filter(key => selectedYear === 2023 ? !key.includes("2024") : key.includes("2024"))
          .map(month => ({
            month: month,
            activeMembers: monthlyData[month].activeMembers,
            leftMembers: monthlyData[month].leftMembers,
            churnRate: monthlyData[month].churnRate,
            growthRates: monthlyData[month].growthRates,
          }));

        setChurnRates(filteredChurnData);
      } catch (error) {
        console.error("Churn rates fetching error:", error);
      }
    };

    fetchChurnRates();
  }, [selectedYear]); 

  useEffect(() => {
    const calculateOrganicUsers = () => {
      const organic = newActiveMembersByMonth.map((activeMember) => {
        const saleMonth = filteredSalesTeamResults.find(
          (sale) => sale.month === activeMember.month
        );
        const sales = saleMonth ? saleMonth.sales : 0;
        const organicUsersCount = activeMember.newActiveMembers - sales;
        return {
          month: activeMember.month,
          organic: organicUsersCount >= 0 ? organicUsersCount : 0,
        };
      });
      setOrganicUsers(organic);
    };

    calculateOrganicUsers();
  }, [selectedYear, newActiveMembersByMonth, filteredSalesTeamResults]);

  useEffect(() => {
    const getFilteredSalesTeamResults = (selectedYear: number) => {
      return salesTeamResults.filter((result) => result.year === selectedYear);
    };
    setFilteredSalesTeamResults(getFilteredSalesTeamResults(selectedYear));
  }, [selectedYear]);

  useEffect(() => {
    const fetchNewMembersByMonth = async () => {
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${url}/api/members/monthly-new-members`
      );
      const newMembersData: NewMember[] = response.data.data.newMembersByMonth;

      // Yıl verilerini filtrele
      const filteredData = newMembersData
        .filter((item) => item.month.includes(selectedYear.toString()))
        .map((item) => {
          // Ay ismini ve yılı ayıkla
          const monthName = item.month.split(" ")[0]; // "Ocak 2023" formatındaki veriden sadece "Ocak"ı alır
          return {
            month: monthName,
            newMembers: item.newMembers,
          };
        });

      setNewMembersByMonth(filteredData);
    };

    fetchNewMembersByMonth();
  }, [selectedYear]);

  useEffect(() => {
    const fetchActiveNewMembersByMonth = async () => {
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${url}/api/members/monthly-members`);
      const newActiveMembersData = response.data.data;

      const monthMapping: { [key: string]: string } = {
        "01": "Ocak",
        "02": "Şubat",
        "03": "Mart",
        "04": "Nisan",
        "05": "Mayıs",
        "06": "Haziran",
        "07": "Temmuz",
        "08": "Ağustos",
        "09": "Eylül",
        "10": "Ekim",
        "11": "Kasım",
        "12": "Aralık",
      };

      // Yıl verilerini filtrele
      const filteredData = Object.keys(newActiveMembersData)
        .filter((key) => key.startsWith(`${selectedYear}`))
        .map((key) => {
          const yearMonth = key.split("-");
          const monthNumber = yearMonth[1];
          const monthName = monthMapping[monthNumber];
          return {
            month: monthName,
            newActiveMembers: newActiveMembersData[key],
          };
        });

      setActiveNewMembersByMonth(filteredData);
    };

    fetchActiveNewMembersByMonth();
  }, [selectedYear]);

  useEffect(() => {
    const fetchGsmVerifiedByMonth = async () => {
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${url}/api/data/gsmverified-data-count`
      );
      const newGsmVerifiedData = response.data.data;

      const monthMapping: { [key: string]: string } = {
        "01": "Ocak",
        "02": "Şubat",
        "03": "Mart",
        "04": "Nisan",
        "05": "Mayıs",
        "06": "Haziran",
        "07": "Temmuz",
        "08": "Ağustos",
        "09": "Eylül",
        "10": "Ekim",
        "11": "Kasım",
        "12": "Aralık",
      };

      // Yalnızca 2023 yılı verilerini filtrele
      const filteredData = Object.keys(newGsmVerifiedData)
        .filter((key) => key.startsWith(`${selectedYear}`))
        .map((key) => {
          const yearMonth = key.split("-");
          const monthNumber = yearMonth[1];
          const monthName = monthMapping[monthNumber];
          return {
            month: monthName,
            newGsmVerified: newGsmVerifiedData[key],
          };
        });

      setGsmVerifiedByMonth(filteredData);
    };

    fetchGsmVerifiedByMonth();
  }, [selectedYear]);

  useEffect(() => {
    const fetchMonthlyAdCost = async () => {
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${url}/api/finance/get-all?year=${selectedYear}`
      );
      const allFinances = response.data.data;
      let monthlyTotalAdCost: any = {};

      allFinances.forEach((finance: any) => {
        const month = new Date(finance.date).toLocaleString("tr-TR", {
          month: "long",
        });

        if (
          [
            "Facebook Reklamları",
            "Google Reklamları",
            "Twitter Reklamları",
          ].includes(finance.category)
        ) {
          monthlyTotalAdCost[month] =
            (monthlyTotalAdCost[month] || 0) + finance.amount;
        }
      });

      // Diziyi ay isimleri ve harcama miktarlarını içeren objelerden oluştur
      const expensesArray = Object.keys(monthlyTotalAdCost).map((month) => ({
        month: month,
        amount: monthlyTotalAdCost[month],
      }));

      setMonthlyExpenses(expensesArray);
    };

    fetchMonthlyAdCost();
  }, [selectedYear]);
  
  const handleYearChange = (event: any) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  return (
    <div className="bg-white">
      <div className="px-4 py-2 sm:px-6 lg:px-8">
        <select value={selectedYear} onChange={handleYearChange}>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
      </div>
      <div className="px-4 py-1 sm:px-6 lg:px-8">
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300 drop-shadow-2xl">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Başlık
                    </th>
                    {monthlyExpenses.map((expense) => (
                      <th
                        key={expense.month}
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-center text-sm font-semibold text-gray-900"
                      >
                        {expense.month}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  
                  <tr>
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Yeni Kullanıcı Sayısı
                    </td>
                    {monthlyExpenses.map((expense, index) => (
                      <td
                        key={expense.month}
                        className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900"
                      >
                        {newMembersByMonth[index]?.newMembers || "-"}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Telefon Doğrulayan Kullanıcı Sayısı
                    </td>
                    {monthlyExpenses.map((expense, index) => (
                      <td
                        key={expense.month}
                        className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900"
                      >
                        {newGsmVerifiedByMonth[index]?.newGsmVerified || "-"}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Yeni Premium Kullanıcı Sayısı
                    </td>
                    {monthlyExpenses.map((expense) => {
                      const activeMember = newActiveMembersByMonth.find(
                        (item) => item.month === expense.month
                      );
                      return (
                        <td
                          key={expense.month}
                          className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900"
                        >
                          {activeMember?.newActiveMembers || "-"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-2 py-2 text-left text-xs text-gray-500">
                      ---- Satış Ekibi
                    </td>
                    {filteredSalesTeamResults.map((result, index) => (
                      <td
                        key={index}
                        className="whitespace-nowrap px-2 py-2 text-center text-xs text-gray-500"
                      >
                        {result.sales}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-2 py-2 text-left text-xs text-gray-500">
                      ---- Organik
                    </td>
                    {monthlyExpenses.map((expense, index) => {
                      // Doğrudan ay isimleriyle karşılaştırma yapılıyor
                      const organicUser = organicUsers.find(
                        (user) => user.month === expense.month
                      );
                      return (
                        <td
                          key={index}
                          className="whitespace-nowrap px-2 py-2 text-center text-xs text-gray-500"
                        >
                          {organicUser ? organicUser.organic : "-"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Ayrılan Premium Kullanıcı Sayısı
                    </td>
                    {monthlyExpenses.map((expense, index) => (
                      <td
                        key={index}
                        className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900"
                      >
                        {churnRates[index]?.leftMembers || "-"}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Aktif Premium Kullanıcı Sayısı
                    </td>
                    {monthlyExpenses.map((expense, index) => (
                      <td
                        key={index}
                        className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900"
                      >
                        {churnRates[index]?.activeMembers || "-"}
                      </td>
                    ))}
                  </tr>
                  {/* <tr>
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Büyüme Oranı (%)
                    </td>
                    {monthlyExpenses.map((expense, index) => (
                      <td
                        key={index}
                        className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900"
                      >
                        {churnRates[index]
                          ? `${(churnRates[index].growthRates * 100).toFixed(2)}%`
                          : "-"}
                      </td>
                    ))}
                  </tr> */}
                  <tr>
                    <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-900">
                      Churn Oranı (%)
                    </td>
                    {monthlyExpenses.map((expense, index) => (
                      <td
                        key={index}
                        className="whitespace-nowrap px-2 py-2 text-center text-sm text-gray-900"
                      >
                        {churnRates[index]
                          ? `${(churnRates[index].churnRate * 100).toFixed(2)}%`
                          : "-"}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
