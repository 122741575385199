import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDateTR } from "../../helpers/date";
import memberService from "../../services/memberService";
import ChangePaymentMethod from "./elements/ChangePaymentMethod";
import { Member } from "./MemberTable";

interface Answer {
  [key: string]: string | string[] | { state: string; country: string };
}
interface UserTrack {
  page: string;
  totalTimes: string;
}

// Anket cevapları için bir tip tanımı yapabilirsiniz
interface FormAnswer {
  answers: Answer;
}

const formQuestions = [
  "Yaş ",
  "Meslek ",
  "Şu an satış yaptığı platform ",
  "Rexven'i nereden duydu ",
  "Rexven'e neden katıldı ",
  "E-ticaret Bütçesi ",
];

interface StrategyAnswers {
  userId: number;
  definition: string;
  sellingHistory: string[];
  wishes: string[];
  platform: string;
  level: string;
  budget: string;
  problems: string[];
  importance: number;
}

interface StrategyAnswers {
  userId: number;
  sellingHistory: string[];
  wishes: string[];
  platform: string;
  budget: string;
}

const MemberDetails: React.FC = () => {
  const { id } = useParams();
  const [description, setDescription] = useState<string | null>(null);
  const [descriptionExists, setDescriptionExists] = useState<boolean>(false);
  const [labelExists, setLabelExists] = useState<boolean>(false);
  const [label, setLabel] = useState<string | null>(null);
  const [member, setMember] = useState<Member | null>(null);
  const [appointmentDate, setAppointmentDate] = useState<string>("");
  const [appointments, setAppointments] = useState<
    Array<{ appointment: string }>
  >([]);
  const [formAnswers, setFormAnswers] = useState<Array<FormAnswer>>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [strategyAnswers, setStrategyAnswers] =
    useState<StrategyAnswers | null>(null);
  const [consultancyAnswers, setConsultancyAnswers] =
    useState<StrategyAnswers | null>(null);
  const [userTracks, setUserTracks] = useState<UserTrack[]>([]);

  const url = process.env.REACT_APP_API_URL;

  const fetchDescription = async (): Promise<void> => {
    const response = await axios.get<{ data: { description: string | null } }>(
      `${url}/api/labels/get-description/${id}`
    );
    setDescription(response.data.data.description);
    setDescriptionExists(response.data.data.description ? true : false);
  };
  const fetchLabel = async (): Promise<void> => {
    const response = await axios.get<{ data: { label: string | null } }>(
      `${url}/api/labels/get-label/${id}`
    );
    setLabel(response.data.data.label);
    setLabelExists(response.data.data.label ? true : false);
  };
  const fetchAppointments = async (): Promise<void> => {
    const response = await axios.get<{ data: { appointment: string }[] }>(
      `${url}/api/labels/get-appointments/${id}`
    );
    setAppointments(response.data.data);
  };
  const fetchFormAnswers = async (): Promise<void> => {
    const response = await axios.get<{ data: Array<any> }>(
      `${url}/api/members/form-answers/${id}`
    );
    setFormAnswers(response.data.data);
  };
  const fetchMemberDetails = async (): Promise<void> => {
    const response = await axios.get<{ data: Member }>(
      `${url}/api/members/${id}`
    );
    setMember(response.data.data);
  };

  const fetchStrategyAnswers = async () => {
    try {
      const response = await axios.get(
        `${url}/api/members/strategy-answers/${id}`
      );
      if (response.data.status === "success") {
        setStrategyAnswers(response.data.data);
      }
    } catch (error) {
      console.error(
        "Strateji anketi cevapları alınırken bir hata oluştu:",
        error
      );
    }
  };
  const fetchConsultancyAnswers = async () => {
    try {
      const response = await axios.get(
        `${url}/api/members/consultancy-answers/${id}`
      );
      if (response.data.status === "success") {
        setConsultancyAnswers(response.data.data);
      }
    } catch (error) {
      console.error(
        "Strateji anketi cevapları alınırken bir hata oluştu:",
        error
      );
    }
  };

  const fetchUserTracks = async () => {
    try {
      const response = await axios.get(`${url}/api/members/user-track/${id}`);
      if (response.data.status === "success") {
        setUserTracks(response.data.data.tracks);
      }
    } catch (error) {
      console.error(
        "Kullanıcı takip bilgileri alınırken bir hata oluştu:",
        error
      );
    }
  };
  useEffect(() => {
    fetchDescription();
    fetchLabel();
    fetchAppointments();
    fetchFormAnswers();
    fetchMemberDetails();
    fetchStrategyAnswers();
    fetchConsultancyAnswers();
    fetchUserTracks();
  }, [id]);

  if (!member) {
    return <div>Veriler Geliyor ...</div>;
  }

  const handleDescriptionSubmit = async (): Promise<void> => {
    if (!descriptionExists && description) {
      await axios.post(`${url}/api/labels/create-description`, {
        memberId: id,
        description,
      });
      alert("Müşteri durumu başarı ile güncellendi");
    } else if (descriptionExists && description) {
      await axios.patch(`${url}/api/labels/update-description`, {
        memberId: id,
        description,
      });
      alert("Müşteri durumu başarı ile güncellendi");
    }
    fetchDescription();
  };

  const handleTagSubmit = async (): Promise<void> => {
    if (!labelExists && label) {
      const response = await axios.post(`${url}/api/labels/create-label`, {
        memberId: id,
        label,
      });

      if (response.data.status === "success") {
        setLabel(response.data.data.label);
        alert("Müşteri durumu başarı ile güncellendi");
      } else {
        alert("Hata oldu.Müşteri durumu güncellenmedi");
        console.error(response.data.message);
      }
    } else if (labelExists && label) {
      const response = await axios.patch(`${url}/api/labels/update-label`, {
        memberId: id,
        label,
      });

      if (response.data.status === "success") {
        setLabel(response.data.data.label);
        alert("Müşteri durumu başarı ile güncellendi");
      } else {
        alert("Hata oldu.Müşteri durumu güncellenmedi");
        console.error(response.data.message);
      }
    }
    fetchLabel(); // Etiketi yeniden fetch ederiz ki labelExists state'i güncellensin
  };
  const handleAppointmentSubmit = async (): Promise<void> => {
    await axios.post(`${url}/api/labels/create-appointment`, {
      memberId: id,
      appointment: appointmentDate,
    });
    setAppointmentDate(""); // input alanını sıfırlıyoruz
    fetchAppointments(); // yeni randevuyu listeye eklemek için randevuları yeniden çekiyoruz
  };

  const changePaymentMethod = async () => {
    setLoading(true);
    try {
      await memberService.updatePaymentMethod(+id!);
      fetchMemberDetails();
    } catch (error) {
      alert("Bir Hata Olustu");
      setOpen(false);
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };
  return (
    <div>
      <h1 className="text-4xl text-center font-bold py-8 text-gray-900">
        {member.firstName} {member.lastName}
      </h1>
      <div className="grid grid-cols-2 gap-4 bg-white p-4 rounded-md">
        <div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900">
              Genel Bilgiler
            </h2>
            <ul className="list-disc list-inside pl-4">
              <li>ID: {member.id}</li>
              <li>İsim: {member.firstName}</li>
              <li>Soyisim: {member.lastName}</li>
              <li>Email: {member.email}</li>
              {member && (
                <li>
                  Telefon:{" "}
                  {member.gsmNumber
                    ? member.gsmNumber
                    : member.billingAddresses &&
                      member.billingAddresses[0] &&
                      member.billingAddresses[0].gsmNumber
                    ? member.billingAddresses[0].gsmNumber
                    : " - "}
                </li>
              )}
              <li>Kayıt Tarihi: {formatDateTR(member.createdAt)}</li>
              <li>
                <span className="mr-4">
                  Ödeme Yontemi: {member.location} -{" "}
                  {member.location === "TR" ? "Iyzico" : "Stripe"}
                </span>
                <button
                  onClick={() => setOpen(true)}
                  type="button"
                  className="px-2 py-1 bg-yellow-400 text-black text-xs rounded-md"
                >
                  Ödeme Yöntemini Değiştir
                </button>
              </li>
              <li>Kayıt Sayfası: {member.registerFrom && member.registerFrom.length > 150 ? `${member.registerFrom.slice(0, 150)}   ......` : member.registerFrom}</li>
              <li>Tedarikçi ise mağaza ismi: {member.producer?.producerName ? member.producer?.producerName: "-"}</li>
            </ul>
          </div>
          {formAnswers && formAnswers.length > 0 && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold text-gray-900">
                Anket Cevapları
              </h2>
              <ul className="list-disc list-inside pl-4">
                {formAnswers.map((answer, index) => {
                  const entries = Object.entries(answer.answers) as [
                    string,
                    string | string[] | { state: string; country: string }
                  ][];
                  if (entries.length === 0) {
                    // Bu anket cevabı boşsa, hiçbir şey render etme
                    return null;
                  }

                  return entries.map(([questionNumber, answerValue]) => {
                    let displayText: string;
                    if (Array.isArray(answerValue)) {
                      displayText = answerValue.filter((v) => v).join(", ");
                    } else if (
                      typeof answerValue === "object" &&
                      answerValue !== null &&
                      "state" in answerValue &&
                      "country" in answerValue
                    ) {
                      displayText = `${answerValue.state}, ${answerValue.country}`;
                    } else {
                      displayText = answerValue as string;
                    }

                    return (
                      <li key={questionNumber}>
                        {`${
                          formQuestions[parseInt(questionNumber) - 1] ||
                          "Şehir - Ülke"
                        }: ${displayText}`}
                      </li>
                    );
                  });
                })}
              </ul>
            </div>
          )}
          <div className="mt-4">
            {strategyAnswers ? (
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  Strateji Anketi Cevapları
                </h2>

                <ul className="list-disc list-inside pl-4">
                  <li>Tanım: {strategyAnswers.definition}</li>
                  <li>
                    Satış Geçmişi:{" "}
                    {(strategyAnswers.sellingHistory || []).join(", ")}
                  </li>
                  <li>Dilekler: {(strategyAnswers.wishes || []).join(", ")}</li>
                  <li>Amazon/Etsy: {strategyAnswers.platform}</li>
                  <li>Seviye: {strategyAnswers.level}</li>
                  <li>Bütçe: {strategyAnswers.budget}</li>
                  <li>
                    Problemler: {(strategyAnswers.problems || []).join(", ")}
                  </li>
                  <li>Önem Derecesi: {strategyAnswers.importance}</li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="mt-4">
            {consultancyAnswers ? (
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  Danışmanlık Anketi Cevapları
                </h2>
                <ul className="list-disc list-inside pl-4">
                  <li>
                    Satış Geçmişi:{" "}
                    {(consultancyAnswers.sellingHistory || []).join(", ")}
                  </li>
                  <li>
                    Dilekler: {(consultancyAnswers.wishes || []).join(", ")}
                  </li>
                  <li>Amazon/Etsy: {consultancyAnswers.platform}</li>
                  <li>Bütçe: {consultancyAnswers.budget}</li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>

          {member.userPackages && member.userPackages.length > 0 && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold text-gray-900">
                Üyelik Paketleri
              </h2>
              <ul className="list-disc list-inside pl-4">
                {member.userPackages.map((userPackage, index) => (
                  <li key={index}>
                    Durum: {userPackage.status}, İptal Tarihi:{" "}
                    {userPackage.cancelDate
                      ? formatDateTR(userPackage.cancelDate)
                      : "Yok"}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {member.invoices && member.invoices.length > 0 && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold text-gray-900">Faturalar</h2>
              <ul className="list-disc list-inside pl-4">
                {member.invoices.map((invoice, index) => (
                  <li key={index}>
                    Fatura Tarihi: {formatDateTR(invoice.billingDate)}, Ödeme
                    Durumu: {invoice.paymentStatus}, Ödenen Tutar:{" "}
                    {invoice.paidPrice} ₺
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Açıklama</h2>
          <textarea
            value={description || ""}
            rows={8}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border rounded-md"
          />
          <button
            onClick={handleDescriptionSubmit}
            className="mt-2 px-4 py-2 bg-yellow-400 text-black rounded-md"
          >
            Kaydet
          </button>
          <div className="grid grid-cols-2 gap-4 py-4">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">
                Kullanıcının Durumu
              </h2>
              <select
                value={label || ""}
                onChange={(e) => setLabel(e.target.value)}
                className="w-full p-2 border rounded-md"
              >
                <option value="">Durum Seçin</option>
                <option value="Aranmamalı (parası yok)">
                  Aranmamalı (parası yok)
                </option>
                <option value="Aranmamalı (bilgi almak istemiyor)">
                  Aranmamalı (bilgi almak istemiyor)
                </option>
                <option value="Aranmamalı (zamanı yok)">
                  Aranmamalı (zamanı yok)
                </option>
                <option value="Aranmamalı (yapmayı düşünmüyor)">
                  Aranmamalı (yapmayı düşünmüyor)
                </option>
                <option value="Aranmamalı (bizi tanımıyor)">
                  Aranmamalı (bizi tanımıyor)
                </option>
                <option value="Aranmamalı (başka firma ile çalışacak)">
                  Aranmamalı (başka firma ile çalışacak)
                </option>
                <option value="Aranmamalı (eğitim parayla verilmez)">
                  Aranmamalı (eğitim parayla verilmez)
                </option>
                <option value="Aranmamalı (youtube yetiyormuş)">
                  Aranmamalı (youtube yetiyormuş)
                </option>
                <option value="Aranmamalı (birebir danışmanlık istiyor)">
                  Aranmamalı (birebir danışmanlık istiyor)
                </option>
                <option value="Aranmamalı (eğitimleri canlı istiyor)">
                  Aranmamalı (eğitimleri canlı istiyor)
                </option>
                <option value="Aranmamalı (dropshipping istiyor)">
                  Aranmamalı (dropshipping istiyor)
                </option>
                <option value="Aranmamalı (bize güvenmiyor)">
                  Aranmamalı (bize güvenmiyor)
                </option>
                <option value="Aranmamalı (yaşı küçük, öğrenci)">
                  Aranmamalı (yaşı küçük, öğrenci)
                </option>
                <option value="Aranmamalı (hakkımızda olumsuz yorumlar görmüş)">
                  Aranmamalı (hakkımızda olumsuz yorumlar görmüş)
                </option>
                <option value="Aranmamalı (teknoloji ile arası yok)">
                  Aranmamalı (teknoloji ile arası yok)
                </option>
                <option value="Mevcut Müşteri">Mevcut Müşteri</option>
                <option value="Meşgul">Meşgul</option>
                <option value="Ulaşılamadı">Ulaşılamadı</option>
                <option value="Randevu">Randevu</option>
                <option value="Düşünmek İstiyor">Düşünmek İstiyor</option>
                <option value="Ödeme Aşamasında">Ödeme Aşamasında</option>
                <option value="Satış Yapıldı">Satış Yapıldı</option>
              </select>
              <button
                onClick={handleTagSubmit}
                className="mt-2 px-4 py-2 bg-yellow-400 text-black rounded-md"
              >
                Etiketi Kaydet
              </button>
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">
                Randevular
              </h2>
              <input
                type="datetime-local"
                value={appointmentDate || ""}
                onChange={(e) => setAppointmentDate(e.target.value)}
                className="w-full p-2 border rounded-md"
              />
              <button
                onClick={handleAppointmentSubmit}
                className="mt-2 px-4 py-2 bg-yellow-400 text-black rounded-md"
              >
                Randevu Oluştur
              </button>
              <div className="mt-4">
                <ul className="list-disc list-inside pl-4">
                  {appointments.map((appointment, index) => (
                    <li key={index}>
                      {appointment.appointment
                        ? formatDateTR(appointment.appointment)
                        : "Henüz randevu verilmedi"}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold text-gray-900">
              Kullanıcı Takip Bilgileri
            </h2>
            {userTracks.length > 0 ? (
              <ul className="list-disc list-inside pl-4">
                {userTracks.map((track, index) => (
                  <li
                    key={index}
                  >{`"${track.page}" sayfasında ${track.totalTimes} dakika.`}</li>
                ))}
              </ul>
            ) : (
              <p>Henüz hiç zaman geçirmemiş.</p>
            )}
          </div>
        </div>
      </div>
      <ChangePaymentMethod
        open={open}
        setOpen={setOpen}
        method={member.location}
        changePaymentMethod={changePaymentMethod}
      />
    </div>
  );
};

export default MemberDetails;
