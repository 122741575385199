import { createContext, useState, useEffect, useContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import authService from "../services/authService";
import memberService from "../services/memberService";


export interface RegisterValues {
    name: string;
    email: string;
    password: string;
}

export interface LoginValues {
    email: string;
    password: string;
}

export interface UserData {
    id:string;
    name:string;
    email: string;
    password: string;
}

export interface UpdatePasswordValues {
  userId: string;
  oldPassword: string;
  newPassword: string;
}

export interface AuthContextValue {
  user: UserData | null;
  error: any;
  isLoading: boolean;
  register: (values: RegisterValues) => void;
  login: (values: LoginValues) => void;
  logout: () => void;
  checkAuthStatus: () => void;
  loginLoading: boolean;
  setUser: React.Dispatch<React.SetStateAction<UserData | null>>;
  registerLoading: boolean;
  updatePassword: (values: UpdatePasswordValues) => void;
  forgotPassword: (email: string) => void;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

const useAuth = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: ReactNode;
}

// create the context provider
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [registerLoading, setRegisterLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  // register user
  const register = (values: RegisterValues) => {
    setRegisterLoading(true);
    authService.registerUser(values)
      .then((res) => {
        navigate('/login');
      })
      .catch((err) => {
        setError(true);
        // alertNotification("error", err.response && err.response.data.message);
      })
      .finally(() => {
        setRegisterLoading(false);
      });
  };

  // login user
  const login = async (values: LoginValues) => {
    setLoginLoading(true);
    try {
      const res = await authService.loginUser(values);
      checkAuthStatus();
      navigate('/dashboard');
      return res;
    } catch (err) {
      throw err;
    } finally {
      setLoginLoading(false);
    }
  };
  

  // logout user
  const logout = () => {
    authService
      .logoutUser()
      .then((res) => {
        setUser(null);
        navigate("/");
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
  };

  // check if user is logged in
  const checkAuthStatus = () => {
    setIsLoading(true);
    authService
      .checkUserAuth()
      .then((res) => {
        setUser(res.data.userData);
        
      })
      .catch((err) => {
        setUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // update user password
  const updatePassword = (values: UpdatePasswordValues) => {
    memberService.updatePassword(values).then((res) => {
      // handle success, e.g. show a success message
    }).catch((err) => {
      // handle error, e.g. show an error message
    });
  };

  // forgotPassword
  const forgotPassword = (email: string) => {
    authService
      .forgotPassword(email)
      .then((res) => {
        // Başarılı işlem için mesaj göster
      })
      .catch((err) => {
        // Hata mesajı göster
      });
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, error, isLoading, register, login, logout, checkAuthStatus,updatePassword,forgotPassword, loginLoading, setUser, registerLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth, AuthContext };
