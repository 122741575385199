import React, { useEffect, useState } from "react";
import httpClient from "../utils/httpClient";
import Notification from "../components/general/Notification";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

interface StoreData {
  storeName: string;
  clientIdentifier: string;
  clientSecret: string;
  refreshTokenUs: string;
  refreshTokenEu: string;
  refreshTokenOther: string;
}
interface Store extends StoreData {
  id: number;
}

const IntegrationPage: React.FC = () => {
  const [show, setShow] = useState(false);
  const [stores, setStores] = useState<Store[]>([]);
  const [formData, setFormData] = useState<StoreData>({
    storeName: "",
    clientIdentifier: "",
    clientSecret: "",
    refreshTokenUs: "",
    refreshTokenEu: "",
    refreshTokenOther: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const userId = 208;

    try {
      const response = await httpClient.post(
        "/api/amazon/create-amazon-store",
        { ...formData, userId }
      );
      setFormData({
        storeName: "",
        clientIdentifier: "",
        clientSecret: "",
        refreshTokenUs: "",
        refreshTokenEu: "",
        refreshTokenOther: "",
      });
      fetchStores();
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    } catch (error) {
      console.error("Failed to save the store data", error);
    }
  };
  // Mağaza listesini çeken fonksiyon
  const fetchStores = async () => {
    const userId = 208; // Kullanıcı ID
    try {
      const response = await httpClient.get(
        `/api/amazon/amazon-store/${userId}`
      );
      setStores(response.data.data);
    } catch (error) {
      console.error("Failed to fetch stores", error);
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const handleDelete = async (id: number) => {
    try {
      await httpClient.delete(`/api/amazon/delete-amazon-store/${id}`);
      fetchStores();
      alert("Mağaza başarılı bir şekilde silindi!");
    } catch (error) {
      console.error("Failed to delete the store", error);
      alert("Mağaza silinirken bir hata oluştu!");
    }
  };

  return (
    <div>
      <Notification
        showNotification={show}
        setShowNotification={setShow}
        title="Başarılı!"
        message="Amazon mağazanız başarılı bir şekilde kaydedildi!"
      />
      <div className="bg-white drop-shadow-2xl py-12 max-w-6xl mx-auto mt-10 rounded-xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-wrap mx-6 ">
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label className="block">
                Store Name:
                <input
                  className="mt-4 block w-full border border-red-600 p-2 rounded-md shadow-md"
                  name="storeName"
                  value={formData.storeName}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label className="block">
                Client Identifier:
                <input
                  className="mt-4 block w-full border border-red-600 p-2 rounded-md shadow-md"
                  name="clientIdentifier"
                  value={formData.clientIdentifier}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label className="block">
                Client Secret:
                <input
                  className="mt-4 block w-full border border-red-600 p-2 rounded-md shadow-md"
                  name="clientSecret"
                  value={formData.clientSecret}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label className="block">
                US Refresh Token:
                <input
                  className="mt-4 block w-full border border-red-600 p-2 rounded-md shadow-md"
                  name="refreshTokenUs"
                  value={formData.refreshTokenUs}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label className="block">
                EU Refresh Token:
                <input
                  className="mt-4 block w-full border border-red-600 p-2 rounded-md shadow-md"
                  name="refreshTokenEu"
                  value={formData.refreshTokenEu}
                  onChange={handleChange}
                  readOnly
                />
              </label>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label className="block">
                FarEast Refresh Token:
                <input
                  className="mt-4 block w-full border border-red-600 p-2 rounded-md shadow-md"
                  name="refreshTokenOther"
                  value={formData.refreshTokenOther}
                  onChange={handleChange}
                  readOnly
                />
              </label>
            </div>
            <div>
              <button
                type="submit"
                className="mt-4 py-2 px-4 bg-yellow-500 text-black font-semibold rounded-lg shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="px-16 py-8">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg text-left text-xs font-semibold tracking-wider py-4">
                Store Name
              </th>
              <th className="px-5 border-b-2 bg-[#1F254B] text-white text-left text-xs font-semibold tracking-wider py-4">
                Client Identifier
              </th>
              <th className="px-5 border-b-2 bg-[#1F254B] text-white text-left text-xs font-semibold tracking-wider py-4">
                Client Secret
              </th>
              <th className="px-5 border-b-2 bg-[#1F254B] text-white text-left text-xs font-semibold tracking-wider py-4">
                US Refresh Token
              </th>
              <th className="px-5 border-b-2 bg-[#1F254B] text-white text-left text-xs font-semibold tracking-wider py-4">
                EU Refresh Token
              </th>
              <th className="px-5 border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg text-left text-xs font-bold tracking-wider py-4">
                Other Refresh Token
              </th>
              <th className="px-5 border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg text-left text-xs font-bold tracking-wider py-4"></th>
            </tr>
          </thead>
          <tbody>
            {stores.map((store) => (
              <tr key={store.id} className="even:bg-[#EDEEF8]">
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  {store.storeName}
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  ******************
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  ******************
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  ******************
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  ******************
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  ******************
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm flex justify-end items-center space-x-2">
                  {/* <button onClick={() => handleUpdate(store)}>
                    <PencilIcon className="h-5 w-5 text-blue-500 hover:text-blue-700" />
                  </button> */}
                  <button onClick={() => handleDelete(store.id)}>
                    <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-700" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IntegrationPage;
