import React, { useState, useEffect } from "react";
import axios from "axios";

interface LabelStats {
  [key: string]: number;
}

interface LabelData {
  [key: string]: {
    data: LabelStats;
  };
}

const SalesRepStats: React.FC = () => {
  const [stats, setStats] = useState<LabelData | null>(null);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${url}/api/data/data-analysis-by-sale-rep`);
        setStats(response.data.data);
      } catch (error) {
        console.error("Error fetching sales rep stats:", error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Veriler Geliyor ...</div>;
  }

  return (
    <div>
      {Object.entries(stats).map(([salesRep, data]) => (
        <div key={salesRep} className="bg-white rounded-lg drop-shadow-2xl py-4 px-4 my-4">
          <h2 className="mb-4 text-2xl font-medium text-red-600">{salesRep}</h2>
          <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-7">
            {Object.entries(data.data).reverse().map(([name, value]) => (
              <div
                key={name}
                className="flex flex-col justify-center items-center bg-gray-100 px-4 py-4 sm:px-4 xl:px-1"
              >
                <dt className="text-xs text-center font-medium leading-6 text-gray-500">
                  {name}
                </dt>
                <dd className="text-2xl font-medium leading-10 tracking-tight text-gray-900">
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 0, 
                    maximumFractionDigits: 1,
                  }).format(value)}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      ))}
    </div>
  );
};

export default SalesRepStats;
