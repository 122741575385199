import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  startOfMonth,
  endOfMonth,
  format,
  addMonths,
  subMonths,
  differenceInDays,
} from "date-fns";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";

interface Habit {
  id: string;
  name: string;
  days: {
    day: number;
    month: string;
    year: number;
    checked: boolean;
  }[];
}

interface Checkbox {
  habitId: string;
  day: number;
  checked: boolean;
}

interface HabitPageProps {
  habits: Habit[];
  onHabitAdded: (updatedHabits: Habit[]) => void;
  onHabitDeleted: () => void;
}

const Habits: React.FC<HabitPageProps> = ({
  habits,
  onHabitAdded,
  onHabitDeleted,
}) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

  const firstDayOfCurrentMonth = startOfMonth(currentMonth);
  const lastDayOfCurrentMonth = endOfMonth(currentMonth);
  const daysInMonth =
    differenceInDays(lastDayOfCurrentMonth, firstDayOfCurrentMonth) + 1;

  const handlePreviousMonth = () => {
    setCurrentMonth((prevMonth) => subMonths(prevMonth, 1));
  };
  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => addMonths(prevMonth, 1));
  };

  const url = process.env.REACT_APP_API_URL;

  const handleDeleteHabit = async (id: string) => {
    await axios.delete(`${url}/api/habits/${id}`);
    // Notify parent component that a habit was deleted
    if (onHabitDeleted) {
      onHabitDeleted();
    } // Trigger the function to refetch events in the parent component
  };

  const handleCheckboxChange = async (
    habitId: string,
    day: number,
    checked: boolean
  ) => {
    const month = format(currentMonth, "MMMM");
    const year = currentMonth.getFullYear();

    try {
      // Send the patch request to update the habit status in the database
      await axios.patch(`${url}/api/habits/${habitId}`, {
        day,
        month,
        year,
        checked,
      });

      // Find the habit in the local state
      const updatedHabit = habits.find((habit) => habit.id === habitId);

      if (updatedHabit) {
        // Get the index of the day in the habit's days array
        const dayIndex = updatedHabit.days.findIndex(
          (status) =>
            status.day === day && status.month === month && status.year === year
        );

        if (dayIndex >= 0) {
          // Update the habit's days array with the new checked status
          const updatedDays = [
            ...updatedHabit.days.slice(0, dayIndex),
            { day, month, year, checked },
            ...updatedHabit.days.slice(dayIndex + 1),
          ];

          // Update the habits array with the new habit data
          const updatedHabits = habits.map((habit) =>
            habit.id === habitId ? { ...habit, days: updatedDays } : habit
          );

          // Update the state with the new habits array
          onHabitAdded(updatedHabits);
        } else {
          // Add the new day status to the days array
          const updatedDays = [
            ...updatedHabit.days,
            { day, month, year, checked },
          ];

          // Update the habits array with the new habit data
          const updatedHabits = habits.map((habit) =>
            habit.id === habitId ? { ...habit, days: updatedDays } : habit
          );

          // Update the state with the new habits array
          onHabitAdded(updatedHabits);
        }
      }
    } catch (error) {
      console.error("Error updating habit status:", error);
    }
  };
  return (
    <div className="px-4 ml-4 mt-4 drop-shadow-2xl sm:px-6 lg:px-8">
      <header className="flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          <time dateTime={format(currentMonth, "yyyy-MM")}>
            {format(currentMonth, "MMMM yyyy")}
          </time>
        </h1>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <div
              className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
              aria-hidden="true"
            />
            <button
              type="button"
              onClick={handlePreviousMonth}
              className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Önceki Ay</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
            >
              {format(currentMonth, "MMMM")}
            </button>

            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={handleNextMonth}
              className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Sonraki Ay</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </header>
      <div className="mt-8 ml-4 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="flex items-center">
            <div className="relative flex w-auto items-center rounded-md bg-gray-100 shadow-sm md:items-stretch">
              <div
                className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
                aria-hidden="true"
              />

              <div className="inline-block py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{ minWidth: "240px" }}
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Başlık
                        </th>
                        {[...Array(daysInMonth)].map((_, index) => (
                          <th
                            key={index}
                            scope="col"
                            style={{ minWidth: "36px" }}
                            className="py-3.5 pl-1 pr-2 text-center text-sm font-semibold text-gray-900"
                          >
                            {index + 1}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {habits.map((habit) => (
                        <tr key={habit.id}>
                          <td
                            className="px-4 py-2 "
                            style={{ minWidth: "240px" }}
                          >
                            {habit.name}
                            <button
                              onClick={() => {
                                handleDeleteHabit(habit.id);
                              }}
                              className="ml-2 p-1 text-gray-400 hover:text-red-600"
                            >
                              <TrashIcon className="h-4 w-4" />
                            </button>
                          </td>
                          {[...Array(daysInMonth)].map((_, dayIndex) => {
                            const day = dayIndex + 1;
                            const checkbox = habit.days.find(
                              (status: any) => status.day === day
                            );

                            const isChecked =
                              checkbox?.checked &&
                              checkbox.month === format(currentMonth, "MMMM") &&
                              checkbox.year === currentMonth.getFullYear()
                                ? true
                                : false;

                            return (
                              <td key={day} className="px-2 py-4">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      habit.id,
                                      day,
                                      e.target.checked
                                    )
                                  }
                                />
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Habits;
