import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { ApexOptions } from 'apexcharts';

const RegistrationSourceChart = () => {
  const [chartData, setChartData] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      height: 400,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
      title: {
        text: 'Kaynak',
      },
    },
    yaxis: {
      title: {
        text: 'Kullanıcı Sayısı',
      },
    },
    series: [{
      name: 'Kullanıcı Sayısı',
      data: [],
    }],
  });

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [packageStatus, setPackageStatus] = useState('all'); // Add packageStatus state
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, packageStatus]);

  const fetchData = async () => {
    const queryParams = new URLSearchParams({
      startDate,
      endDate,
      packageStatus // Include packageStatus in the API request
    }).toString();

    const { data } = await axios.get<{ status: string, data: [string, number][] }>(`${url}/api/members/registration-stats?${queryParams}`);
    const categories = data.data.map(item => item[0]);
    const seriesData = data.data.map(item => item[1]);

    setChartData(prevChartData => ({
      ...prevChartData,
      xaxis: {
        ...prevChartData.xaxis,
        categories: categories,
      },
      series: [{
        name: 'Kullanıcı Sayısı',
        data: seriesData,
      }],
    }));
  };

  return (
    <div>
      <div style={{ marginBottom: '5px', textAlign: 'right' }}>
        <label>Başlangıç: </label>
        <input
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <label>  Bitiş: </label>
        <input
          type="date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
        {/* Package Status Selection */}
        {/* <select value={packageStatus} onChange={e => setPackageStatus(e.target.value)} style={{ marginLeft: '10px' }}>
          <option value="all">Tümü</option>
          <option value="active">Aktif</option>
          <option value="passive">Pasif</option>
        </select> */}
      </div>
      <Chart options={chartData} series={chartData.series} type="bar" height={800} />
    </div>
  );
};

export default RegistrationSourceChart;
