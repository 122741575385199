import { useState } from "react";
import ConsultancyFormDataTable from "../components/data/ConsultancyFormTable";
import DataTable from "../components/data/DataTable";
import DistributeConsultancyData from "../components/data/DistributeConsultancyData";
import DistributeMembers from "../components/data/DistributeMembers";
import DistributeStrategyData from "../components/data/DistributeStrategyData";
import FormDataTable from "../components/data/FormDataTable";

export default function Data() {
  const [refreshKey, setRefreshKey] = useState(0);
  const [activeTable, setActiveTable] = useState("DataTable"); 

  const handleDataChange = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Data Dağıtım Sistemi
          </h1>
        </div>
        <DistributeMembers onDataChange={handleDataChange} />
        <DistributeStrategyData onDataChange={handleDataChange} />
        <DistributeConsultancyData onDataChange={handleDataChange} />
        <div className="ml-4 py-4">
          <button
            className="bg-gray-600 text-white text-sm px-4 py-2 rounded-md mr-4"
            onClick={() => setActiveTable("DataTable")}
          >
            Genel Data{" "}
          </button>
          <button
            className="bg-gray-600 text-white text-sm px-4 py-2 rounded-md mr-4"
            onClick={() => setActiveTable("FormDataTable")}
          >
            Strateji Görüşmesi Datası
          </button>
          <button
            className="bg-gray-600 text-white text-sm px-4 py-2 rounded-md mr-4"
            onClick={() => setActiveTable("ConsultancyFormDataTable")}
          >
            Danışmanlık Görüşmesi Datası
          </button>
        </div>
        {activeTable === "DataTable" && <DataTable refreshKey={refreshKey} />}
        {activeTable === "FormDataTable" && (
          <div>
            <h1 className="text-4xl text-left ml-4 font-bold tracking-tight text-gray-900">
              Strateji Görüşmesi Datası
            </h1>
            <div className="py-4">
              <FormDataTable />
            </div>
          </div>
        )}
        {activeTable === "ConsultancyFormDataTable" && (
          <div>
            <h1 className="text-4xl text-left ml-4 font-bold tracking-tight text-gray-900">
              Danışmanlık Görüşmesi Datası
            </h1>
            <div className="py-4">
              <ConsultancyFormDataTable />
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
