import CancelReasonChart from "../components/members/CancelCharts";
import NetPromoterScoreChart from "../components/members/NpsChart";
import SorryChart from "../components/members/SorryChart";
import ChurnRateStats from "../components/metrics/ChurnRates";
import LtvCacStats from "../components/metrics/LtvCac";


export default function Support() {

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          </h1>
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Churn Rate
        </h1>
        <p className="text-center">(O ay üyeliğe devam etmeyen kullanıcı sayısı / Ay başındaki aktif kullanıcı sayısı)</p>
        <div className="py-4">
          <ChurnRateStats />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          LTV - ARPU
        </h1>
        <p className="text-center">(LTV: Life time value, ARPU: Average revenue per user)</p>
        <div className="py-4">
          <LtvCacStats />
        </div>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Kullanıcıların İptal Nedenleri
            </h3>
            <CancelReasonChart />
          </div>
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Rexven'i Tavsiye eder misiniz? (NPS)
            </h3>
            <NetPromoterScoreChart />
          </div>
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Rexven olmazsa ne hissedersin?
            </h3>
            <SorryChart />
          </div>
        </div>
      </main>
    </div>
  );
}
