import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';

const DailyRevenueChart = () => {
    const [chartData, setChartData] = useState<{
        options: {
          chart: {
            id: string;
          };
          colors: string[];
          xaxis: {
            categories: string[];
            labels: {
              show: boolean;
              rotate: number;
              rotateAlways: boolean;
              hideOverlappingLabels: boolean;
              trim: boolean;
              minHeight: undefined;
              maxHeight: number;
            };
          };
          yaxis: {
            labels: {
              formatter: (value: number) => string;
            }
          };
        };
        series: {
          name: string;
          data: number[];
        }[];
      }>({
        options: {
          chart: {
            id: 'daily-revenue-chart',
          },
          colors: ['#D80032'],
          xaxis: {
            categories: [],
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: false,
              hideOverlappingLabels: true,
              trim: true,
              minHeight: undefined,
              maxHeight: 120,
            },
          },
          yaxis: {
            labels: {
              formatter: (value: number) => `${value} ₺`
            }
          }
        },
        series: [
          {
            name: 'Günlük Ciro (₺)',
            data: [],
          },
        ],
      });

  const [selectedRange, setSelectedRange] = useState('60');
  const timeRanges = ['7', '30', '60', '90', '120', '150', '180'];

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(`${url}/api/payments/daily`);
      const { data: dailyActiveMembers } = data;

      filterDataByRange(dailyActiveMembers, selectedRange);
    };

    fetchData();
  }, [selectedRange]);

  const filterDataByRange = (data: Record<string, number>, range: string) => {
    let filteredData: Record<string, number> = {};
    const today = new Date();
    const endDate = new Date(today.setDate(today.getDate() - (range === 'all' ? 0 : parseInt(range))));

    for (const date in data) {
      const dataDate = new Date(date);
      if (dataDate >= endDate) {
        filteredData[date] = data[date];
      }
    }

    setChartData((prevChartData) => ({
      ...prevChartData,
      options: {
        ...prevChartData.options,
        xaxis: {
          ...prevChartData.options.xaxis,
          categories: Object.keys(filteredData),
        },
      },
      series: [
        {
          ...prevChartData.series[0],
          data: Object.values(filteredData),
        },
      ],
    }));
  };

  const renderRangeButtons = () => (
    <div className="flex justify-center mt-4">
      {timeRanges.map((range, index) => (
        <button
          key={range}
          onClick={() => setSelectedRange(range)}
          className={`bg-gray-500 hover:bg-red-600 text-white text-xs lg:text-base font-bold py-2 px-4 rounded ${selectedRange === range ? ' bg-yellow-400 text-black hover:bg-gray-600' : ''} ${index !== 0 ? 'ml-2' : ''}`}
        >
          {`Son ${range} Gün`}
        </button>
      ))}
    </div>
  );


  return (
    <div>
      {renderRangeButtons()}
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        width="100%"
        height="400"
      />
    </div>
  );
};

export default DailyRevenueChart;
