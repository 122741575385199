import { useState, useEffect } from "react";
import axios from "axios";
import {formatDateTR} from "../../helpers/date";
import Pagination from "../general/Pagination";
import { Link } from "react-router-dom";

export interface UserPackage {
  status: string;
  packagePlanId: number;
  cancelDate: string | null;
}
export interface UserTracks {
  total_times: string;
  page: string;
}

export interface Invoice {
  billingDate: string;
  paymentStatus: string;
  paidPrice: string;
}

export interface Member {
  id: string;
  first_name: string;
  last_name: string;
  gsm_number: string | null
  email: string;
  status: string;
  created_at: string;
  user_packages: UserPackage[];
  invoices: Invoice[];
  total_times: number;
}

interface DataTableProps {
    refreshKey: number;
  }

export default function DataTable({refreshKey}:DataTableProps) {
  const [members, setMembers] = useState<Member[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMembers = async (): Promise<void> => {
      const response = await axios.get<{ data: { total: number; members: Member[] } }>(
        `${url}/api/data/all-data?page=${page}&limit=${limit}`
      );
      console.log(response.data.data.members)
      setMembers(response.data.data.members);
      
      setTotalPageCount(Math.ceil(response.data.data.total / limit));
    };
  
    fetchMembers();
  }, [page, limit, url , refreshKey]);

  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
          
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Sıra No
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İsim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Soyisim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Katılış Tarihi
                    </th>
                    {/* <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Telefon
                    </th> */}
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Geçirdiği Süre
                    </th>

                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {members.map((member) => (
                    <tr key={member.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        {member.id}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        {member.first_name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {member.last_name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.email}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {formatDateTR(member.created_at)}
                      </td>
                      {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.gsmNumber}
                      </td> */}
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.total_times} dakika
                      </td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination page={page} setPage={setPage} totalPageCount={totalPageCount} />
      </div>
    </div>
  );
}
