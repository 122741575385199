import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";

type ChartData = {
  options: any;
  series: {
    name: string;
    data: number[];
  }[];
};

const RevenueChart = () => {
  const [selectedYear, setSelectedYear] = useState(2024);

  const [chartData, setChartData] = useState<ChartData>({
    options: {
      chart: {
        id: "revenue-chart",
      },
      colors: ["#3D0C11", "#D80032", "#F78CA2"],
      xaxis: {
        categories: [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          trim: true,
          minHeight: undefined,
          maxHeight: 120,
        },
      },
      yaxis: {
        labels: {
          formatter: (value: number) =>
            `${new Intl.NumberFormat("en-US").format(value)} ₺`,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) =>
          `${new Intl.NumberFormat("en-US").format(val)}₺`,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "75%",
          endingShape: "rounded",
          distributed: false,
          dataLabels: {
            position: "top",
          },
          stacked: true,
        },
      },
    },
    series: [],
  });

  const [timePeriod, setTimePeriod] = useState<"weekly" | "monthly">("monthly");
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      if (timePeriod === "weekly") {
        const endpoint = "/api/payments/weekly";
        const response = await axios.get(`${url}${endpoint}`);
        const { parsedStats } = response.data.data;
        const categories = parsedStats
          .reverse()
          .map((stat: any) => new Date(stat.week).toLocaleDateString());
        const revenues = parsedStats.map((stat: any) => stat.totalRevenue);

        setChartData((prevChartData) => ({
          ...prevChartData,
          options: {
            ...prevChartData.options,
            xaxis: {
              ...prevChartData.options.xaxis,
              categories: categories,
            },
          },
          series: [
            {
              name: "Haftalık Toplam Ciro",
              data: revenues,
            },
          ],
        }));
      } else {
        const responseEc = await axios.get(
          `${url}/api/payments/monthly?year=${selectedYear}`
        );
        const monthlyEcosystemRevenue = responseEc.data.data.monthlyRevenue;

        const responseFin = await axios.get(
          `${url}/api/finance/get-all?year=${selectedYear}`
        );
        const allFinances = responseFin.data.data;

        let monthlyProviderRevenue: any = {};
        let monthlyOtherRevenue: any = {};

        const sortedMonths = Object.keys(monthlyEcosystemRevenue);

        allFinances.forEach((finance: any) => {
          const month = new Date(finance.date).toLocaleString("tr-TR", {
            month: "long",
          });

          if (finance.category === "Hizmet Sağlayıcı Geliri") {
            monthlyProviderRevenue[month] =
              (monthlyProviderRevenue[month] || 0) + finance.amount;
          } else if (finance.category === "Diğer Gelirler") {
            monthlyOtherRevenue[month] =
              (monthlyOtherRevenue[month] || 0) + finance.amount;
          }
        });

        // Ensure monthlyOtherRevenue is in the same order as monthlyEcosystemRevenue
        const sortedOtherRevenues = sortedMonths.map(
          (month) => monthlyOtherRevenue[month] || 0
        );

        setChartData((prevChartData) => ({
          ...prevChartData,
          options: {
            ...prevChartData.options,
            chart: {
              ...prevChartData.options.chart,
              stacked: true,
              type: "bar",
              height: 450,
            },
            plotOptions: {
              ...prevChartData.options.plotOptions,
              bar: {
                ...prevChartData.options.plotOptions.bar,
                horizontal: false,
                columnWidth: "75%",
                endingShape: "rounded",
                dataLabels: {
                  position: "center",
                },
              },
            },
            xaxis: {
              ...prevChartData.options.xaxis,
              categories: sortedMonths,
            },
          },
          series: [
            {
              name: "Ekosistem Gelirleri",
              data: Object.values(monthlyEcosystemRevenue),
            },
            {
              name: "Hizmet Sağlayıcı Gelirleri",
              data: Object.values(monthlyProviderRevenue),
            },
            {
              name: "Diğer Gelirler",
              data: sortedOtherRevenues,
            },
          ],
        }));
      }
    };

    fetchData();
  }, [timePeriod, selectedYear]);

  return (
    <div>
      <div className="flex justify-center mt-4">
        <div className="mr-4">
          <button
            className="bg-gray-500 hover:bg-red-600 text-white text-xs lg:text-base font-bold py-2 px-4 rounded"
            onClick={() => setTimePeriod("weekly")}
          >
            Haftalık
          </button>
        </div>
        <button
          className="bg-gray-500 hover:bg-red-600 text-white text-xs lg:text-base font-bold py-2 px-4 rounded"
          onClick={() => setTimePeriod("monthly")}
        >
          Aylık
        </button>
        <div className="ml-4">
          <select
            className="bg-white hover:bg-red-600 text-black text-xs lg:text-base font-bold py-2 mr-4 rounded"
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          >
            <option value={2023}>2023</option>
            <option value={2024}>2024</option>
          </select>
        </div>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        width="100%"
        height="600"
      />
    </div>
  );
};

export default RevenueChart;
