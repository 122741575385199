import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import httpClient from "../../utils/httpClient";
import { AuthContextValue, useAuth } from "../../context/AuthContext";

const countriesUs = [
  "Amazon.com",
  "Amazon.com.mx",
  "Amazon.ca",
  "Amazon.com.br",
];
const countriesEu = [
  "Amazon.co.uk",
  "Amazon.de",
  "Amazon.fr",
  "Amazon.es",
  "Amazon.it",
  "Amazon.nl",
  "Amazon.com.tr",
  "Amazon.com.au",
  "Amazon.be",
  "Amazon.ae",
  "Amazon.se",
  "Amazon.eg",
];
const countriesFe = ["Amazon.co.jp", "Amazon.in", "Amazon.sg"];

interface Props {
  setMarketplaceName: React.Dispatch<any>;
  marketplaceName: any;
}
const NestedDropDown: React.FC<Props> = ({
  setMarketplaceName,
  marketplaceName,
}) => {
  //   const [selected, setSelected] = useState<any>();
  const [options, setOptions] = useState<any>();
  const { user } = useAuth() as AuthContextValue;

  const getStores = async () => {
    try {
      const res = await httpClient.get(`/api/amazon/amazon-store/208`);
      const temp = res.data.data.map((item: any, index: number) => {
        if (item.refreshTokenUs) {
          item.countries = countriesUs;
        }
        if (item.refreshTokenEu) {
          item.countries = [...item.countries, countriesEu];
        }
        if (item.refreshTokenOther) {
          item.countries = [...item.countries, countriesFe];
        }
        if (index === 0) {
          setMarketplaceName({
            marketplace: "Amazon.com",
            storeId: item.id,
            storeName: item.storeName,
          });
        }
        const tempItem = item.countries.map((country: any) => {
          return {
            storeName: item.storeName,
            marketplace: country,
            storeId: item.id,
          };
        });
        item.countries = tempItem;
        return item;
      });

      setOptions(temp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStores();
  }, []);

  return (
    <Listbox value={marketplaceName} onChange={setMarketplaceName}>
      <div className="relative mt-1 min-w-[240px]">
        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <span className="block truncate">
            {marketplaceName
              ? marketplaceName?.storeName + " - " + marketplaceName.marketplace
              : "Seçiniz"}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 z-40  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {options?.map((person: any, personIdx: number) => (
              <>
                <p className="p-2">{person.storeName}</p>
                {person?.countries?.map((item: any) => {
                  return (
                    <Listbox.Option
                      key={personIdx + Math.random() * 1000}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 z-40 ${
                          active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={item}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {item.marketplace}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  );
                })}
              </>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default NestedDropDown;
