import { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../general/Pagination";

export interface Payment {
  billingDate: string;
  paymentStatus: string;
  paidPrice: string;
  currency: string;
  owner: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    gsmNumber:number;
  };
}

export default function PaymentTable() {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPayments = async (): Promise<void> => {
    const response = await axios.get(`${url}/api/payments?page=${page}&limit=${limit}`);
    setPayments(response.data.data.payments);

    setTotalPageCount(Math.ceil(response.data.data.total / limit));
    
    };

    fetchPayments();
  }, [page]);

  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Kullanıcı ID
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İsim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Soyisim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Telefon
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text sm font-semibold text-gray-900"
                    >
                      Ödeme Tarihi
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Ödeme Durumu
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Ödenen Tutar
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Para Birimi
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300">
                  {payments && payments.map((payment, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 text-sm text-gray-900 sm:pl-0">
                        {payment.owner?.id}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-900">
                        {payment.owner?.firstName}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-900">
                        {payment.owner?.lastName}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-900">
                        {payment.owner?.email}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {payment.owner?.gsmNumber ? payment.owner?.gsmNumber : "-"}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-900">
                      {payment.billingDate}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-900">
                        {payment.paymentStatus}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-900">
                        {payment.paidPrice}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-900">
                        {payment.currency}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="mt-8">
        <Pagination page={page} setPage={setPage} totalPageCount={totalPageCount} />

        </div>
      </div>
    </div>
  );
}
