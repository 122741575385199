import React from "react";
import { formatDateTR } from "../../helpers/date";
import { seperateNumberWithComma } from "../../utils/numberFormatter";

interface Props {
  data: any;
}
export const addCurrencySymbol = (
  value: number | undefined,
  marketplaceName: string
) => {
  if (value === undefined) return "";
  let symbol = "$";
  switch (marketplaceName) {
    case "Amazon.ca":
      symbol = "CA$";
      break;
    case "Amazon.com.mx":
      symbol = "MX$";
      break;
  }
  const formattedValue = seperateNumberWithComma(
    Number(Math.abs(value).toFixed(2))
  );
  return `${value < 0 ? "-" : ""}${symbol}${formattedValue}`;
};

const FeeStatsTable: React.FC<Props> = ({ data }) => {
  
  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl my-8">
      {/* <div className="flow-root"> */}
      <div className="relative overflow-x-auto overflow-y-auto">
        <div className="inline-block min-w-full align-middle"></div>
        <table className="min-w-full leading-normal">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Pazaryeri
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Kesinti Tarihi
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Compensated Clawback
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                FBA Disposal Fee
              </th>

              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Inbound Transportation
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Long Term Storage
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Removal Fee
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Storage Fee
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Replacement Refund
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Reversal Reimbursement
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Subscription
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-6 py-3 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Warehouse Damage
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((order: any, index: number) => {
              return (
                <tr key={index} className="bg-white">
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {order.marketplaceName}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {formatDateTR(order.postedDate)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.compensatedClawbackAdjustmentValueAmount || 0, order.marketplaceName)}
                  </td>
                  {/* <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {order.FBACustomerReturnPerUnitFeeValueAmount || 0}
                  </td> */}
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.fbaDisposalFeeValueAmount || 0, order.marketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.fbaInboundTransportationFeeValueAmount || 0, order.marketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.fbaLongTermStorageFeeValueAmount || 0, order.marketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.fbaRemovalFeeValueAmount || 0, order.marketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.fbaStorageFeeValueAmount || 0, order.marketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.freeReplacementRefundItemsAdjustmentValueAmount || 0, order.marketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.reversalReimbursementAdjustmentValueAmount || 0, order.marketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.subscriptionValueAmount || 0, order.marketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(order.warehouseDamageAdjustmentValueAmount || 0, order.marketplaceName)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FeeStatsTable;
