import React, { useState, useEffect } from "react";
import axios from "axios";

interface LabelStats {
  [key: string]: number;
}

interface LabelRates {
  [key: string]: string;
}

interface LabelData {
  data: LabelStats;
  rates: LabelRates;
}

const DataAnalysis: React.FC = () => {
  const [stats, setStats] = useState<LabelData | null>(null);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${url}/api/data/data-analysis`);
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching label stats:", error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Veriler Geliyor ...</div>;
  }

  const reversedEntries = [...Object.entries(stats.data)].reverse();

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl py-4 px-4">
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-7">
        {reversedEntries.map(([name, value]) => (
          <div
            key={name}
            className="flex flex-col justify-center items-center bg-gray-100 px-4 py-4 sm:px-6 xl:px-8"
          >
            <dt className="text-xs text-center font-medium leading-6 text-gray-500">
              {name}
            </dt>
            <dd className="text-3xl font-medium leading-10 tracking-tight text-gray-900">
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0, 
                maximumFractionDigits: 1,
              }).format(value)}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default DataAnalysis;

