import React, { useState } from "react";

import {
  Flash,
  Shop,
  Strongbox2,
  Wallet2,
  PercentageSquare,
  ShopRemove,
  ArrowDown2,
  ArrowUp2,
} from "iconsax-react";
import { addCurrencySymbol } from "./AmazonOrders";

interface Props {
  data: any;
  orderData: any;
  isLoading: boolean;
  marketplaceName: string;
}

const SummaryCardWrapper: React.FC<Props> = ({
  data,
  orderData,
  isLoading,
  marketplaceName,
}) => {
  const [openAmzFee, setOpenAmzFee] = useState(false);
  const [openProductCost, setOpenProductCost] = useState(false);
  const [openTax, setOpenTax] = useState(false);
  const [openReturn, setOpenReturn] = useState(false);

  return (
    <div className="grid gap-4 grid-cols-10 justify-between items-center my-5">
      {/* Filter Section */}
      {/* <div className="col-span-10">
       
      </div> */}
      {/* CARDS Section */}
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Strongbox2
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#820CF8" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#4338CA" }}>
            Toplam Ciro
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#4338CA]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#4338CA" }}>
              {addCurrencySymbol(data?.data.data.totalPrincipalAmount,marketplaceName)}
              <p className="text-xs">
                Sipariş: {orderData?.data.data.orders.total}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Wallet2
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#34D399" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#047857" }}>
            Net Kar/Zarar
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#047857]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#047857" }}>
              {addCurrencySymbol(
                data?.data.data.totalPrincipalAmount +
                  data?.data.data.totalFbaPerUnitFulfillmentFeeAmount +
                  data?.data.data.totalCommissionAmount +
                  data?.data.data.totalShippingChargeAmount +
                  data?.data.data.totalShippingChargebackAmount +
                  data?.data.data.totalShippingTaxAmount +
                  data?.data.data.totalPromotionAmount +
                  data?.data.data.totalFbaDisposalFeeValueAmount +
                  data?.data.data.totalFbaInboundTransportationFeeValueAmount +
                  data?.data.data.totalFbaStorageFeeValueAmount +
                  data?.data.data.totalSubscriptionValueAmount +
                  data?.data.data.totalFbaRemovalFeeValueAmount +
                  data?.data.data.totalFbaLongTermStorageFeeValueAmount +
                  data?.data.data.totalReversalReimbursementAdjustmentValueAmount +
                  data?.data.data
                    .totalCompensatedClawbackAdjustmentValueAmount +
                  data?.data.data
                    .totalFreeReplacementRefundItemsAdjustmentValueAmount +
                  data?.data.data.totalWarehouseDamageAdjustmentValueAmount +
                  data?.data.data.totalFbaCustomerReturnPerUnitFeeValueAmount +
                  data?.data.data.totalProductCogsAmount +
                  data?.data.data.totalShippingCogsAmount +
                  data?.data.data.totalOtherCogsAmount +
                  data?.data.data.totalRefundPrincipalAmount +
                  //data?.data.data.totalRefundCommissionAmount +
                  data?.data.data.totalRefundTaxAmount +
                  data?.data.data.totalNegativeRefundCommisionAmount +
                  data?.data.data
                    .totalRefundMarketplaceFacilitatorTaxPrincipalAmount +
                  data?.data.data.totalAdvertisingTransactionValueAmount +
                  data?.data.data.totalTaxAmount +
                  data?.data.data.totalMarketplaceFacilitatorTaxPrincipalAmount,
                marketplaceName
              )}
            </div>
          )}
        </div>
      </div>
      <div className="shadow-md relative col-span-10  md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <ShopRemove
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#FBBF24" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openAmzFee ? (
            <ArrowUp2
              onClick={() => setOpenAmzFee((prev) => !prev)}
              onBlur={() => setOpenAmzFee(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#B45309"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenAmzFee((prev) => !prev)}
              onBlur={() => setOpenAmzFee(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#B45309"
            />
          )}
          <div className="font-normal" style={{ color: "#B45309" }}>
            Amazon Kesintileri
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#B45309]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#B45309" }}>
              {addCurrencySymbol(
                data?.data.data.totalFbaPerUnitFulfillmentFeeAmount +
                  data?.data.data.totalCommissionAmount +
                  data?.data.data.totalShippingChargeAmount +
                  data?.data.data.totalShippingChargebackAmount +
                  data?.data.data.totalShippingTaxAmount +
                  data?.data.data.totalPromotionAmount +
                  data?.data.data.totalFbaDisposalFeeValueAmount +
                  data?.data.data.totalFbaInboundTransportationFeeValueAmount +
                  data?.data.data.totalFbaStorageFeeValueAmount +
                  data?.data.data.totalSubscriptionValueAmount +
                  data?.data.data.totalFbaRemovalFeeValueAmount +
                  data?.data.data.totalFbaLongTermStorageFeeValueAmount +
                  data?.data.data.totalReversalReimbursementAdjustmentValueAmount +
                  data?.data.data
                    .totalCompensatedClawbackAdjustmentValueAmount +
                  data?.data.data
                    .totalFreeReplacementRefundItemsAdjustmentValueAmount +
                  data?.data.data.totalWarehouseDamageAdjustmentValueAmount +
                  data?.data.data.totalFbaCustomerReturnPerUnitFeeValueAmount,
                marketplaceName
              )}
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] left-0 p-4 bg-white shadow-lg w-[300px] z-20"
          style={{ display: openAmzFee ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">FBA Komisyonu :</p>
            <p className="text-xs">
              {addCurrencySymbol(
                data?.data.data.totalFbaPerUnitFulfillmentFeeAmount,
                marketplaceName
              )}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Komisyon:</p>
            <p className="text-xs">
              {addCurrencySymbol(
                data?.data.data.totalCommissionAmount,
                marketplaceName
              )}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Shipping Charge :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalShippingChargeAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Shipping Chargeback :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalShippingChargebackAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Shipping Tax :</p>
            <p className="text-xs">{addCurrencySymbol(data?.data.data.totalShippingTaxAmount, marketplaceName)}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Promotion:</p>
            <p className="text-xs">{addCurrencySymbol(data?.data.data.totalPromotionAmount, marketplaceName)}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">FBA Disposal Fee :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalFbaDisposalFeeValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">FBA Inbound Transportation Fee :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalFbaInboundTransportationFeeValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">FBA Storage Fee :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalFbaStorageFeeValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Subscription Fee :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalSubscriptionValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">FBA Removal Fee :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalFbaRemovalFeeValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Long Term Storage Fee :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalFbaLongTermStorageFeeValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Reversal Reimbursement :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalReversalReimbursementAdjustmentValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Compensated Clawback : </p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalCompensatedClawbackAdjustmentValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Free Replacement Refund Items : </p>
            <p className="text-xs">
              {addCurrencySymbol(
                data?.data.data
                  .totalFreeReplacementRefundItemsAdjustmentValueAmount
              , marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Warehouse Damage : </p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalWarehouseDamageAdjustmentValueAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">FBA Customer Return Per Unit : </p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalFbaCustomerReturnPerUnitFeeValueAmount, marketplaceName)}
            </p>
          </div>
        </div>
      </div>

      <div className="shadow-md relative col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Flash
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#A78BFA" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openProductCost ? (
            <ArrowUp2
              onClick={() => setOpenProductCost((prev) => !prev)}
              onBlur={() => setOpenProductCost(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#6D28D9"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenProductCost((prev) => !prev)}
              onBlur={() => setOpenProductCost(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#6D28D9"
            />
          )}
          <div className="font-normal" style={{ color: "#6D28D9" }}>
            Ürün Maliyeti
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#6D28D9]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#6D28D9" }}>
              {addCurrencySymbol(
                data?.data.data.totalProductCogsAmount +
                  data?.data.data.totalShippingCogsAmount +
                  data?.data.data.totalOtherCogsAmount,
                marketplaceName
              )}
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] left-0 p-4 bg-white shadow-lg w-[300px]"
          style={{ display: openProductCost ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Üretim Maliyeti :</p>
            <p className="text-xs">{addCurrencySymbol(data?.data.data.totalProductCogsAmount.toFixed(2), marketplaceName)}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Kargo Maliyeti:</p>
            <p className="text-xs">{addCurrencySymbol(data?.data.data.totalShippingCogsAmount.toFixed(2), marketplaceName)}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Diğer Maliyetler :</p>
            <p className="text-xs">{addCurrencySymbol(data?.data.data.totalOtherCogsAmount.toFixed(2), marketplaceName)}</p>
          </div>
        </div>
      </div>
      <div className="shadow-md relative col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Shop
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#F87171" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openReturn ? (
            <ArrowUp2
              onClick={() => setOpenReturn((prev) => !prev)}
              onBlur={() => setOpenReturn(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#B45309"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenReturn((prev) => !prev)}
              onBlur={() => setOpenReturn(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#B45309"
            />
          )}
          <div className="font-normal" style={{ color: "#B91C1C" }}>
            İade Maliyeti
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#B91C1C]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#B91C1C" }}>
              {addCurrencySymbol(
                data?.data.data.totalRefundPrincipalAmount +
                  //data?.data.data.totalRefundCommissionAmount +
                  //data?.data.data.totalRefundTaxAmount +
                  data?.data.data.totalNegativeRefundCommisionAmount,
                  //data?.data.data
                  //  .totalRefundMarketplaceFacilitatorTaxPrincipalAmount,
                marketplaceName
              )}
              <p className="text-xs">
                İade: {orderData?.data.data.refunds.total}
              </p>
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] left-0 p-4 z-10 bg-white shadow-lg w-full"
          style={{ display: openReturn ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Ürün İade Ücreti :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalRefundPrincipalAmount, marketplaceName)}
            </p>
          </div>
          {/* <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Komisyon:</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalRefundCommissionAmount, marketplaceName)}
            </p>
          </div> */}
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">İade Komisyonu :</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalNegativeRefundCommisionAmount, marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">İade Vergi (+) :</p>
            <p className="text-xs">
              {addCurrencySymbol(
                data?.data.data
                  .totalRefundMarketplaceFacilitatorTaxPrincipalAmount
              , marketplaceName)}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">İade Vergi (-) :</p>
            <p className="text-xs">{addCurrencySymbol(data?.data.data.totalRefundTaxAmount, marketplaceName)}</p>
          </div>
        </div>
      </div>
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Strongbox2
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#FB923C" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#C2410C" }}>
            Reklam Maliyeti
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#C2410C]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#C2410C" }}>
              {addCurrencySymbol(
                data?.data?.data.totalAdvertisingTransactionValueAmount,
                marketplaceName
              )}
            </div>
          )}
        </div>
      </div>
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <PercentageSquare
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#38BDF8" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#0369A1" }}>
            İade Oranı
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#0369A1]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#0369A1" }}>
              %{" "}
              {(
                (orderData?.data.data.refunds.total /
                  orderData?.data.data.orders.total) *
                100
              ).toFixed(2)}
            </div>
          )}
        </div>
      </div>
      <div className="shadow-md relative col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Strongbox2
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#F472B6" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openTax ? (
            <ArrowUp2
              onClick={() => setOpenTax((prev) => !prev)}
              onBlur={() => setOpenTax(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#F472B6"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenTax((prev) => !prev)}
              onBlur={() => setOpenTax(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#F472B6"
            />
          )}
          <div className="font-normal" style={{ color: "#BE205D" }}>
            Vergi
          </div>

          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#BE205D]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#BE205D" }}>
              {addCurrencySymbol(
                data?.data.data.totalTaxAmount +
                  data?.data.data.totalMarketplaceFacilitatorTaxPrincipalAmount,
                marketplaceName
              )}
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] left-0 p-4 z-10 bg-white shadow-lg w-[300px]"
          style={{ display: openTax ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Vergi (+) :</p>
            <p className="text-xs">{addCurrencySymbol(data?.data.data.totalTaxAmount, marketplaceName)}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Vergi (-):</p>
            <p className="text-xs">
              {addCurrencySymbol(data?.data.data.totalMarketplaceFacilitatorTaxPrincipalAmount, marketplaceName)}
            </p>
          </div>
        </div>
      </div>
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <PercentageSquare
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#2DD4BF" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#0F766E" }}>
            Kar Marjı
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#0F766E]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#0F766E" }}>
              %{" "}
              {(
                ((data?.data.data.totalPrincipalAmount +
                  data?.data.data.totalFbaPerUnitFulfillmentFeeAmount +
                  data?.data.data.totalCommissionAmount +
                  data?.data.data.totalShippingChargeAmount +
                  data?.data.data.totalShippingChargebackAmount +
                  data?.data.data.totalShippingTaxAmount +
                  data?.data.data.totalPromotionAmount +
                  data?.data.data.totalFbaDisposalFeeValueAmount +
                  data?.data.data.totalFbaInboundTransportationFeeValueAmount +
                  data?.data.data.totalFbaStorageFeeValueAmount +
                  data?.data.data.totalSubscriptionValueAmount +
                  data?.data.data.totalFbaRemovalFeeValueAmount +
                  data?.data.data.totalFbaLongTermStorageFeeValueAmount +
                  data?.data.data.totalReversalReimbursementAdjustmentValueAmount +
                  data?.data.data
                    .totalCompensatedClawbackAdjustmentValueAmount +
                  data?.data.data
                    .totalFreeReplacementRefundItemsAdjustmentValueAmount +
                  data?.data.data.totalWarehouseDamageAdjustmentValueAmount +
                  data?.data.data.totalFbaCustomerReturnPerUnitFeeValueAmount +
                  data?.data.data.totalProductCogsAmount +
                  data?.data.data.totalShippingCogsAmount +
                  data?.data.data.totalOtherCogsAmount +
                  data?.data.data.totalRefundPrincipalAmount +
                  //data?.data.data.totalRefundCommissionAmount +
                  data?.data.data.totalRefundTaxAmount +
                  data?.data.data.totalNegativeRefundCommisionAmount +
                  data?.data.data
                    .totalRefundMarketplaceFacilitatorTaxPrincipalAmount +
                  data?.data.data.totalAdvertisingTransactionValueAmount +
                  data?.data.data.totalTaxAmount +
                  data?.data.data.totalMarketplaceFacilitatorTaxPrincipalAmount) /
                  data?.data.data.totalPrincipalAmount) *
                100
              ).toFixed(2)}
            </div>
          )}
        </div>
      </div>
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <PercentageSquare
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#3B82F6" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#1D4ED8" }}>
            Toplam ACOS
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#1D4ED8]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#1D4ED8" }}>
              %{" "}
              {(
                (data?.data.data.totalAdvertisingTransactionValueAmount /
                  data?.data.data.totalPrincipalAmount) *
                -100
              ).toFixed(2)}
              <p className="text-xs">(Reklam/Ciro)</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SummaryCardWrapper;
