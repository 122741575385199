import React, { useState, useEffect } from "react";
import axios from "axios";

interface LabelStats {
  [key: string]: number;
}

interface LabelData {
  [key: string]: {
    data: LabelStats;
  };
}

export const DataAnalysisTable: React.FC = () => {
  const [stats, setStats] = useState<LabelData | null>(null);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${url}/api/data/data-analysis-by-sale-rep`);
        setStats(response.data.data);
      } catch (error) {
        console.error("Error fetching sales rep stats:", error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Veriler Geliyor...</div>;
  }

  const labels = new Set<string>();
  Object.values(stats).forEach(salesRep =>
    Object.keys(salesRep.data).forEach(label => labels.add(label))
  );
  
  const salesReps = Object.keys(stats);

  // Etiketlerin sırasını tersine çevir
  const reversedLabels = Array.from(labels).reverse();

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Etiket / Satış Temsilcisi
            </th>
            {salesReps.map(salesRep => (
              <th key={salesRep} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {salesRep}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {reversedLabels.map((label) => (
            <tr key={label}>
              <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                {label}
              </td>
              {salesReps.map(salesRep => (
                <td key={`${salesRep}-${label}`} className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                  {stats[salesRep].data[label] !== undefined ? 
                    new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0, 
                      maximumFractionDigits: 1,
                    }).format(stats[salesRep].data[label]) 
                    : "0"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
