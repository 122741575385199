import React, { useState, useEffect } from "react";
import axios from "axios";

interface RevenueStatsData {
  todayTotalPaymentAmount: number;
  yesterdayTotalPaymentAmount: number;
  last30DaysTotalPaymentAmount: number;
  last90DaysTotalPaymentAmount: number;
  last360DaysTotalPaymentAmount: number;
}

const RevenueStats: React.FC = () => {
  const [stats, setStats] = useState<RevenueStatsData | null>(null);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${url}/api/payments/stats`);
        setStats(response.data.data);
      } catch (error) {
        console.error("Error fetching revenue stats:", error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Veriler geliyor ...</div>;
  }

  const {
    todayTotalPaymentAmount,
    yesterdayTotalPaymentAmount,
    last30DaysTotalPaymentAmount,
    last90DaysTotalPaymentAmount,
    last360DaysTotalPaymentAmount,
  } = stats;

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl py-4 px-4">
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-5">
        {[
          { name: "Bugün (Ciro)", value: todayTotalPaymentAmount },
          { name: "Dün (Ciro)", value: yesterdayTotalPaymentAmount },
          { name: "Son 30 Gün (Ciro)", value: last30DaysTotalPaymentAmount },
          { name: "Son 90 Gün (Ciro)", value: last90DaysTotalPaymentAmount },
          { name: "Son 1 yıl (Ciro)", value: last360DaysTotalPaymentAmount },
        ].map((stat) => (
          <div
            key={stat.name}
            className="flex flex-col justify-center items-center bg-gray-100 px-2 py-10 sm:px-2 xl:px-4"
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>
            <dd className="flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits:0,
              }).format(stat.value)}{" "}
              ₺
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default RevenueStats;
