import { useContext, useState, ChangeEvent, FormEvent } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { RegisterValues, AuthContextValue } from "../context/AuthContext"; // Import the RegisterValues interface

export default function Register() {
  const { register } = useContext(AuthContext) ?? ({} as AuthContextValue);
  const [credentials, setCredentials] = useState<RegisterValues>({
    name: "",
    email: "",
    password: "",
  });

  // Add types to the event parameter for handleChange function
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // Add types to the event parameter for handleSubmit function
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await register(credentials);
    } catch (error) {
      console.log("hata oluştu!");
    }
  };
  return (
    <>
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
        <img
            className="mx-auto h-12 w-auto"
            src="/assets/img/logo.png"
            alt="Your Company"
          />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Rexven dünyasına hoşgeldin!
          </h2>

          <div className="mt-8">
            <div className="mt-6">
              <form action="#" onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    İsim ve soyisim
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      required
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email adresi
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      placeholder="@rexven.com uzantılı mail ile kayıt olun"
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Şifre
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Beni hatırla
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link
                      to="/login"
                      className="font-medium text-red-600 hover:text-red-500"
                    >
                      Hesabınız var mı? Giriş Yapın
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-yellow-400 text-black py-2 px-4 text-sm font-medium shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Kayıt Ol
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
