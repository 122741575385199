import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { ApexOptions } from 'apexcharts';

interface SorryStatistic {
    sorryStats: {
      [sorry: string]: number;
    };
    sorryStatsPercentage: {
      [sorry: string]: string;
    };
  }

const SorryChart = () => {
  const [chartData, setChartData] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      title: {
        text: 'Kullanıcı Sayısı',
      },
    },
    yaxis: {
      title: {
        text: '',
      },
      
    },
    series: [{
      name: 'Kullanıcı Sayısı',
      data: [],
    }],
  });

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
        const { data } = await axios.get<{status: string, data: SorryStatistic}>(`${url}/api/members/sorry-stats`);
        const SorryStats = data.data.sorryStats;
        const SorryStatsPercentage = data.data.sorryStatsPercentage;
      
        setChartData(prevChartData => ({
          ...prevChartData,
          xaxis: {
            ...prevChartData.xaxis,
            categories: Object.keys(SorryStats).map((key, index) => `${key} (${SorryStatsPercentage[key]})`),
          },
          series: [{
            name: 'Kullanıcı Sayısı',
            data: Object.values(SorryStats),
          }],
        }));
      };

    fetchData();
  }, []);

  return (
    <Chart options={chartData} series={chartData.series} type="bar" height={350} />
  );
};

export default SorryChart;
