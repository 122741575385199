import { ArrowRight2 } from "iconsax-react";
import { addCurrencySymbol } from "./AmazonOrders";
import { useState } from "react";

interface AmazonProfitTableProps {
  marketplaceName: any;
  monthlyStats: any;
}

export default function AmazonProfitTable({
  marketplaceName,
  monthlyStats,
}: AmazonProfitTableProps) {
  // <------- ACOS hesaplama --------->
  //monthlyAd data = data.data.adStatsArray
  const [showAmazonFee, setShowAmazonFee] = useState(false);
  const [showCogs, setShowCogs] = useState(false);
  const [showRefunds, setShowRefunds] = useState(false);

  const acosByMonth = monthlyStats.adStatsArray.map((adData: any) => {
    // find revenue according to month data.data.orderStatsArray
    const revenueData = monthlyStats.orderStatsArray.find(
      (md: any) => md.date === adData.date
    );
    const acos = revenueData
      ? (parseFloat(adData.dailyTransactionValueAmount) /
          parseFloat(revenueData.principalAmount)) *
        100
      : 0;
    return { month: adData.month, acos: isNaN(acos) ? 0 : acos };
  });

  //  Refund hesaplama
  //<-------------- monthlyRefundDat = monthlyStats.data.data.refundStatsArray -------------->

  const refundByMonth = monthlyStats.refundStatsArray.map((refundData: any) => {
    //find revenue according to month orderStatsArray

    const revenueData = monthlyStats.orderStatsArray.find(
      (md: any) => md.date === refundData.date
    );
    const refundRate = revenueData
      ? (refundData.refundCount / revenueData.orderCount) * 100
      : 0;
    return {
      month: refundData.month,
      refund: isNaN(refundRate) ? 0 : refundRate,
    };
  });

  // Yardımcı fonksiyonlar
  const sumReducer = (key: any) => (total: any, curr: any) =>
    total + Number(curr[key]);
  const sumFixedReducer = (key: any) => (total: any, curr: any) =>
    total + parseFloat(curr[key]);

  // Toplamları hesapla
  const totalRevenue = monthlyStats.orderStatsArray
    .reduce(sumFixedReducer("principalAmount"), 0)
    .toFixed(2);
  const totalOrder = monthlyStats.orderStatsArray.reduce(
    sumReducer("orderCount"),
    0
  );
  const totalFbaFees = monthlyStats.orderStatsArray.reduce(
    sumReducer("fbaPerUnitFulfillmentFeeAmount"),
    0
  );
  const totalCommission = monthlyStats.orderStatsArray.reduce(
    sumReducer("commissionAmount"),
    0
  );
  const totalShippingChargeAmount = monthlyStats.orderStatsArray.reduce(
    sumReducer("shippingChargeAmount"),
    0
  );
  const totalShippingTaxAmount = monthlyStats.orderStatsArray.reduce(
    sumReducer("shippingTaxAmount"),
    0
  );
  const totalShippingChargebackAmount = monthlyStats.orderStatsArray.reduce(
    sumReducer("shippingChargebackAmount"),
    0
  );
  const totalPromotionAmount = monthlyStats.orderStatsArray.reduce(
    sumReducer("promotionAmount"),
    0
  );
  const totalRefunds = monthlyStats.refundStatsArray.reduce(
    sumReducer("principleAmount"),
    0
  );
  const totalproductCogs = monthlyStats.orderStatsArray.reduce(
    sumReducer("productCogs"),
    0
  );
  const totalShippingCogs = monthlyStats.orderStatsArray.reduce(
    sumReducer("shippingCogs"),
    0
  );
  const totalOtherCogs = monthlyStats.orderStatsArray.reduce(
    sumReducer("otherCogs"),
    0
  );

  const refundCount = monthlyStats.refundStatsArray.reduce(
    sumReducer("refundCount"),
    0
  );
  const totalRefundCommission = monthlyStats.refundStatsArray.reduce(
    sumReducer("commissionAmount"),
    0
  );
  const totalAds = monthlyStats.adStatsArray.reduce(
    sumReducer("dailyTransactionValueAmount"),
    0
  );

  // Fees için aynı yöntemi uygula
  const calculateTotalForFee = (key: any) =>
    monthlyStats.feeStatsArray.reduce(sumReducer(key), 0);

  const fbaDisposalFeeValueAmount = calculateTotalForFee(
    "fbaDisposalFeeValueAmount"
  );
  const fbaInboundTransportationFeeValueAmount = calculateTotalForFee(
    "fbaInboundTransportationFeeValueAmount"
  );
  const fbaStorageFeeValueAmount = calculateTotalForFee(
    "fbaStorageFeeValueAmount"
  );
  const subscriptionValueAmount = calculateTotalForFee(
    "subscriptionValueAmount"
  );
  const fbaRemovalFeeValueAmount = calculateTotalForFee(
    "fbaRemovalFeeValueAmount"
  );
  const fbaLongTermStorageFeeValueAmount = calculateTotalForFee(
    "fbaLongTermStorageFeeValueAmount"
  );
  const reversalReimbursementAdjustmentValueAmount = calculateTotalForFee(
    "reversalReimbursementAdjustmentValueAmount"
  );
  const compensatedClawbackAdjustmentValueAmount = calculateTotalForFee(
    "compensatedClawbackAdjustmentValueAmount"
  );
  const freeReplacementRefundItemsAdjustmentValueAmount = calculateTotalForFee(
    "freeReplacementRefundItemsAdjustmentValueAmount"
  );
  const warehouseDamageAdjustmentValueAmount = calculateTotalForFee(
    "warehouseDamageAdjustmentValueAmount"
  );

  //TOTAL COGS ARRAY
  let totalCogsArray: number[] = [];
  totalCogsArray = monthlyStats.orderStatsArray.map((item: any) => {
    return item.productCogs + item.shippingCogs + item.otherCogs;
  });
  //TOTAL REFUND ARRAY
  let totalRefundArray: number[] = [];
  totalRefundArray = monthlyStats.refundStatsArray.map((item: any) => {
    return Number(item.refundCommissionAmount) + Number(item.principalAmount);
  });
  //AMAZON FEES ARRAY
  let amazonFeesArray: number[] = [];
  amazonFeesArray = monthlyStats.feeStatsArray.map(
    (item: any, index: number) => {
      return (
        item.fbaDisposalFeeValueAmount +
        item.fbaInboundTransportationFeeValueAmount +
        item.fbaStorageFeeValueAmount +
        item.subscriptionValueAmount +
        item.fbaRemovalFeeValueAmount +
        item.fbaLongTermStorageFeeValueAmount +
        item.reversalReimbursementAdjustmentValueAmount +
        item.compensatedClawbackAdjustmentValueAmount +
        item.freeReplacementRefundItemsAdjustmentValueAmount +
        item.warehouseDamageAdjustmentValueAmount +
        monthlyStats.orderStatsArray[index].fbaPerUnitFulfillmentFeeAmount +
        monthlyStats.orderStatsArray[index].commissionAmount +
        monthlyStats.orderStatsArray[index].shippingChargeAmount +
        monthlyStats.orderStatsArray[index].shippingTaxAmount +
        monthlyStats.orderStatsArray[index].shippingChargebackAmount +
        monthlyStats.orderStatsArray[index].promotionAmount
      );
    }
  );
  let withdrawArray = [];
  withdrawArray = monthlyStats?.orderStatsArray.map(
    (item: any, index: number) => {
      let adFee =
        index < monthlyStats?.adStatsArray.length
          ? monthlyStats?.adStatsArray[index].dailyTransactionValueAmount
          : 0;
      return (
        item.principalAmount +
        amazonFeesArray[index] +
        totalRefundArray[index] +
        adFee
      );
    }
  );
  let netProfitArray = [];
  netProfitArray = withdrawArray.map((item: any, index: number) => {
    return item + Number(totalCogsArray[index]);
  });

  let profitMarginArray = [];
  profitMarginArray = netProfitArray.map((item: any, index: number) => {
    const principalAmount = monthlyStats.orderStatsArray[index].principalAmount;
    // Her bir siparişin ciro değerinin sıfır olup olmadığını kontrol et
    if (Number(principalAmount) === 0) {
      // Ciro sıfır ise, kar marjını %0 olarak ayarla
      return 0;
    }
    // Ciro sıfır değilse, kar marjını hesapla
    return (item / Number(principalAmount)) * 100;
  });

  let roiArray = [];
  roiArray = netProfitArray.map((item: any, index: number) => {
    // COGS değeri sıfırsa, ROI hesaplamasını atla ve 0 dön
    if (Math.abs(totalCogsArray[index]) === 0) {
      return 0;
    }
    return (item / Math.abs(totalCogsArray[index])) * 100;
  });

  // ROI satırında sıfır bölü sıfır durumunu ele almak
  const formattedROI = roiArray.map((roi: any) => {
    // NaN veya Infinity değerlerini kontrol et
    if (!isFinite(roi)) {
      return 0; // ROI hesaplanamıyorsa 0 olarak ayarla
    }
    return roi.toFixed(2);
  });

  return (
    <div>
      <div className="flex flex-col flex-shrink-0 bg-white rounded-xl my-8">
        {/* <div className="flow-root"> */}
        <div className="relative overflow-x-auto overflow-y-auto">
          <div className="inline-block min-w-full align-middle"></div>
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg text-left text-xs font-semibold  tracking-wider py-4">
                  Metrik/Tarih
                </th>
                <th className="px-5  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg text-left text-xs font-bold  tracking-wider py-4">
                  Toplam
                </th>
                {monthlyStats.orderStatsArray.map((data: any) => (
                  <th
                    key={data.date}
                    className="px-4  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg w-16 text-left text-xs font-semibold  tracking-wider py-4"
                  >
                    <p className="whitespace-nowrap overflow-hidden text-overflow-ellipsis max-w-16">{data.date}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="even:bg-[#EDEEF8]">
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  Ciro
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  {addCurrencySymbol(totalRevenue, marketplaceName.marketplace)}
                </td>
                {monthlyStats.orderStatsArray.map((data: any) => (
                  <td
                    key={data.date}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {addCurrencySymbol(
                      Number(parseFloat(data.principalAmount).toFixed(2)),
                      marketplaceName.marketplace
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  Sipariş Sayısı
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  {totalOrder}
                </td>
                {monthlyStats.orderStatsArray.map((data: any) => (
                  <td
                    key={data.date}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {data.orderCount}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  <p className="w-24">İade Sayısı</p>
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  {refundCount}
                </td>
                {monthlyStats.refundStatsArray.map((data: any) => (
                  <td
                    key={data.month}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {data.refundCount}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm  text-bold">
                  {" "}
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setShowAmazonFee((prev) => !prev)}
                  >
                    <p className="w-32">Amazon Kesintileri</p>
                    <ArrowRight2
                      className="w-5 h-5"
                      color="#1F254B"
                      variant="Bold"
                    />
                  </div>
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700">
                  {" "}
                  {addCurrencySymbol(
                    amazonFeesArray.reduce((a: any, b: any) => a + b, 0),
                    marketplaceName.marketplace
                  )}
                </td>
                {amazonFeesArray.map((data: any) => (
                  <td
                    key={data.date}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {addCurrencySymbol(data, marketplaceName.marketplace)}
                  </td>
                ))}
              </tr>
              {showAmazonFee && (
                <>
                  {" "}
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">FBA Fees</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(totalFbaFees.toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.date}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(
                            parseFloat(
                              data.fbaPerUnitFulfillmentFeeAmount
                            ).toFixed(2)
                          ),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Commission</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                      {addCurrencySymbol(
                        totalCommission.toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.date}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.commissionAmount).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Shipping Charge</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                      {addCurrencySymbol(
                        totalShippingChargeAmount.toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.date}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.shippingChargeAmount).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Shipping Chargeback</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                      {addCurrencySymbol(
                        totalShippingChargebackAmount.toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.date}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.shippingChargebackAmount).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Shipping Tax</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                      {addCurrencySymbol(
                        totalShippingTaxAmount.toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.date}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.shippingTaxAmount).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Promotion</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                      {addCurrencySymbol(
                        totalPromotionAmount.toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.date}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.promotionAmount).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Disposal Fee</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(fbaDisposalFeeValueAmount.toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(data.fbaDisposalFeeValueAmount.toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Inbound Transportation</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(
                          fbaInboundTransportationFeeValueAmount.toFixed(2)
                        ),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(
                            data.fbaInboundTransportationFeeValueAmount.toFixed(
                              2
                            )
                          ),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Storage Fee</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(fbaStorageFeeValueAmount.toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(data.fbaStorageFeeValueAmount.toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Subscription</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(subscriptionValueAmount.toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(data.subscriptionValueAmount.toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Removal Fee</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(fbaRemovalFeeValueAmount.toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(data.fbaRemovalFeeValueAmount.toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Long Term Storage</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(fbaLongTermStorageFeeValueAmount.toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(
                            data.fbaLongTermStorageFeeValueAmount.toFixed(2)
                          ),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Reversal Reimbursement</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(
                          reversalReimbursementAdjustmentValueAmount.toFixed(2)
                        ),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(
                            data.reversalReimbursementAdjustmentValueAmount.toFixed(
                              2
                            )
                          ),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Compensated Clawback</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(
                          compensatedClawbackAdjustmentValueAmount.toFixed(2)
                        ),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(
                            data.compensatedClawbackAdjustmentValueAmount.toFixed(
                              2
                            )
                          ),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Free Replacement Refund</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(
                          freeReplacementRefundItemsAdjustmentValueAmount.toFixed(
                            2
                          )
                        ),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(
                            data.freeReplacementRefundItemsAdjustmentValueAmount.toFixed(
                              2
                            )
                          ),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Warehouse Damage</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(warehouseDamageAdjustmentValueAmount.toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.feeStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(
                            data.warehouseDamageAdjustmentValueAmount.toFixed(2)
                          ),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                </>
              )}

              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm  text-bold">
                  {" "}
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setShowRefunds(!showRefunds)}
                  >
                    <p>İade</p>
                    <ArrowRight2
                      className="w-5 h-5"
                      color="#1F254B"
                      variant="Bold"
                    />
                  </div>
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  {addCurrencySymbol(
                    totalRefundArray.reduce((a: any, b: any) => a + b, 0),
                    marketplaceName.marketplace
                  )}
                </td>
                {totalRefundArray.map((item: any) => {
                  return (
                    <td
                      key={item}
                      className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                    >
                      {addCurrencySymbol(
                        Number(parseFloat(item).toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                  );
                })}
              </tr>
              {showRefunds && (
                <>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Refunds</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        monthlyStats.refundStatsArray
                          .reduce((a: any, b: any) => a + b.principalAmount, 0)
                          .toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.refundStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.principalAmount).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>

                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Refund Commission</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        monthlyStats.refundStatsArray
                          .reduce(
                            (a: any, b: any) => a + b.refundCommissionAmount,
                            0
                          )
                          .toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.refundStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(
                            parseFloat(data.refundCommissionAmount).toFixed(2)
                          ),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                </>
              )}

              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  Reklam
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  {addCurrencySymbol(
                    totalAds.toFixed(2),
                    marketplaceName.marketplace
                  )}
                </td>
                {monthlyStats.adStatsArray.map((data: any) => (
                  <td
                    key={data.month}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {addCurrencySymbol(
                      Number(
                        parseFloat(data.dailyTransactionValueAmount).toFixed(2)
                      ),
                      marketplaceName.marketplace
                    )}
                  </td>
                ))}
              </tr>
              {/* BEKLENEN ÖDEME */}
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  Beklenen Ödeme
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  {addCurrencySymbol(
                    withdrawArray.reduce((a: any, b: any) => a + b, 0),
                    marketplaceName.marketplace
                  )}
                </td>
                {withdrawArray.map((data: any) => (
                  <td
                    key={data}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {addCurrencySymbol(
                      Number(parseFloat(data).toFixed(2)),
                      marketplaceName.marketplace
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm  text-bold">
                  {" "}
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setShowCogs(!showCogs)}
                  >
                    <p>Ürün Maliyeti</p>
                    <ArrowRight2
                      className="w-5 h-5"
                      color="#1F254B"
                      variant="Bold"
                    />
                  </div>
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700">
                  {addCurrencySymbol(
                    totalCogsArray.reduce((a: any, b: any) => a + b, 0),
                    marketplaceName.marketplace
                  )}
                </td>
                {totalCogsArray.map((item: any) => {
                  return (
                    <td
                      key={item}
                      className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                    >
                      {addCurrencySymbol(
                        Number(parseFloat(item).toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                  );
                })}
              </tr>
              {showCogs && (
                <>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Üretim</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        Number(totalproductCogs.toFixed(2)),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.date}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.productCogs).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Kargo</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs text-red-700  text-bold">
                      {addCurrencySymbol(
                        totalShippingCogs.toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.month}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.shippingCogs).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-xs">
                      <p className="ml-5">Diğer</p>
                    </td>
                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                      {addCurrencySymbol(
                        totalOtherCogs.toFixed(2),
                        marketplaceName.marketplace
                      )}
                    </td>
                    {monthlyStats.orderStatsArray.map((data: any) => (
                      <td
                        key={data.date}
                        className="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                      >
                        {addCurrencySymbol(
                          Number(parseFloat(data.otherCogs).toFixed(2)),
                          marketplaceName.marketplace
                        )}
                      </td>
                    ))}
                  </tr>
                </>
              )}
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  İade Oranı
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  %{((refundCount / totalOrder) * 100).toFixed(2)}
                </td>
                {refundByMonth.map((item: any) => (
                  <td
                    key={item.month}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {item.refund.toFixed(2)}%
                  </td>
                ))}
              </tr>
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  ACOS
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  %{((totalAds / Number(totalRevenue)) * -100).toFixed(2)}
                </td>
                {acosByMonth.map((item: any) => (
                  <td
                    key={item.month}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {item.acos.toFixed(2) * -1}%
                  </td>
                ))}
              </tr>
              {/* NET KAR */}
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  Net Kar
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700  text-bold">
                  {addCurrencySymbol(
                    netProfitArray.reduce(
                      (a: any, b: any) => Number(a) + Number(b),
                      0
                    ),
                    marketplaceName.marketplace
                  )}
                </td>
                {netProfitArray.map((item: any) => (
                  <td
                    key={item}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {addCurrencySymbol(
                      item.toFixed(2),
                      marketplaceName.marketplace
                    )}
                  </td>
                ))}
              </tr>
              {/* KAR MARJ */}
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  Kar Marjı
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700 text-bold">
                  {
                    Number(totalRevenue) !== 0 // Toplam gelirin sıfır olup olmadığını kontrol et
                      ? (
                          (netProfitArray.reduce(
                            (a: any, b: any) => Number(a) + Number(b),
                            0
                          ) /
                            Number(totalRevenue)) *
                          100
                        ).toFixed(2) + "%"
                      : "0.00%" // Toplam gelir 0 ise, %0.00 göster
                  }
                </td>
                {profitMarginArray.map((marginValue: any, index: any) => (
                  <td
                    key={index} // key olarak index kullanılabilir
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {marginValue.toFixed(2)}%
                  </td>
                ))}
              </tr>

              {/* ROI  */}
              <tr>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                  ROI
                </td>
                <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm text-red-700 text-bold">
                  {
                    totalCogsArray.reduce((a, b) => a + b, 0) !== 0 // Paydanın sıfır olup olmadığını kontrol et
                      ? (
                          (netProfitArray.reduce(
                            (a: any, b: any) => Number(a) + Number(b),
                            0
                          ) /
                            totalCogsArray.reduce((a, b) => a + b, 0)) *
                          -100
                        ).toFixed(2) + "%"
                      : "0.00%" // Payda 0 ise, %0.00 göster
                  }
                </td>
                {formattedROI.map((roiValue: any) => (
                  <td
                    key={roiValue}
                    className="px-5 py-2 border-b border-gray-200 bg-white text-sm"
                  >
                    {roiValue}%
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
