import { useState, useEffect } from "react";
import axios from "axios";
import {formatMonthYearTR} from "../../helpers/date";
import { TrashIcon, PencilIcon } from "@heroicons/react/20/solid";

interface Finance {
  _id: string;
  amount: number;
  date: string;
  category: string;
}

export default function AllFinanceRecords(): JSX.Element {
  const [finances, setFinances] = useState<Finance[]>([]);
  const [newAmount, setNewAmount] = useState<number>(0);
  const [newDate, setNewDate] = useState<string>("");
  const [newCategory, setNewCategory] = useState<string>("General");
  const [editingFinance, setEditingFinance] = useState<Finance | null>(null);
  const [selectedYear, setSelectedYear] = useState(2023);

  const url = process.env.REACT_APP_API_URL;

  const handleAddFinance = async (): Promise<void> => {
    if (editingFinance) {
      handleUpdateFinance();
      return;
    }

    if (newAmount && newDate) {
      try {
        const response = await axios.post<{ data: Finance }>(`${url}/api/finance/create`, {
          amount: newAmount,
          date: newDate,
          category: newCategory,
        });

        const createdFinance = response.data.data;
        setFinances([...finances, createdFinance]);
        setNewAmount(0);
        setNewDate("");
      } catch (error) {
        console.error("Error creating finance:", error);
      }
    }
  };

  const handleDeleteFinance = async (id: string): Promise<void> => {
    await axios.delete(`${url}/api/finance/delete/${id}`);
    setFinances(finances.filter((finance) => finance._id !== id));
  };

  const handleEditStart = (id: string): void => {
    const financeToEdit = finances.find((finance) => finance._id === id);
    if (financeToEdit) {
      setEditingFinance(financeToEdit);
      setNewAmount(financeToEdit.amount);
      setNewDate(financeToEdit.date);
      setNewCategory(financeToEdit.category);
    }
  };

  const handleUpdateFinance = async (): Promise<void> => {
    if (newAmount && newDate && editingFinance) {
      try {
        await axios.patch(`${url}/api/finance/update`, {
          _id: editingFinance._id,
          amount: newAmount,
          date: newDate,
          category: newCategory,
        });
        setFinances(
          finances.map((finance) =>
            finance._id === editingFinance._id
              ? { ...finance, amount: newAmount, date: newDate, category: newCategory }
              : finance
          )
        );
      } catch (error) {
        console.error("Error updating finance:", error);
      }
      setEditingFinance(null);
      setNewAmount(0);
      setNewDate("");
      setNewCategory("General");
    }
  };

  useEffect(() => {
    const fetchFinances = async (): Promise<void> => {
      const response = await axios.get<{ data: Finance[] }>(`${url}/api/finance/get-all?year=${selectedYear}`);
      setFinances(response.data.data);
    };

    fetchFinances();
  }, [selectedYear]);

  const handleYearChange = (event: any) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };
  return (
    <div className="bg-white drop-shadow-2xl py-12 max-w-6xl mx-auto mt-10 rounded-xl ">
      {/* Input Fields */}
      <div className="flex space-x-4 items-center p-4">
        <input
          type="number"
          value={newAmount}
          onChange={(e) => setNewAmount(Number(e.target.value))}
          className="border border-red-600 p-2 rounded w-1/4"
          placeholder="Tutar..."
        />
        <input
          type="month"
          value={newDate}
          onChange={(e) => setNewDate(e.target.value)}
          className="border border-red-600 p-2 rounded w-1/4"
        />
        <select
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          className="border border-red-600 p-2 rounded w-1/4"
        >
          <option value="Genel">Genel</option>
          <option value="Hizmet Sağlayıcı Geliri">Hizmet Sağlayıcı Geliri</option>
          <option value="Affiliate Geliri">Affiliate Geliri</option>
          <option value="Rexven Elite">Rexven Elite</option>
          <option value="Rexven Exclusive">Rexven Exclusive</option>
          <option value="E-ticaret Geliri">E-ticaret Geliri</option>
          <option value="API Geliri">API Geliri</option>
          <option value="Diğer Gelirler">Diğer Gelirler</option>
          <option value="Maaş - E-ticaret">Maaş - E-Ticaret</option>
          <option value="Maaş - Pazarlama">Maaş - Pazarlama</option>
          <option value="Maaş - Satış">Maaş - Satış</option>
          <option value="Maaş - Destek">Maaş - Destek</option>
          <option value="Maaş - Yazılım">Maaş - Yazılım</option>
          <option value="Maaş - IK ve Operasyon">Maaş - IK ve Operasyon</option>
          <option value="Maaş - Finans ve Hukuk">Maaş - Finans ve Hukuk</option>
          <option value="SGK">SGK</option>
          <option value="Prim">Prim</option>
          <option value="Kira - Aidat - Fatura">Kira - Aidat - Fatura</option>
          <option value="Sunucu - Server">Sunucu - Server</option>
          <option value="Yemek - Ödül">Yemek - Ödül</option>
          <option value="Facebook Reklamları">Facebook Reklamları</option>
          <option value="Google Reklamları">Google Reklamları</option>
          <option value="Twitter Reklamları">Twitter Reklamları</option>
          <option value="Muhasebe">Muhasebe</option>
          <option value="Vergi">Vergi</option>
          <option value="Ödeme Altyapısı">Ödeme Altyapısı</option>
          <option value="Diğer Giderler">Diğer Giderler</option>
        </select>
        <button
          onClick={handleAddFinance}
          className="bg-yellow-400 py-2 px-4 rounded text-white"
        >
          {editingFinance ? "Düzenle" : "Ekle"}
        </button>
      </div>

      {/* Data Display Table */}
      <table className="min-w-full divide-y divide-gray-700">
        <thead>
          <tr>
            <th className="text-left py-2 px-4">Tarih</th>
            <th className="text-left py-2 px-4">Kategori</th>
            <th className="text-left py-2 px-4">Tutar</th>
          </tr>
        </thead>
        <tbody>
        <div className="px-4 py-2 sm:px-6 lg:px-8">
        <select value={selectedYear} onChange={handleYearChange}>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
      </div>
          {finances.map((finance) => (
            <tr key={finance._id} className="px-12 hover:bg-gray-100">
              <td className="text-left py-2 px-4">{formatMonthYearTR(finance.date)}</td>
              <td className="text-left py-2 px-4">{finance.category}</td>
              <td className="text-left py-2 px-4">{finance.amount} ₺</td>
              <td className="text-center py-2 px-4 flex space-x-2">
                <button onClick={() => handleEditStart(finance._id)}>
                  <PencilIcon className="h-6 w-6 text-gray-600 hover:text-red-600" />
                </button>
                <button onClick={() => handleDeleteFinance(finance._id)}>
                  <TrashIcon className="h-6 w-6 text-gray-600 hover:text-red-600" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
