import React, { useState, useEffect } from "react";
import axios from "axios";

interface DataStats {
  [key: string]: string; // key-value yapısı olarak tanımladık.
}

interface GrowthStatsData {
  growthRates: DataStats;
  averageGrowthRate: string;
}

const GrowthStats: React.FC = () => {
  const [stats, setStats] = useState<GrowthStatsData | null>(null);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${url}/api/metrics/churn-rate`);
        setStats({
          growthRates: response.data.growthRates,
          averageGrowthRate: response.data.averageGrowthRate,
        });
      } catch (error) {
        console.error("Error fetching churn rate stats:", error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Veriler geliyor ...</div>;
  }

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl py-4 px-4">
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-3 lg:grid-cols-7">
        {[
          { name: "Ocak", value: stats.growthRates.january },
          { name: "Şubat", value: stats.growthRates.february },
          { name: "Mart", value: stats.growthRates.march },
          { name: "Nisan", value: stats.growthRates.april },
          { name: "Mayıs", value: stats.growthRates.may },
          { name: "Haziran", value: stats.growthRates.june },
          { name: "Temmuz", value: stats.growthRates.july },
          { name: "Ağustos", value: stats.growthRates.august },
          { name: "Eylül", value: stats.growthRates.september },
          { name: "Ekim", value: stats.growthRates.october },
          { name: "Kasım", value: stats.growthRates.november },
          { name: "Aralık", value: stats.growthRates.december },
          { name: "Ocak", value: stats.growthRates.january2024 },
          { name: "Şubat", value: stats.growthRates.february2024 },
          { name: "Mart", value: stats.growthRates.march2024 },
          { name: "Nisan", value: stats.growthRates.april2024 },
          { name: "Mayıs", value: stats.growthRates.may2024 },
          { name: "Ortalama", value: stats.averageGrowthRate },
          
        ].map((stat) => (
          <div
            key={stat.name}
            className="flex flex-col justify-center items-center bg-gray-100 px-4 py-4 sm:px-6 xl:px-4"
          >
            <dt className="text-sm text-center font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>
            <dd className="text-2xl font-medium leading-10 tracking-tight text-gray-900">
              {`${stat.value}%`}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default GrowthStats;
