import { useState, useEffect } from "react";
import Avatar from "react-avatar";
import axios from "axios";
import { useAuth, AuthContextValue } from "../context/AuthContext";


export default function Profile() {

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { updatePassword, user } = useAuth() as AuthContextValue;

  const url = process.env.REACT_APP_API_URL;

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (newPassword === confirmPassword) {
      if (user) {
        const userId = user.id;
        updatePassword({ userId, oldPassword, newPassword });
        console.log("şifre başarı ile değişti");
      } else {
        // Show error message, user is not logged in or user object is not available
      }
    } else {
      console.log("şifreler uyuşmuyor");
    }
  };


  return (
    <div className="px-10 py-20">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row">
          {/* Sol taraf: Profil resmi, isim ve email */}
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
            <div className="text-center lg:text-left">
              <div className="mx-auto h-40 w-36 relative">
                <Avatar
                  size="150"
                  round={true}
                  src="/assets/img/rexven2.png"
                />
                <div className="absolute mb-5 py-6 md:py-4 left-0 right-0">
                  <h2 className="text-lg text-center font-semibold text-gray-800">
                    {" "}
                    {user?.name}{" "}
                  </h2>
                  <p className="text-sm text-center text-gray-600">
                    {user?.email}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Sağ taraf: Şifre değiştirme formu */}
          <div className="w-full mt-8 lg:mt-0 lg:w-1/2">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="old-password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Eski Şifre
                </label>
                <input
                  type="password"
                  id="old-password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="new-password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Yeni Şifre
                </label>
                <input
                  type="password"
                  id="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Şifre Tekrarı
                </label>
                <input
                  type="password"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                />
              </div>

              <button
                type="submit"
                className="w-full py-2 px-4 bg-yellow-400 text-black font-semibold rounded-md hover:bg-red-500"
              >
                Şifreyi Güncelle
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
