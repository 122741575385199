import React, { useState, useEffect } from "react";
import axios from "axios";

const MrrForecast: React.FC = () => {
  const [forecast, setForecast] = useState<{
    activeMemberCount: number;
    prediction2024: number;
    prediction2025: number;
    prediction2026: number;
    prediction2027: number;
    prediction2028: number;
    prediction2029: number;
    prediction2030: number;
  } | null>(null);

  const [metrics, setMetrics] = useState<{
    AverageMonthly: number;
  } | null>(null);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchForecast = async () => {
      try {
        const response = await axios.get(
          `${url}/api/forecast/get-members-forecast`
        );
        const {
          activeMemberCount,
          prediction2024,
          prediction2025,
          prediction2026,
          prediction2027,
          prediction2028,
          prediction2029,
          prediction2030,
        } = response.data;

        setForecast({
          activeMemberCount,
          prediction2024,
          prediction2025,
          prediction2026,
          prediction2027,
          prediction2028,
          prediction2029,
          prediction2030,
        });
      } catch (error) {
        console.error("Error fetching member forecast:", error);
      }
    };

    const fetchMetrics = async () => {
      try {
        const response = await axios.get(`${url}/api/metrics/mrr-arr`);
        const { AverageMonthly } = response.data.data;

        setMetrics({
          AverageMonthly,
        });
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    };

    fetchMetrics();
    fetchForecast();
  }, []);

  if (!forecast) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl py-4 px-4">
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
        {!metrics
          ? "AverageMonthly değeri yükleniyor..."
          : [
              {
                name: "Bugün",
                value: forecast.activeMemberCount,
              },
              
              {
                name: "2024",
                value: forecast.prediction2024,
              },
              {
                name: "2025",
                value: forecast.prediction2025,
              },
              {
                name: "2026",
                value: forecast.prediction2026,
              },
              {
                name: "2027",
                value: forecast.prediction2027,
              },
              {
                name: "2028",
                value: forecast.prediction2028,
              },
              {
                name: "2029",
                value: forecast.prediction2029,
              },
              {
                name: "2030",
                value: forecast.prediction2030,
              },
            ].map((stat) => {
              const adjustedValue = stat.value * metrics.AverageMonthly;
              return (
                <div
                  key={stat.name}
                  className="flex flex-col justify-center items-center bg-gray-100 px-4 py-10 sm:px-6 xl:px-8"
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">
                    {stat.name}
                  </dt>
                  <dd className="text-xl py-1 font-medium leading-10 tracking-tight text-gray-900">
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(adjustedValue)}
                    ₺ (MRR)
                  </dd>
                  <dd className="text-xl font-medium leading-10 tracking-tight text-gray-900">
                    ${new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(adjustedValue / 27)} (MRR)
                  </dd>
                  <dd className="text-xl mt-4 font-medium leading-10 tracking-tight text-gray-900">
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(adjustedValue * 12)}
                    ₺ (ARR)
                  </dd>
                  <dd className="text-xl font-medium py-1 leading-10 tracking-tight text-gray-900">
                    ${new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(adjustedValue * 12 /27)} (ARR)
                  </dd>
                </div>
              );
            })}
      </dl>
    </div>
  );
};

export default MrrForecast;
