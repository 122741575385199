import React, { useEffect, useState } from "react";
import AmazonProfitTable from "../components/amazon/ProfitTable";
import SkuStatsTable from "../components/amazon/SkuStatsTable";
import SummaryCardWrapper from "../components/amazon/SummaryCardWrapper";
import httpClient from "../utils/httpClient";
import { useQuery } from "@tanstack/react-query";
import CustomDatePicker from "../components/amazon/CustomDatePicker";
import OrderStatsTable from "../components/amazon/OrderStatsTable";
import RefundStatsTable from "../components/amazon/RefundStatsTable";
import AdStatsTable from "../components/amazon/AdStatsTable";
import FeeStatsTable from "../components/amazon/FeeStatsTable";
import CustomDatePickerDaily from "../components/amazon/CustomDatePickerDaily";
import Select from "react-select";
import { InputActionMeta } from "react-select";
import NestedDropdownMenu from "../components/amazon/NestedDropDown";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface DaysType {
  today: string;
  sevenDaysAgo: string;
  thirtyDaysAgo: string;
  ninetyDaysAgo: string;
  lastThreeMonth: string;
  lastYear: string;
  lastTwoYear: string;
}
export default function AmazonProfitDashboard() {
  const [productOptions, setProductOptions] = useState<any>([]);
  const [productOptionsBase, setProductOptionsBase] = useState<any>([]);
  const [selectedSku, setSelectedSku] = useState({ label: "Bütün Ürünler", value: "" });
  const [inputValue, setInputValue] = useState("");
  const [marketplaceName, setMarketplaceName] = useState<any>();

  const [datePickerStartDate, setDatePickerStartDate] = useState(new Date());
  const [datePickerEndDate, setDatePickerEndDate] = useState<Date | null>(null);
  const [datePickerStartDateDaily, setDatePickerStartDateDaily] = useState(
    new Date()
  );
  const [datePickerEndDateDaily, setDatePickerEndDateDaily] =
    useState<Date | null>(null);
  const [datDiff, setDayDiff] = useState(90);
  const [tableView, setTableView] = useState("sku");
  const [selected, setSelected] = useState({
    title: "Son 30 Gün",
    current: false,
  });
  const [selectedDaily, setSelectedDaily] = useState({
    title: "Aylık",
    current: false,
  });
  const [isDaily, setIsDaily] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDatePickerDaily, setShowDatePickerDaily] = useState(false);
  const [days, setDays] = useState<DaysType>({
    today: "",
    sevenDaysAgo: "",
    thirtyDaysAgo: "",
    ninetyDaysAgo: "",
    lastThreeMonth: "",
    lastYear: "",
    lastTwoYear: "",
  });
  const [selectedDate, setSelectedDate] = useState({
    startDate: days.thirtyDaysAgo,
    endDate: days.today,
  });
  const [selectedDateDaily, setSelectedDateDaily] = useState({
    startDate: days.thirtyDaysAgo,
    endDate: days.today,
  });
  
  const handleOptionSelect = (option: any) => {
    switch (option.title) {
      case "Tarih Aralığı Seç":
        setShowDatePicker(true);
        setSelected(option);
        // setSelectedDate({
        //   startDate: datePickerStartDate.toISOString(),
        //   endDate: datePickerStartDate.toISOString() || days.today,
        // });
        break;
      case "Bugün":
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.today,
          endDate: "",
        });
        break;
      case "Son 7 Gün":
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.sevenDaysAgo,
          endDate: days.today,
        });
        break;
      case "Son 30 Gün":
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.thirtyDaysAgo,
          endDate: days.today,
        });
        break;
      case "Son 90 Gün":
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.ninetyDaysAgo,
          endDate: days.today,
        });
        break;
      case "Son 180 Gün":
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.lastThreeMonth,
          endDate: days.today,
        });
        break;
      case "Son 1 Yıl":
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.lastYear,
          endDate: days.today,
        });
        break;
      case "Son 2 Yıl":
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.lastTwoYear,
          endDate: days.today,
        });
        break;
      
    }
  };
  const handleOptionSelectDaily = (option: any) => {
    switch (option.title) {
      case "Günlük":
        setShowDatePickerDaily(true);
        setSelectedDaily(option);
        // setSelectedDateDaily({
        //   startDate: datePickerStartDate.toISOString(),
        //   endDate: datePickerStartDate.toISOString() || days.today,
        // });
        break;
      case "Aylık":
        setIsDaily(false);
        setShowDatePickerDaily(false);
        setSelectedDaily(option);
        // setSelectedDateDaily({
        //   startDate: "",
        //   endDate: "",
        // });
        break;
    }
  };

  const {
    isLoading,
    error,
    data: totalStats,
  } = useQuery<any, Error>({
    queryKey: ["totalStats", marketplaceName, selectedDate, selectedSku],
    queryFn: () =>
      httpClient.get(
        `/api/amazon/total-stats?marketplace=${
          marketplaceName.marketplace || ""
        }&postedAfter=${selectedDate.startDate}&postedBefore=${
          selectedDate.endDate
        }&sellerSku=${selectedSku.value}&amazonStoreId=${
          marketplaceName?.storeId || ""
        }`
      ),
    staleTime: Infinity,
  });

  //MONTHLY STATS REQ
  const { data: monthlyStats, isLoading: monthlyStatsLoading } = useQuery<
    any,
    Error
  >({
    queryKey: [
      "monthlyStats",
      marketplaceName,
      selectedDate,
      selectedSku.value,
    ],
    queryFn: () =>
      httpClient.get(
        `/api/amazon/monthly-stats?marketplace=${marketplaceName.marketplace}&sellerSku=${selectedSku.value}&amazonStoreId=${marketplaceName.storeId}`
      ),

    staleTime: Infinity,
  });

  //DAILY STATS
  const { data: dailyStats, isLoading: dailyStatsLoading } = useQuery<
    any,
    Error
  >({
    queryKey: ["dailyStats", marketplaceName, selectedDateDaily, selectedSku],
    queryFn: () =>
      httpClient.get(
        `/api/amazon/daily-stats?marketplace=${marketplaceName.marketplace}&postedAfter=${selectedDateDaily.startDate}&postedBefore=${selectedDateDaily.endDate}&sellerSku=${selectedSku.value}&amazonStoreId=${marketplaceName.storeId}`
      ),

    staleTime: Infinity,
  });

  //ORDER STATS REQ
  const { data: orderStats } = useQuery<any, Error>({
    queryKey: ["orderStats", marketplaceName, selectedDate, selectedSku],
    queryFn: () =>
      httpClient.get(
        `/api/amazon/order-stats?marketplace=${marketplaceName.marketplace}&postedAfter=${selectedDate.startDate}&postedBefore=${selectedDate.endDate}&sellerSku=${selectedSku.value}&amazonStoreId=${marketplaceName.storeId}`
      ),
    staleTime: Infinity,
  });
  const getAllSkus = async () => {
    const amazonStoreId = marketplaceName?.storeId; 
    if (!amazonStoreId) {
      console.error("Amazon Store ID is required");
      return;
    }
    
    const res = await httpClient.get(`/api/amazon/all-skus`, {
      params: {
        amazonStoreId
      },
    });
    const temp = res.data.data.map((item: any) => {
      // Ürün adını istenilen formata göre kısalt
      const productNameShortened =
          item.productName.length > 60
          ? `${item.productName.substring(0, 57)}...${item.productName.substring(
              item.productName.length - 30
            )}`
          : item.productName;
      
      return {
        // productName'i kısaltılmış versiyonu ile birleştir
        label: `${productNameShortened} ASIN: ${item.asin}`,
        value: item.sellerSku,
      };
  });
    setProductOptions([{ label: "Bütün Ürünler", value: "" }, ...temp]);
    setProductOptionsBase([{ label: "Bütün Ürünler", value: "" }, ...temp]);
  };

  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(today.getDate() - 90);

    const lastThreeMonth = new Date();
    lastThreeMonth.setDate(today.getDate() - 180);
    const lastYear = new Date();
    lastYear.setDate(today.getDate() - 365);
    const lastTwoYear = new Date();
    lastTwoYear.setDate(today.getDate() - 730);

    setDays({
      today: today.toISOString(),
      sevenDaysAgo: sevenDaysAgo.toISOString(),
      thirtyDaysAgo: thirtyDaysAgo.toISOString(),
      ninetyDaysAgo: ninetyDaysAgo.toISOString(),
      lastThreeMonth: lastThreeMonth.toISOString(),
      lastYear: lastYear.toISOString(),
      lastTwoYear: lastTwoYear.toISOString(),
    });
    setSelectedDate({
      startDate: thirtyDaysAgo.toISOString(),
      endDate: today.toISOString(),
    });
    setSelectedDateDaily({
      startDate: thirtyDaysAgo.toISOString(),
      endDate: today.toISOString(),
    });
    getAllSkus();
  }, [marketplaceName]);

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Profit Dashboard
          </h1>
        </div>
        <div className="w-full flex justify-between py-4">
          {/* <select
            value={marketplaceName}
            onChange={(e) => setMarketplaceName(e.target.value)}
            className="border-[#6F7AC3] rounded-md text-slate-600 "
          >
            <option value="Amazon.com">Amazon.com</option>
            <option value="Amazon.ca">Amazon.ca</option>
            <option value="Amazon.com.mx">Amazon.com.mx</option>
            <option value="Non-Amazon">Non-Amazon</option>
          </select> */}
          <NestedDropdownMenu
            setMarketplaceName={setMarketplaceName}
            marketplaceName={marketplaceName}
          />
          <CustomDatePicker
            selected={selected}
            setSelected={setSelected}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
            setSelectedDate={setSelectedDate}
            handleOptionSelect={handleOptionSelect}
            startDate={datePickerStartDate}
            setStartDate={setDatePickerStartDate}
            endDate={datePickerEndDate}
            setEndDate={setDatePickerEndDate}
            dayDiff={datDiff}
          />
        </div>
        <SummaryCardWrapper
          // refund={refund}
          marketplaceName={marketplaceName?.marketplace}
          data={totalStats}
          orderData={orderStats}
          isLoading={isLoading}
        />

        <div className="flex w-full justify-between space-x-4">
          <Select
            defaultValue={selectedSku}
            name="sku"
            options={productOptions}
            className="basic-single text-xs relative mt-1 min-w-[240px]"
            // classNamePrefix="select"
            onChange={(option: any) => {
              setSelectedSku(option);
              setInputValue(option);
            }}
            inputValue={inputValue}
            onInputChange={function (
              newValue: string,
              actionMeta: InputActionMeta
            ): void {
              setInputValue(newValue);
              if (newValue === "") {
                setProductOptions(productOptionsBase);
              }
              const temp = productOptionsBase.filter((product: any) =>
                product.value.toLowerCase().includes(newValue.toLowerCase())
              );
              setProductOptions(temp);
            }}
            blurInputOnSelect
            onMenuClose={function (): void {
              setProductOptions(productOptionsBase);
            }}
          />
          <CustomDatePickerDaily
            selected={selectedDaily}
            setSelected={setSelectedDaily}
            showDatePicker={showDatePickerDaily}
            setShowDatePicker={setShowDatePickerDaily}
            setSelectedDate={setSelectedDateDaily}
            handleOptionSelect={handleOptionSelectDaily}
            startDate={datePickerStartDateDaily}
            setStartDate={setDatePickerStartDateDaily}
            endDate={datePickerEndDateDaily}
            setEndDate={setDatePickerEndDateDaily}
            dayDiff={datDiff}
            setIsDaily={setIsDaily}
          />
        </div>
        <div>
          {monthlyStatsLoading || dailyStatsLoading ? (
            <p></p>
          ) : monthlyStats?.data?.status === "success" ? (
            <AmazonProfitTable
              marketplaceName={marketplaceName}
              monthlyStats={
                isDaily ? dailyStats?.data?.data : monthlyStats?.data?.data
              }
            />
          ) : (
            <p>Hata</p>
          )}
        </div>
        <div className="border-b border-gray-200 py-4">
          <h1 className="text-2xl text-center font-bold tracking-tight text-gray-900">
            SKU Profit/Loss
          </h1>
        </div>
        <div>
          <div className="flex items-center justify-start space-x-2 my-2">
            <button
              className={classNames(
                tableView === "sku"
                  ? "bg-black text-yellow-400"
                  : "bg-yellow-400 text-black",
                "rounded-md   px-4 py-2"
              )}
              onClick={() => setTableView("sku")}
            >
              Ürünler
            </button>
            <button
              className={classNames(
                tableView === "orders"
                  ? "bg-black text-yellow-400"
                  : "bg-yellow-400 text-black",
                "rounded-md   px-4 py-2"
              )}
              onClick={() => setTableView("orders")}
            >
              Siparişler
            </button>
            <button
              className={classNames(
                tableView === "refunds"
                  ? "bg-black text-yellow-400"
                  : "bg-yellow-400 text-black",
                "rounded-md   px-4 py-2"
              )}
              onClick={() => setTableView("refunds")}
            >
              İadeler
            </button>
            <button
              className={classNames(
                tableView === "ads"
                  ? "bg-black text-yellow-400"
                  : "bg-yellow-400 text-black",
                "rounded-md   px-4 py-2"
              )}
              onClick={() => setTableView("ads")}
            >
              Reklamlar
            </button>
            <button
              className={classNames(
                tableView === "fees"
                  ? "bg-black text-yellow-400"
                  : "bg-yellow-400 text-black",
                "rounded-md   px-4 py-2"
              )}
              onClick={() => setTableView("fees")}
            >
              Kesintiler
            </button>
          </div>
          {tableView === "sku" && (
            <SkuStatsTable
              marketplaceName={marketplaceName}
              selectedDate={selectedDate}
              // setProductOptions={setProductOptions}
              // setProductOptionsBase={setProductOptionsBase}
              selectedSku={selectedSku.value}
            />
          )}
          {tableView === "orders" && (
            <OrderStatsTable data={orderStats?.data?.data.orders.events} />
          )}
          {tableView === "refunds" && (
            <RefundStatsTable data={orderStats?.data?.data.refunds.events} />
          )}
          {tableView === "ads" && (
            <AdStatsTable data={orderStats?.data?.data.ads.events} />
          )}
          {tableView === "fees" && (
            <FeeStatsTable data={orderStats?.data?.data.fees.events} />
          )}
        </div>
      </main>
    </div>
  );
}
