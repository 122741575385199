import React, { useState, useEffect } from "react";
import axios from "axios";

interface UsageStat {
    totalTimeSpent: string;
    averageTimePerUser: number;
    activeTotalTimeSpent: string;
    activeAverageTimePerUser: number;
  }

const UsageStats: React.FC = () => {
  const [usagestats, setUsageStats] = useState<UsageStat | null>(null);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
        try {
          const usageStats = await axios.get(`${url}/api/metrics/usage-time`);
          setUsageStats(usageStats.data.data);
  
        } catch (error) {
          console.error("Error fetching stats:", error);
        }
      };

    fetchStats();
  }, []);

  if (!usagestats ) {
    return <div>Veriler Geliyor</div>;
  }

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl py-4 px-4">
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
        {[
          { name: "Tüm Kullanıcıların Toplam Süresi", value: `${usagestats.totalTimeSpent}` },
          { name: "Tüm Kullanıcıların Ortalama Süresi", value: `${usagestats.averageTimePerUser}` },
          { name: "Premium Kullanıcıların Toplam Süresi", value: `${usagestats.activeTotalTimeSpent}` },
          { name: "Premium Kullanıcıların Ortalama Süresi", value: `${usagestats.activeAverageTimePerUser}` },
        ].map((stat) => (
          <div
            key={stat.name}
            className="flex flex-col justify-center items-center bg-gray-100 px-4 py-10 sm:px-6 xl:px-8"
          >
            <dt className="xl:text-xs 2xl:text-base text-center font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>
            <dd className="xl:text-xl 2xl:text-3xl font-medium leading-10 tracking-tight text-gray-900">
            {new Intl.NumberFormat("en-US", {
                maximumFractionDigits:0,
              }).format(parseInt(stat.value))} dakika
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default UsageStats;
