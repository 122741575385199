import React, { useState, useEffect } from "react";
import axios from "axios";

interface ChurnRateData {
  activeMembers: number;
  leftMembers: number;
  churnRate: number;
}

interface ChurnRateStats {
  january: ChurnRateData;
  february: ChurnRateData;
  march: ChurnRateData;
  april: ChurnRateData;
  may: ChurnRateData;
}

interface ChurnRateResponse {
  averageChurnRate: string;
  lifeTime: string;
  data: ChurnRateStats;
}

interface MetricsResponse {
    MRR: string;
    ARR: string;
    AverageMonthly: string;
    AverageYearly: string;
    activeMemberCounts: number;
    total: number;
  }

const LtvCacStats: React.FC = () => {
  const [stats, setStats] = useState<ChurnRateResponse | null>(null);
  const [metricsStats, setMetricsStats] = useState<MetricsResponse | null>(null);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
        try {
          const churnRateResponse = await axios.get(`${url}/api/metrics/churn-rate`);
          setStats(churnRateResponse.data);
  
          const metricsResponse = await axios.get(`${url}/api/metrics/mrr-arr`);
          setMetricsStats(metricsResponse.data.data);
        } catch (error) {
          console.error("Error fetching stats:", error);
        }
      };

    fetchStats();
  }, []);

  if (!stats || !metricsStats) {
    return <div>Loading...</div>;
  }
  const lifeTimeValue = (parseFloat(metricsStats.AverageMonthly) * parseFloat(stats.lifeTime)).toFixed();
  const averageMonthlyFormatted = parseFloat(metricsStats.AverageMonthly).toFixed();

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl py-4 px-4">
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
        {[
          //{ name: "MRR", value: `${(metricsStats.MRR)} ₺` },
          //{ name: "ARR", value: `${(metricsStats.ARR)} ₺` },
          { name: "Average Churn Rate", value: `${(parseFloat(stats.averageChurnRate) * 100).toFixed(2)}%` },
          { name: "Average Life Time", value: `${stats.lifeTime} ay` },
          { name: "ARPU (Average revenue per user - monthly)", value: `${averageMonthlyFormatted}₺` },
          { name: "Life Time Value (LTV)", value: `${lifeTimeValue}₺` },
        ].map((stat) => (
          <div
            key={stat.name}
            className="flex flex-col justify-center items-center bg-gray-100 px-4 py-10 sm:px-6 xl:px-8"
          >
            <dt className="text-sm text-center font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>
            <dd className="text-3xl font-medium leading-10 tracking-tight text-gray-900">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default LtvCacStats;
