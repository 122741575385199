import { Fragment, useState } from "react";
import { useAuth, AuthContextValue } from "../../context/AuthContext";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { NavigationItem } from "./masterLayout";
import {
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

interface NavigationProps {
  navigation: NavigationItem[];
  current: string;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
  subNavigation: NavigationItem[]; // Yeni eklenen
  portfolioSystemOpen: boolean; // Yeni eklenen
}

const Sidebar: React.FC<NavigationProps> = ({
  navigation,
  current,
  setCurrent,
  subNavigation, // Yeni eklenen
  portfolioSystemOpen, // Yeni eklenen
}) => {
  const { logout } = useAuth() as AuthContextValue;
  const handleClick = (name: string) => {
    setCurrent(name);
  };
  const location = useLocation();
  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-red-100 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-14 w-auto"
                src="/assets/img/logo.png"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-4">
                {navigation.map((tool) =>
                  !tool?.children ? (
                    <Link
                      key={tool.name}
                      to={tool.href}
                      // onClick={() => handleClick(tool.href)}
                      className={classNames(
                        location.pathname === tool.href
                          ? "bg-gray-200 text-red-600"
                          : "text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <tool.icon
                        className={classNames(
                          location.pathname === tool.href
                            ? "text-gray-300"
                            : "text-gray-400 group-hover:text-gray-300",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {tool.name}
                    </Link>
                  ) : (
                    <Disclosure as="div" key={tool.name} className="space-y-1">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              "text-gray-700 hover:bg-gray-50 hover:text-red-600",
                              "group flex w-full items-center rounded-md pr-2 text-left text-sm font-semibold focus:outline-none focus:ring-2"
                            )}
                          >
                            <svg
                              className={classNames(
                                open
                                  ? "rotate-90 text-gray-400"
                                  : "text-gray-300",
                                "mr-1 h-10 w-10 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                              )}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path
                                d="M6 6L14 10L6 14V6Z"
                                fill="currentColor"
                              />
                            </svg>
                            {tool.name}
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {tool?.children?.map((subItem) => (
                              <Link
                                key={subItem.name}
                                // onClick={() => setCurrent(subItem.href)}
                                to={subItem.href}
                                className={classNames(
                                  location.pathname.includes(subItem.href)
                                    ? "bg-gray-200 text-red-600"
                                    : "text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600",
                                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                )}
                              >
                                -{subItem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                )}

                <li className="mt-auto">
                  <Link
                    to="/my-profile"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
                      aria-hidden="true"
                    />
                    Hesabım
                  </Link>
                  <button
                    onClick={logout}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600"
                  >
                    <XCircleIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
                      aria-hidden="true"
                    />
                    Çıkış Yap
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
