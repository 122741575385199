import axios from 'axios';
import React,{ useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDateTR } from '../../helpers/date';

export interface Message {
  id: number;
  messageContent: string;
  messageType: 'text' | 'image';
  isRead: boolean;
  senderId: number;
  receiverId: number;
  createdAt: string;
  sender: Member;
  receiver: Member;
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
}

interface Conversation {
  participants: string;
  messages: Message[];
}

export default function MessageTable() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [total,setTotal] = useState(0)
  const [expandedConversations, setExpandedConversations] = useState<string[]>([]);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMessages = async (): Promise<void> => {
      const response = await axios.get<{
        data: { total: number; messages: Message[] };
      }>(`${url}/api/members/producer-messages`);
      setMessages(response.data.data.messages);
      setTotal(response.data.data.total)
    };

    fetchMessages();
  }, []);

  const groupMessagesByConversation = (messages: Message[]): Conversation[] => {
    const grouped: { [key: string]: Conversation } = {};

    messages.forEach((message) => {
      const participants = [message.senderId, message.receiverId].sort().join('-');
      if (!grouped[participants]) {
        grouped[participants] = {
          participants,
          messages: [],
        };
      }
      grouped[participants].messages.push(message);
    });

    return Object.values(grouped);
  };

  const toggleConversation = (participants: string) => {
    setExpandedConversations((prev) =>
      prev.includes(participants) ? prev.filter((p) => p !== participants) : [...prev, participants]
    );
  };

  const groupedConversations = groupMessagesByConversation(messages);

  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto text-lg font-bold"> Toplam Mesaj: {total}</div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      İsim (Gönderen)
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İsim (Gönderilen)
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Son Mesaj Tarihi
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Mesaj Sayısı
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {groupedConversations.map((conversation) => {
                    const participants = conversation.participants;
                    const isExpanded = expandedConversations.includes(participants);

                    const firstMessage = conversation.messages[0];
                    const sender = firstMessage.sender;
                    const receiver = firstMessage.receiver;

                    return (
                      <React.Fragment key={participants}>
                        <tr
                          className="cursor-pointer"
                          onClick={() => toggleConversation(participants)}
                        >
                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                            <Link
                              to={`/members/${sender.id}`}
                              target="_blank"
                              className="text-red-600 hover:text-red-900"
                            >
                              {sender.firstName} {sender.lastName}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            <Link
                              to={`/members/${receiver.id}`}
                              target="_blank"
                              className="text-red-600 hover:text-red-900"
                            >
                              {receiver.firstName} {receiver.lastName}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {formatDateTR(conversation.messages[0].createdAt)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {conversation.messages.length}
                          </td>
                        </tr>
                        {isExpanded &&
                          conversation.messages.map((message) => (
                            <tr key={message.id} className="bg-gray-100">
                              <td
                                className={`whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0 ${message.senderId === Number(sender.id) ? 'text-left' : 'text-right'}`}
                                colSpan={3}
                              >
                                <div className={`${message.senderId === Number(sender.id) ? 'text-left' : 'text-right'}`}>
                                  <span>{formatDateTR(message.createdAt)}</span>
                                  <br />
                                  <span>{message.messageContent}</span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
