import axios, { AxiosInstance } from 'axios';
import API_URL from '../config';

const axiosInstance: AxiosInstance = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
  baseURL: API_URL,
  withCredentials: true,
});

export default axiosInstance;
