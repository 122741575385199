import React, { useState, useEffect } from "react";
import axios from "axios";

interface ChurnRateStat {
    activeMembers: number;
    leftMembers: number;
    churnRate: string;
  }

const ChurnRateStats: React.FC = () => {
  const [stats, setStats] = useState<{
    january: ChurnRateStat;
    february: ChurnRateStat;
    march: ChurnRateStat;
    april: ChurnRateStat;
    may: ChurnRateStat;
    june: ChurnRateStat;
    july: ChurnRateStat;
    august: ChurnRateStat;
    september: ChurnRateStat;
    october: ChurnRateStat;
    november: ChurnRateStat;
    december: ChurnRateStat;
    january2024: ChurnRateStat;
    february2024: ChurnRateStat;
    march2024: ChurnRateStat;
    april2024: ChurnRateStat;
    may2024: ChurnRateStat;
  } | null>(null);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${url}/api/metrics/churn-rate`);
        setStats(response.data.data);
      } catch (error) {
        console.error("Error fetching churn rate stats:", error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Veriler geliyor ...</div>;
  }

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl py-4 px-4">
      <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-3 lg:grid-cols-7">
        {[
          { name: "Ocak", value: stats.january },
          { name: "Şubat", value: stats.february },
          { name: "Mart", value: stats.march },
          { name: "Nisan", value: stats.april },
          { name: "Mayıs", value: stats.may },
          { name: "Haziran", value: stats.june },
          { name: "Temmuz", value: stats.july },
          { name: "Ağustos", value: stats.august },
          { name: "Eylül", value: stats.september },
          { name: "Ekim", value: stats.october },
          { name: "Kasım", value: stats.november },
          { name: "Aralık", value: stats.december },
          { name: "Ocak", value: stats.january2024 },
          { name: "Şubat", value: stats.february2024 },
          { name: "Mart", value: stats.march2024 },
          { name: "Nisan", value: stats.april2024 },
          { name: "Mayıs", value: stats.may2024 },
        ].map((stat) => (
          <div
            key={stat.name}
            className="flex flex-col justify-center items-center bg-gray-100 px-4 py-4 sm:px-6 xl:px-8"
          >
            <dt className="text-sm text-center font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>
            <dd className="text-2xl font-medium leading-10 tracking-tight text-gray-900">
              {`${(parseFloat(stat.value.churnRate) * 100).toFixed()}%`}
            </dd>
            <dd className="text-sm text-center font-medium leading-6 text-gray-500">
              {`(${stat.value.leftMembers}/${stat.value.activeMembers})`}
            </dd>
            
          </div>
        ))}
      </dl>
    </div>
  );
};

export default ChurnRateStats;
