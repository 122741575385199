import React, { useState } from "react";

import {
  Flash,
  Shop,
  Strongbox2,
  Wallet2,
  PercentageSquare,
  ShopRemove,
  ArrowDown2,
  ArrowUp2,
} from "iconsax-react";

interface Props {
  data: any;
  isLoading: boolean;
}

const InventorySummary: React.FC<Props> = ({
  data,
  isLoading,
}) => {
  const [openInbound, setOpenInbound] = useState(false);
  const [openReserved, setOpenReserved] = useState(false);
  const [openFuture, setOpenFuture] = useState(false);
  const [openResearching, setOpenResearching] = useState(false);
  const [openUnfulfillable, setOpenUnfulfillable] = useState(false);
  return (
    <div className="grid gap-4 grid-cols-8 justify-between items-center my-5">
      {/* Filter Section */}
      {/* <div className="col-span-10">
       
      </div> */}
      {/* CARDS Section */}
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Strongbox2
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#820CF8" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#4338CA" }}>
            Toplam Stok
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#4338CA]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#4338CA" }}>
              {data?.data.data.totals.totalQuantity}
            </div>
          )}
        </div>
      </div>
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Wallet2
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#34D399" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#047857" }}>
            Fulfillable Stok
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#047857]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#047857" }}>
              {data?.data.data.totals.totalFulfillableQuantity}
            </div>
          )}
        </div>
      </div>
      <div className="shadow-md relative col-span-10  md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <ShopRemove
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#FBBF24" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openInbound ? (
            <ArrowUp2
              onClick={() => setOpenInbound((prev) => !prev)}
              onBlur={() => setOpenInbound(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#B45309"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenInbound((prev) => !prev)}
              onBlur={() => setOpenInbound(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#B45309"
            />
          )}
          <div className="font-normal" style={{ color: "#B45309" }}>
            Inbound Stok
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#B45309]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#B45309" }}>
              {data?.data.data.totals.totalInboundReceivingQuantity + data?.data.data.totals.totalInboundShippedQuantity + data?.data.data.totals.totalInboundWorkingQuantity} 
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] left-0 p-4 bg-white shadow-lg w-[300px] z-20"
          style={{ display: openInbound ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Inbound Receiving Quantity :</p>
            <p className="text-xs">
            {data?.data.data.totals.totalInboundReceivingQuantity}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Inbound Shipped Quantity :</p>
            <p className="text-xs">{data?.data.data.totals.totalInboundShippedQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Inbound Working Quantity :</p>
            <p className="text-xs">
            {data?.data.data.totals.totalInboundWorkingQuantity}
            </p>
          </div>
        </div>
      </div>

      <div className="shadow-md relative col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Flash
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#A78BFA" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openReserved ? (
            <ArrowUp2
              onClick={() => setOpenReserved((prev) => !prev)}
              onBlur={() => setOpenReserved(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#6D28D9"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenReserved((prev) => !prev)}
              onBlur={() => setOpenReserved(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#6D28D9"
            />
          )}
          <div className="font-normal" style={{ color: "#6D28D9" }}>
            Reserved Stok
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#6D28D9]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#6D28D9" }}>
              {data?.data.data.totals.totalReservedQuantity}
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] left-0 p-4 bg-white shadow-lg w-[300px]"
          style={{ display: openReserved ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Pending Customer Order Quantity :</p>
            <p className="text-xs">{data?.data.data.totals.totalPendingCustomerOrderQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Pending Transshipment Quantity :</p>
            <p className="text-xs">{data?.data.data.totals.totalPendingTransshipmentQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Fc Processing Quantity :</p>
            <p className="text-xs">{data?.data.data.totals.totalFcProcessingQuantity}</p>
          </div>
        </div>
      </div>
      <div className="shadow-md relative col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Shop
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#F87171" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openResearching ? (
            <ArrowUp2
              onClick={() => setOpenResearching((prev) => !prev)}
              onBlur={() => setOpenResearching(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#B45309"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenResearching((prev) => !prev)}
              onBlur={() => setOpenResearching(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#B45309"
            />
          )}
          <div className="font-normal" style={{ color: "#B91C1C" }}>
            Researching Stok
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#B91C1C]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#B91C1C" }}>
             {data?.data.data.totals.totalResearchingQuantity}
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] z-10 left-0 p-4 bg-white shadow-lg w-full"
          style={{ display: openResearching ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">In Short Term (1-10 days) :</p>
            <p className="text-xs">
            {data?.data.data.totals.totalResearchingQuantityInShortTerm}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">In Mid Term (11-20 days):</p>
            <p className="text-xs">
            {data?.data.data.totals.totalResearchingQuantityInMidTerm}
            </p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">In Long Term (21 days or longer):</p>
            <p className="text-xs">
            {data?.data.data.totals.totalResearchingQuantityInLongTerm}
            </p>
          </div>
        </div>
      </div>
      
      <div className="shadow-md relative col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Strongbox2
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#F472B6" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openUnfulfillable ? (
            <ArrowUp2
              onClick={() => setOpenUnfulfillable((prev) => !prev)}
              onBlur={() => setOpenUnfulfillable(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#F472B6"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenUnfulfillable((prev) => !prev)}
              onBlur={() => setOpenUnfulfillable(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#F472B6"
            />
          )}
          <div className="font-normal" style={{ color: "#BE205D" }}>
          Unfulfillable Stok
          </div>

          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#BE205D]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#BE205D" }}>
              {data?.data.data.totals.totalUnfulfillableQuantity}
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] left-0 p-4 z-10 bg-white shadow-lg w-[300px]"
          style={{ display: openUnfulfillable ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Customer Damaged:</p>
            <p className="text-xs">{data?.data.data.totals.totalCustomerDamagedQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Warehouse Damaged:</p>
            <p className="text-xs">{data?.data.data.totals.totalWarehouseDamagedQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Distributor Damaged:</p>
            <p className="text-xs">{data?.data.data.totals.totalDistributorDamagedQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Carrier Damaged:</p>
            <p className="text-xs">{data?.data.data.totals.totalCarrierDamagedQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Defective:</p>
            <p className="text-xs">{data?.data.data.totals.totalDefectiveQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Expired:</p>
            <p className="text-xs">{data?.data.data.totals.totalExpiredQuantity}</p>
          </div>
          
        </div>
      </div>
      <div className="shadow-md relative col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <Strongbox2
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#2DD4BF" }}
          />
        </div>
        <div className="col-span-5 relative">
          {openFuture ? (
            <ArrowUp2
              onClick={() => setOpenFuture((prev) => !prev)}
              onBlur={() => setOpenFuture(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#2DD4BF"
            />
          ) : (
            <ArrowDown2
              onClick={() => setOpenFuture((prev) => !prev)}
              onBlur={() => setOpenFuture(false)}
              className="absolute w-8 h-8 font-extrabold -bottom-2 right-0 cursor-pointer"
              color="#2DD4BF"
            />
          )}
          <div className="font-normal" style={{ color: "#2DD4BF" }}>
          Future Stok
          </div>

          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#2DD4BF]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#2DD4BF" }}>
             {data?.data.data.totals.totalReservedFutureSupplyQuantity + data?.data.data.totals.totalFutureSupplyBuyableQuantity}
            </div>
          )}
        </div>
        <div
          className=" absolute top-[100px] z-10 left-0 p-4 bg-white shadow-lg w-[300px]"
          style={{ display: openFuture ? "block" : "none" }}
        >
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Reserved Future Supply Quantity:</p>
            <p className="text-xs">{data?.data.data.totals.totalReservedFutureSupplyQuantity}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-b border-[#EDEEF8]">
            <p className="text-xs">Future Supply Buyable Quantity:</p>
            <p className="text-xs">{data?.data.data.totals.totalFutureSupplyBuyableQuantity}</p>
          </div>
          
        </div>
      </div>
      <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <PercentageSquare
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#38BDF8" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#0369A1" }}>
            Stok Değeri
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#0369A1]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#0369A1" }}>
             ${data?.data.data.totals.totalStockValue.toFixed(2)}
            </div>
          )}
        </div>
      </div>
      
      {/* <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <PercentageSquare
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#2DD4BF" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#0F766E" }}>
            Kar Marjı
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#0F766E]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#0F766E" }}>
             {data?.data.data.totals.totalQuantity}
            </div>
          )}
        </div>
      </div> */}
      {/* <div className="shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]">
        <div className="col-span-3 m-auto">
          <PercentageSquare
            className=" w-10 h-10 font-extrabold text-3xl"
            style={{ color: "#3B82F6" }}
          />
        </div>
        <div className="col-span-5">
          <div className="font-normal" style={{ color: "#1D4ED8" }}>
            Toplam ACOS
          </div>
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className={`opacity-75 text-[#1D4ED8]`}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <div className={`font-medium `} style={{ color: "#1D4ED8" }}>
              {data?.data.data.totals.totalQuantity}
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default InventorySummary;
