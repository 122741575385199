import API_URL from '../config';
import { UpdatePasswordValues } from '../context/AuthContext';
import httpClient from '../utils/httpClient';

class MemberService {
  // update user password
  updatePassword(values: UpdatePasswordValues) {
    return httpClient.patch(`${API_URL}/api/users/update-password`, values);
  }

  updatePaymentMethod(id: number) {
    return httpClient.patch(`${API_URL}/api/members/${id}`);
  }
}

export default new MemberService();
