import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth, AuthContextValue } from '../context/AuthContext';
import Loading from '../components/loader/loader';

// Since your component doesn't have any specific props, you can use an empty interface
interface AuthRouterProps {}

const AuthRouter: FC<AuthRouterProps> = () => {
  const { user } = useAuth() as AuthContextValue;

  if (user === undefined) {
    return <Loading />;
  }
  if (user) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default AuthRouter;
