let API_URL: string | undefined;

if (process.env.REACT_APP_ENV === 'production') {
  API_URL = process.env.REACT_APP_API_URL;
} else if (process.env.REACT_APP_ENV === 'test') {
  API_URL = process.env.REACT_APP_BASE_URL_TEST;
} else if (process.env.REACT_APP_ENV === 'development') {
  API_URL = process.env.REACT_APP_API_URL;
}

export default API_URL;
