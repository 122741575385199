import { useState, useEffect } from "react";
import axios from "axios";
import { formatDate, formatDateTR } from "../../helpers/date";
import Pagination from "../general/Pagination";
import { Link } from "react-router-dom";
import { useAuth, AuthContextValue } from "../../context/AuthContext";
import SearchBar from "../general/SearchBar";
import Select, { ActionMeta, SingleValue } from "react-select";

export interface UserPackage {
  status: string;
  packagePlanId: number;
  cancelDate: string | null;
}
export interface UserTracks {
  totalTimes: string;
  page: string;
}
interface OptionType {
  value: string;
  label: string;
}

export interface UserLabel {
  description: string | null;
  label: string | null;
}

export interface Invoice {
  billingDate: string;
  paymentStatus: string;
  paidPrice: string;
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  gsmNumber: string | null;
  email: string;
  status: string;
  createdAt: string;
  userPackages: UserPackage[];
  userLabel: UserLabel;
  invoices: Invoice[];
  userTracks: UserTracks[];
}
export interface User {
  _id: string;
  name: string;
  email: string;
  clients: string[];
  role: string[];
}

export default function DataByUser() {
  const [members, setMembers] = useState<Member[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10000);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  const [search, setSearch] = useState("");
  const [salesReps, setSalesReps] = useState<User[]>([]);
  const [selectedLabel, setSelectedLabel] = useState({
    value: "Bütün Kullanıcılar",
    label: "Bütün Kullanıcılar",
  });
  const labelOptions = [
    { value: "Bütün Kullanıcılar", label: "Bütün Kullanıcılar" },
    { value: "Etiket Olmayanlar", label: "Etiket Olmayanlar" },
    { value: "Satış Yapıldı", label: "Satış Yapıldı" },
    { value: "Mevcut Müşteri", label: "Mevcut Müşteri" },
    { value: "Meşgul", label: "Meşgul" },
    { value: "Ulaşılamadı", label: "Ulaşılamadı" },
    { value: "Randevu", label: "Randevu" },
    { value: "Ödeme Aşamasında", label: "Ödeme Aşamasında" },
    { value: "Düşünmek İstiyor", label: "Düşünmek İstiyor" },
    { value: "Aranmamalı", label: "Aranmamalı" },
    { value: "Aranmamalı (parası yok)", label: "Aranmamalı (parası yok)" },
    {
      value: "Aranmamalı (bilgi almak istemiyor)",
      label: "Aranmamalı (bilgi almak istemiyor)",
    },
    { value: "Aranmamalı (zamanı yok)", label: "Aranmamalı (zamanı yok)" },
    {
      value: "Aranmamalı (yapmayı düşünmüyor)",
      label: "Aranmamalı (yapmayı düşünmüyor)",
    },
    {
      value: "Aranmamalı (bizi tanımıyor)",
      label: "Aranmamalı (bizi tanımıyor)",
    },
    {
      value: "Aranmamalı (başka firma ile çalışacak)",
      label: "Aranmamalı (başka firma ile çalışacak)",
    },
    {
      value: "Aranmamalı (eğitim parayla verilmez)",
      label: "Aranmamalı (eğitim parayla verilmez)",
    },
    {
      value: "Aranmamalı (youtube yetiyormuş)",
      label: "Aranmamalı (youtube yetiyormuş)",
    },
    {
      value: "Aranmamalı (birebir danışmanlık istiyor)",
      label: "Aranmamalı (birebir danışmanlık istiyor)",
    },
    {
      value: "Aranmamalı (eğitimleri canlı istiyor)",
      label: "Aranmamalı (eğitimleri canlı istiyor)",
    },
    {
      value: "Aranmamalı (dropshipping istiyor)",
      label: "Aranmamalı (dropshipping istiyor)",
    },
    {
      value: "Aranmamalı (bize güvenmiyor)",
      label: "Aranmamalı (bize güvenmiyor)",
    },
    {
      value: "Aranmamalı (yaşı küçük, öğrenci)",
      label: "Aranmamalı (yaşı küçük, öğrenci)",
    },
    {
      value: "Aranmamalı (olumsuz yorumlar görmüş)",
      label: "Aranmamalı (olumsuz yorumlar görmüş)",
    },
    {
      value: "Aranmamalı (teknoloji ile arası yok)",
      label: "Aranmamalı (teknoloji ile arası yok)",
    },
  ];

  const { user } = useAuth() as AuthContextValue;

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMembers = async (): Promise<void> => {
      const response = await axios.get<{
        data: { total: number; data: Member[] };
      }>(
        `${url}/api/data/data-by-sales-rep/${user?.id}?page=${page}&limit=${limit}`
      );
      setMembers(response.data.data.data);
      setTotalPageCount(Math.ceil(response.data.data.total / limit));
    };

    fetchMembers();
  }, [page, limit, url, user]);

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();

    setFilteredMembers(
      members.filter(
        (member) =>
          member.firstName.toLowerCase().includes(lowercasedSearch) ||
          member.lastName.toLowerCase().includes(lowercasedSearch) ||
          member.email.toLowerCase().includes(lowercasedSearch) ||
          member.gsmNumber?.toLowerCase().includes(lowercasedSearch)
      )
    );
  }, [search, members]);

  const onSearch = (searchValue: any) => {
    setSearch(searchValue);
  };

  // Fetch sales reps when component mounts
  useEffect(() => {
    const fetchSalesReps = async (): Promise<void> => {
      const response = await axios.get<{ data: User[] }>(
        `${url}/api/admin/get-users`
      );
      const agents = response.data.data.filter((user) =>
        user.role.includes("agent")
      );
      setSalesReps(agents);
    };

    fetchSalesReps();
  }, [url]);

  const transferData = async (memberId: string, targetAgentId: string) => {
    try {
      await axios.post(`${url}/api/data/transfer`, {
        sourceAgentId: user?.id,
        targetAgentId,
        memberId,
      });

      alert("Data başarılı bir şekilde transfer edildi.");

      // Refresh member data
      const response = await axios.get<{
        data: { total: number; data: Member[] };
      }>(
        `${url}/api/data/data-by-sales-rep/${user?.id}?page=${page}&limit=${limit}`
      );
      setMembers(response.data.data.data);
    } catch (error) {
      console.error(error);
      alert("Data transfer failed.");
    }
  };
  // Filtrelenmiş üyeleri güncellemek için useEffect
  useEffect(() => {
    if (selectedLabel.value === "Bütün Kullanıcılar") {
      setFilteredMembers(members);
    } else if (selectedLabel.value === "Etiket Olmayanlar") {
      setFilteredMembers(
        members.filter((member) => !member.userLabel || !member.userLabel.label)
      );
    } else {
      setFilteredMembers(
        members.filter(
          (member) =>
            member.userLabel && member.userLabel.label === selectedLabel.value
        )
      );
    }
  }, [members, selectedLabel]);

  // Select komponenti için onChange handler
  const handleLabelChange = (
    selectedOption: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (selectedOption !== null) {
      setSelectedLabel(selectedOption);
    } else {
      // null durumu için bir işlem yapabilirsiniz, örneğin varsayılan bir değere dönmek
      setSelectedLabel({
        value: "Bütün Kullanıcılar",
        label: "Bütün Kullanıcılar",
      });
    }
  };

  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex-grow">
            <SearchBar onSearch={onSearch} />
          </div>
          <div className="sm:flex-shrink">
            <Select
              options={labelOptions}
              value={selectedLabel}
              onChange={handleLabelChange}
              className="mt-4 sm:mt-0"
            />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Sıra No
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İsim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Soyisim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Katılış Tarihi
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Telefon
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Geçirdiği Süre
                    </th>
                    {/* <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Aktif üye mi?
                    </th> */}
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Sonuç Kodları
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Açıklama
                    </th>
                    <th
                      scope="col"
                      className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Detay</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {filteredMembers.map((member) => (
                    <tr key={member.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        {member.id}
                      </td>
                      <td className="px-2 py-2 text-sm font-medium text-gray-900">
                        <Link
                          to={`/members/${member.id}`}
                          target="_blank"
                          className="text-red-600 hover:text-red-900"
                        >
                          {member.firstName}
                          <span className="sr-only">, {member.id}</span>
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {member.lastName}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.email}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {formatDateTR(member.createdAt)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.gsmNumber}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userTracks[0] ? member.userTracks[0].totalTimes: "0"} dakika
                      </td>
                      {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userPackages && member.userPackages.length > 0
                          ? member.userPackages[0].status
                          : "N/A"}
                      </td> */}
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userLabel ? member.userLabel.label : " --- "}
                      </td>
                      <td
                        className="whitespace-normal px-2 py-2 text-sm text-gray-500 overflow-hidden max-w-sm"
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                          overflow: "hidden",
                        }}
                      >
                        {member.userLabel
                          ? member.userLabel.description
                          : " --- "}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        <Select
                          options={salesReps.map((rep) => ({
                            value: rep._id,
                            label: rep.name,
                          }))}
                          onChange={(selectedOption: any) =>
                            transferData(member.id, selectedOption.value)
                          }
                          placeholder="Datayı Transfer Et ..."
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />
                      </td>
                      {/* <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <Link
                          to={`/members/${member.id}`}
                          className="text-red-600 hover:text-red-900"
                        >
                          Detay
                          <span className="sr-only">, {member.id}</span>
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          totalPageCount={totalPageCount}
        />
      </div>
    </div>
  );
}
