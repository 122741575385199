import PortfolioTable from "../components/portfolio/portfolioMembers";
import PortfolioStats from "../components/portfolio/portfolioStats";

export default function Portfolio() {

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Portföy üyelerimiz
          </h1>
        </div>
        <div className="py-4"><PortfolioStats /></div>
        <PortfolioTable />
      </main>
    </div>
  );
}
