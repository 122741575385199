import React from "react";
import { formatDateTR } from "../../helpers/date";
import { addCurrencySymbol } from "./AmazonOrders";

interface Props {
  data: any;
}

const OrderStatsTable: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl my-8">
      {/* <div className="flow-root"> */}
      <div className="relative overflow-x-auto overflow-y-auto">
        <div className="inline-block min-w-full align-middle"></div>
        <table className="min-w-full leading-normal">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Amazon Sipariş Numarası
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Pazaryeri
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Sipariş Tarihi
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Ürün SKU
              </th>
              
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Fiyat
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                FBA
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Komisyon
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Ürün 
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Kargo 
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Diğer
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Vergi
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Vergi (-)
              </th>

              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Net Kâr
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
              >
                Ürün Adedi
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((order: any, index: number) => {
              return (
                <tr key={index} className="bg-white">
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {order.amazonOrderId}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {order.marketplaceName}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {formatDateTR(order.postedDate)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {order.sellerSku}
                  </td>
                  
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(
                      order.principalAmount,
                      order.marketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(
                      order.fbaPerUnitFulfillmentFeeAmount,
                      order.marketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(
                      order.commissionAmount,
                      order.marketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(
                      order.productCogs,
                      order.marketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(
                      order.shippingCogs,
                      order.marketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(
                      order.otherCogs,
                      order.marketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    <div className="flex items-center">
                      <p>
                        {" "}
                        {addCurrencySymbol(
                          order.taxAmount,
                          order.marketplaceName
                        )}
                      </p>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(
                      order.marketplaceFacilitatorTaxPrincipalAmount,
                      order.marketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {addCurrencySymbol(
                      (Number(order.principalAmount) + 
                        Number(order.fbaPerUnitFulfillmentFeeAmount) +
                        Number(order.commissionAmount) +
                        Number(order.productCogs) +
                        Number(order.shippingCogs) +
                        Number(order.otherCogs) +
                        Number(order.taxAmount) +
                        Number(order.marketplaceFacilitatorTaxPrincipalAmount)),
                      order.marketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                    {order.quantityShipped}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderStatsTable;
