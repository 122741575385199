import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../general/SearchBar';

import { formatDateTR } from '../../helpers/date';
import Pagination from '../general/Pagination';

export interface UserPackage {
  status: string;
  packagePlanId: number;
  cancelDate: string | null;
}

export interface Invoice {
  billingDate: string;
  paymentStatus: string;
  paidPrice: string;
}

export interface BillingAddresses {
  gsmNumber: string;
}

export interface UserTracks {
  totalTimes: string;
  page: string;
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gsmNumber: string | null;
  status: string;
  createdAt: string;
  registerFrom: string;
  userPackages: UserPackage[];
  invoices: Invoice[];
  billingAddresses: BillingAddresses[];
  userTracks: UserTracks[];
  location: string
  producer: Producer
}
export interface Producer {
  producerName: string;
}

export default function MemberTable() {
  const [members, setMembers] = useState<Member[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [search, setSearch] = useState(""); 

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMembers = async (): Promise<void> => {
      const response = await axios.get<{
        data: { total: number; members: Member[] };
      }>(`${url}/api/members/all-members?page=${page}&limit=${limit}&search=${search}`);
      setMembers(response.data.data.members);
      setTotalPageCount(Math.ceil(response.data.data.total / limit));
    };

    fetchMembers();
  }, [page, limit, search, url]);

  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
          <SearchBar onSearch={(searchValue) => setSearch(searchValue)} /> 
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Sıra No
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İsim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Soyisim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Telefon
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Katılış Tarihi
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Geçirdiği Süre
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Aktif üye mi?
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Nereden Kayıt Oldu?
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {members.map((member) => (
                    <tr key={member.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        {member.id}
                      </td>
                      <td className="px-2 py-2 text-sm font-medium text-gray-900 overflow-hidden ellipsis max-w-sm">
                        <Link
                          to={`/members/${member.id}`}
                          target="_blank"
                          className="text-red-600 hover:text-red-900"
                        >
                          {member.firstName.length > 15
                            ? `${member.firstName.substring(0, 15)}...`
                            : member.firstName}
                          <span className="sr-only">, {member.id}</span>
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {member.lastName}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.email}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.gsmNumber
                          ? member.gsmNumber
                          : member.billingAddresses &&
                            member.billingAddresses.length > 0 &&
                            member.billingAddresses[0].gsmNumber
                            ? member.billingAddresses[0].gsmNumber
                            : " - "}
                      </td>

                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {formatDateTR(member.createdAt)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userTracks && member.userTracks.length > 0
                          ? `${member.userTracks[0].totalTimes} dakika`
                          : "0"}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userPackages && member.userPackages.length > 0
                          ? member.userPackages[0].status === "active"
                            ? "Aktif"
                            : "Pasif"
                          : "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {member.registerFrom}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          totalPageCount={totalPageCount}
        />
      </div>
    </div>
  );
}
