import React, { useState, useEffect } from "react";
import axios from "axios";

interface PortfolioStat {
  userId: string;
  userName: string;
  totalPortfolioMembers: number;
  activeMembers: number;
  passiveMembers: number;
  monthlyMembers: number;
  yearlyMembers: number;
}

const PortfolioStatsByAllUsers: React.FC = () => {
  const [stats, setStats] = useState<PortfolioStat[] | null>(null);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${url}/api/portfolio/all-users-stats`);
        setStats(response.data.data);
      } catch (error) {
        console.error("Error fetching portfolio stats:", error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white rounded-lg drop-shadow-2xl py-4 px-4">
      {stats.map((userStat) => (
        <div key={userStat.userId} className="mb-8">
          <h3 className="text-2xl font-semibold text-red-600 mb-4">
            {userStat.userName}
          </h3>
          <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-5">
            {[
              { name: "Toplam Portföy", value: userStat.totalPortfolioMembers },
              { name: "Aktif Üye Sayısı", value: userStat.activeMembers },
              { name: "Pasif Üye Sayısı", value: userStat.passiveMembers },
              { name: "Aylık Üye Sayısı", value: userStat.monthlyMembers },
              { name: "Yıllık Üye Sayısı", value: userStat.yearlyMembers },
            ].map((stat) => (
              <div
                key={stat.name}
                className="flex flex-col justify-center items-center bg-gray-100 px-4 py-10 sm:px-6 xl:px-8"
              >
                <dt className="text-sm text-center font-medium leading-6 text-gray-500">
                  {stat.name}
                </dt>
                <dd className="text-3xl font-medium leading-10 tracking-tight text-gray-900">
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 0,
                  }).format(stat.value)}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      ))}
    </div>
  );
};

export default PortfolioStatsByAllUsers;
