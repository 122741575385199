import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import Notification from "../../components/general/Notification";
import tr from "date-fns/locale/tr";

interface CogsData {
  id?: string;
  sellerSku: string;
  purchaseDate: string;
  quantity: number;
  purchasePrice: number;
  shippingCost: number;
  otherCost: number;
  cogsPerUnit?: number;
}

interface CostInputModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (cogsData: CogsData[]) => void;
  sellerSku: string;
  cogsData?: CogsData[];
  totalCount: number;
}

export const CostInputModal: React.FC<CostInputModalProps> = ({
  isOpen,
  onClose,
  onSave,
  sellerSku,
  cogsData,
  totalCount,
}) => {
  const [show, setShow] = useState(false);

  registerLocale("tr", tr);
  setDefaultLocale("tr");
  // If there is existing COGS data, use it as initial values; otherwise, use default empty values
  const initialValues = {
    entries: cogsData?.length
      ? cogsData.map((cogs) => ({
          id: cogs.id,
          purchaseDate: new Date(cogs.purchaseDate), // Tarih string'ini YYYY-MM-DD formatına dönüştür
          quantity: cogs.quantity,
          purchasePrice: cogs.purchasePrice,
          shippingCost: cogs.shippingCost,
          otherCost: cogs.otherCost,
          cogsPerUnit: cogs.cogsPerUnit ? cogs.cogsPerUnit : 0,
        }))
      : [
          {
            purchaseDate: new Date(),
            quantity: 0,
            purchasePrice: 0,
            shippingCost: 0,
            otherCost: 0,
            cogsPerUnit: 0,
          },
        ],
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen overflow-y-auto">
          <Notification
            showNotification={show}
            setShowNotification={setShow}
            title="Hata!"
            message="Toplam ürün sayısı kadar ürün için maliyet girilmelidir!"
          />
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:max-w-5xl lg:max-w-5xl">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-[40vh]">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      COGS for SKU: {sellerSku}
                      <p className="text-sm py-4 text-red-700">
                        ***Burada hangi tarihte kaç ürünü ne kadar maliyetle
                        aldığınızı giriniz.Toplam girilmesi gereken ürün
                        miktarı:{" "}
                        <span className="font-medium">{totalCount}</span>
                      </p>
                    </Dialog.Title>
                    <div className="mt-2">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                          entries: Yup.array().of(
                            Yup.object({
                              purchaseDate: Yup.date().required("Required"),
                              quantity: Yup.number()
                                .min(1, "Must be positive")
                                .required("Required"),
                              purchasePrice: Yup.number()
                                .required("Required")
                                .min(1),
                              shippingCost: Yup.number()
                                .required("Required")
                                .min(1),
                              otherCost: Yup.number()
                                .required("Required")
                                .min(1),
                            })
                          ),
                        })}
                        onSubmit={(values, actions) => {
                          const calculatedEntries: CogsData[] =
                            values.entries.map((entry) => ({
                              sellerSku: sellerSku,
                              purchaseDate: new Date(
                                entry.purchaseDate
                              ).toISOString(),
                              quantity: entry.quantity,
                              purchasePrice: entry.purchasePrice,
                              shippingCost: entry.shippingCost,
                              otherCost: entry.otherCost,
                              cogsPerUnit:
                                (Number(entry.purchasePrice) +
                                  Number(entry.shippingCost) +
                                  Number(entry.otherCost)) /
                                Number(entry.quantity),
                            }));

                          const countArr = values.entries.map((item: any) => {
                            return item.quantity;
                          });

                          const count = countArr.reduce(
                            (total: number, entry: any) => {
                              return total + (entry || 0); // Her elemanın quantity alanını topla
                            },
                            0
                          );
                          if (count >= totalCount) {
                            onSave(calculatedEntries);
                            onClose();
                          } else {
                            setShow(true);
                          }
                          actions.setSubmitting(false);
                        }}
                      >
                        {({ values, handleSubmit, setFieldValue }) => (
                          <Form onSubmit={handleSubmit}>
                            <FieldArray name="entries">
                              {({ remove, push }) => (
                                <div className="overflow-y-auto">
                                  <div className="grid grid-cols-7 gap-4 mb-4 items-center">
                                    <div>Date</div>
                                    <div className="text-left">Quantity</div>
                                    <div>Product ($)</div>
                                    <div>Shipping ($)</div>
                                    <div>Other ($)</div>
                                    <div>Per Unit ($)</div>
                                  </div>
                                  {values.entries.map((entry, index) => (
                                    <div
                                      key={index}
                                      className="grid grid-cols-7 gap-4 mb-4 items-center"
                                    >
                                      {/* <Field
                                        type="date"
                                        name={`entries[${index}].purchaseDate`}
                                        className="border-gray-300 rounded-md shadow-sm text-sm"
                                      /> */}
                                      <div className="col-span-1">
                                        <DatePicker
                                          selected={entry.purchaseDate}
                                          onChange={(date) => {
                                            setFieldValue(
                                              `entries[${index}].purchaseDate`,
                                              date
                                            );
                                          }}
                                          preventOpenOnFocus
                                          autoFocus={false}
                                          popperClassName=""
                                          className="border-gray-300 rounded-md shadow-sm text-sm w-full"
                                          dateFormat="yyyy-MM-dd"
                                        />
                                      </div>
                                      <Field
                                        type="number"
                                        name={`entries[${index}].quantity`}
                                        className="border-gray-300 rounded-md shadow-sm text-sm"
                                      />
                                      <Field
                                        type="number"
                                        name={`entries[${index}].purchasePrice`}
                                        className="border-gray-300 rounded-md shadow-sm text-sm"
                                      />
                                      <Field
                                        type="number"
                                        name={`entries[${index}].shippingCost`}
                                        className="border-gray-300 rounded-md shadow-sm text-sm"
                                      />
                                      <Field
                                        type="number"
                                        name={`entries[${index}].otherCost`}
                                        className="border-gray-300 rounded-md shadow-sm text-sm"
                                      />
                                      <div className="text-sm">
                                        $
                                        {(
                                          (Number(entry.purchasePrice) +
                                            Number(entry.shippingCost) +
                                            Number(entry.otherCost)) /
                                            Number(entry.quantity) || 0
                                        ).toFixed(2)}
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <TrashIcon className="h-5 w-5 text-red-500 " />
                                      </button>
                                    </div>
                                  ))}
                                  <div className="flex justify-between items-center mt-4">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        push({
                                          purchaseDate: new Date(),
                                          quantity: 0,
                                          purchasePrice: 0,
                                          shippingCost: 0,
                                          otherCost: 0,
                                          cogsPerUnit: 0,
                                        })
                                      }
                                      className="bg-yellow-400 text-black p-2 rounded hover:bg-blue-700 text-sm"
                                    >
                                      +
                                    </button>
                                    <button
                                      type="submit"
                                      className="bg-indigo-600 text-white p-2 rounded hover:bg-indigo-700 text-sm"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              )}
                            </FieldArray>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
