import axios from 'axios';
import { useEffect, useState } from 'react';

import FunnelStats from '../components/marketing/FunnelStats';
import PageViewsTable from '../components/marketing/PageViewsTable';
import AgeRangeChart from '../components/members/AgeChart';
import BudgetChart from '../components/members/BudgetChart';
import CancelReasonChart from '../components/members/CancelCharts';
import CountryChart from '../components/members/CountryChart';
import NetPromoterScoreChart from '../components/members/NpsChart';
import PlatformChart from '../components/members/PlatformChart';
import ProfessionsChart from '../components/members/ProfessionsChart';
import RegistrationSourceChart from '../components/members/RegistrationStats';
import SorryChart from '../components/members/SorryChart';
import StateChart from '../components/members/StateChart';
import WhereChart from '../components/members/WhereChart';
import WhyChart from '../components/members/WhyChart';

export default function PageViews() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [packageStatus, setPackageStatus] = useState('all');
  const [data, setData] = useState()
  const url = process.env.REACT_APP_API_URL;

  const fetchData = async () => {
    const { data } = await axios.get(`${url}/api/members/form-stats`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        packageStatus: packageStatus,
      }
    });
    console.log(data)
    setData(data)
  }



  useEffect(() => {
    fetchData();
  }, [startDate, endDate, packageStatus]);
  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Pazarlama İstatistikleri
          </h1>
        </div>
        <FunnelStats />
        <div className='mt-10' style={{ marginBottom: '5px', textAlign: 'center' }}>
          <label>Başlangıç: </label>
          <input
            type="date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
          />
          <label>  Bitiş: </label>
          <input
            type="date"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
          />
          {/* Package Status Selection */}
          <select value={packageStatus} onChange={e => setPackageStatus(e.target.value)} style={{ marginLeft: '10px' }}>
            <option value="all">Tümü</option>
            <option value="active">Aktif</option>
            <option value="passive">Pasif</option>
          </select>
        </div>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              E-ticaret Platformu
            </h3>
            <PlatformChart data={data} />
          </div>
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Yaş Dağılımı
            </h3>
            <AgeRangeChart data={data} />
          </div>
        </div>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Rexven'e Neden Katıldılar?
            </h3>
            <WhyChart data={data} />
          </div>
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Rexven'i Nereden Duydular?
            </h3>
            <WhereChart data={data} />
          </div>
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              E-Ticaret Bütçesi Nedir?
            </h3>
            <BudgetChart data={data} />
          </div>

          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Rexven olmazsa ne hissedersin?
            </h3>
            <SorryChart />
          </div>
        </div>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Kullanıcıların İptal Nedenleri
            </h3>
            <CancelReasonChart />
          </div>
          <div>
            <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
              Rexven'i Tavsiye eder misiniz? (NPS)
            </h3>
            <NetPromoterScoreChart />
          </div>

        </div>
        <div>
          <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
            Ülke Bilgileri
          </h3>
          <CountryChart data={data} />
        </div>
        <div>
          <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
            Şehir Bilgileri
          </h3>
          <StateChart data={data} />
        </div>
        <div>
          <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
            Mesleğiniz Nedir?
          </h3>
          <ProfessionsChart data={data} />
        </div>
        <div>
          <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
            Hangi Sayfadan Kayıt Oldular?
          </h3>
          <RegistrationSourceChart />
        </div>

        <div className="mt-40">
          <PageViewsTable />
        </div>
      </main>
    </div>
  );
}
