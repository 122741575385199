import React, { ChangeEvent, useState } from 'react';

export interface SearchBarProps {
  onSearch: (searchValue: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [search, setSearch] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearch = () => {
    onSearch(search);
  };

  return (
    <div className='flex flex-row'>
      <input 
        type="text" 
        value={search}
        className="block w-80 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
        placeholder="İsim, mail veya telefon arayabilirsiniz ..." 
        onChange={handleInputChange} 
      />
      <div className='px-4'>
      <button 
      className="block rounded-md bg-yellow-400 py-2 px-3 text-center text-sm font-semibold text-white hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
      onClick={handleSearch}>
        Ara
      </button>
      </div>
    </div>
  );
};

export default SearchBar;
