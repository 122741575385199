import { useState, useEffect } from "react";
import axios from "axios";
import {formatDateTR} from "../../helpers/date";
import Pagination from "../general/Pagination";
import { Link } from "react-router-dom";

export interface Member {
  id: string;
  fullName: string;
  phoneNumber: string | null
  email: string;
  appointmentDate: string; 
  userId: number;
  isMember: boolean;
}

export default function ConsultancyFormDataTable() {
  const [members, setMembers] = useState<Member[]>([]);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMembers = async (): Promise<void> => {
      const response = await axios.get<{ data: { total: number; members: Member[] } }>(
        `${url}/api/data/all-consultancy-form-data`
      );
      setMembers(response.data.data.members);
    };
  
    fetchMembers();
  }, [ url]);

  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Sıra No
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İsim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Telefon
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Randevu Tarihi
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Rexven Üyesi mi?
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {members.map((member) => (
                    <tr key={member.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        {member.userId}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        {member.fullName}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {member.email}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.phoneNumber}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {formatDateTR(member.appointmentDate)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.isMember === true ? "Evet" : "Hayır" } 
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
