import { useState, useEffect, FormEvent } from "react";
import axios from "axios";

interface DistributeMembersProps {
  onDataChange: () => void;
}
export default function DistributeMembers({
  onDataChange,
}: DistributeMembersProps) {
  const [membersCount, setMembersCount] = useState<number>(0);
  const [distributing, setDistributing] = useState<boolean>(false);
  const [resetting, setResetting] = useState<boolean>(false);
  const [agents, setAgents] = useState<any[]>([]);
  const [agentCounts, setAgentCounts] = useState<{ [key: string]: number }>({});
  const [dataCounts, setDataCounts] = useState<{ [key: string]: number }>({});

  const url = process.env.REACT_APP_API_URL;
  const fetchMembersCount = async (): Promise<void> => {
    const response = await axios.get<{ data: { total: number } }>(
      `${url}/api/data/all-data`
    );
    console.log(response.data.data)
    setMembersCount(response.data.data.total);
  };
  const fetchAgents = async (): Promise<any[]> => {
    const response = await axios.get<{ data: any[] }>(
      `${url}/api/admin/get-users`
    );
    const agents = response.data.data.filter((user) =>
      user.role.includes("agent")
    );
    setAgents(agents);
    return agents;
  };

  const fetchDataCounts = async (fetchedAgents: any[]) => {
    try {
      const response = await axios.get(`${url}/api/data/data-counts`);
      const result = response.data;
  
      if (result.status === 'success') {
        const newDataCounts: { [key: string]: number } = {};
        fetchedAgents.forEach(agent => {
          newDataCounts[agent._id] = result.data[agent._id] || 0;
        });
        setDataCounts(newDataCounts);
      } else {
        console.error('Error fetching agent data counts');
      }
    } catch (error) {
      console.error('Error fetching agent data counts:', error);
    }
  };
  
  
  

  useEffect(() => {
    const fetchData = async () => {
      await fetchMembersCount();
      const fetchedAgents = await fetchAgents();
      await fetchDataCounts(fetchedAgents);
    };
  
    fetchData();
  }, [url, onDataChange]);
  

  const distributeMembers = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDistributing(true);
    try {
      await axios.post(`${url}/api/data/distribute-members`, agentCounts);
      alert("Üyeler satış temsilcilerine başarıyla dağıtıldı.");
    } catch (error) {
      console.error("Üyelerin dağıtımı sırasında hata oluştu:", error);
      alert("Üyelerin dağıtımı sırasında hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setDistributing(false);
      await onDataChange();
      setAgentCounts({});
    }
  };

  const resetDistribution = async () => {
    setResetting(true);
    try {
      await axios.post(`${url}/api/data/reset-data`);
      alert("Satış temsilcilerindeki dağıtım başarıyla sıfırlandı.");
    } catch (error) {
      console.error("Dağıtımın sıfırlanması sırasında hata oluştu:", error);
      alert(
        "Dağıtımın sıfırlanması sırasında hata oluştu. Lütfen tekrar deneyin."
      );
    } finally {
      setResetting(false);
      await onDataChange();
      setAgentCounts({});
    }
  };

  const handleAgentCountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event.target.name);

    setAgentCounts({
      ...agentCounts,
      [event.target.name]: parseInt(event.target.value, 10),
    });
  };

  return (
    <div className="bg-white p-4 rounded-md">
      <h2 className="text-2xl font-semibold mb-2">
      Genel Data Sayısı: {membersCount}
      </h2>
      <form onSubmit={distributeMembers}>
        <div className="grid grid-cols-4 gap-x-8">
          {agents.map((agent) => (
            <div key={agent._id} className="mb-4">
              <label className="text-gray-600" htmlFor={`agent_${agent._id}`}>{agent.name}</label>
              <input
                type="number"
                name={agent._id}
                id={`agent_${agent._id}`}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={handleAgentCountChange}
                value={agentCounts[agent._id] || ""}
                placeholder={`Şu anda ${dataCounts[agent._id] || 0} datası vardır.`}
              />
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="bg-yellow-400 text-black text-sm px-4 py-2 rounded-md mr-4"
          disabled={distributing}
        >
          {distributing ? "Dağıtılıyor..." : "Satış Temsilcilerine Dağıt"}
        </button>
        {/* <button
          className="bg-gray-600 text-white text-sm px-4 py-2 rounded-md"
          onClick={resetDistribution}
          disabled={resetting}
        >
          {resetting ? "Sıfırlanıyor..." : "Dağıtımı Sıfırla"}
        </button> */}
      </form>
    </div>
  );
}
