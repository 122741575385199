import axios from "axios";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDateTR } from "../../helpers/date";
import { seperateNumberWithComma } from "../../utils/numberFormatter";

export const addCurrencySymbol = (
  value: number | undefined,
  marketplaceName: string
) => {
  if (value === undefined) return "";
  let symbol = "$";
  switch (marketplaceName) {
    case "Amazon.ca":
      symbol = "CA$";
      break;
    case "Amazon.com.mx":
      symbol = "MX$";
      break;
  }
  const formattedValue = seperateNumberWithComma(
    Number(Math.abs(value).toFixed(2))
  );
  return `${value < 0 ? "-" : ""}${symbol}${formattedValue}`;
};
interface AmazonOrders {
  id?: number;
  AmazonOrderId: string;
  SellerOrderId: string;
  MarketplaceName: string;
  PostedDate: string;
  SellerSKU?: string;
  OrderItemId?: string;
  QuantityShipped?: number;
  PrincipalAmount?: number;
  TaxAmount?: number;
  GiftWrapAmount?: number;
  GiftWrapTaxAmount?: number;
  ShippingChargeAmount?: number;
  ShippingTaxAmount?: number;
  FBAPerOrderFulfillmentFeeAmount?: number;
  FBAPerUnitFulfillmentFeeAmount?: number;
  FBAWeightBasedFeeAmount?: number;
  CommissionAmount?: number;
  FixedClosingFeeAmount?: number;
  GiftwrapChargebackAmount?: number;
  ShippingChargebackAmount?: number;
  VariableClosingFeeAmount?: number;
  PromotionAmount?: number;
  MarketplaceFacilitatorTaxPrincipalAmount?: number;
  ProductCogs?: number;
  ShippingCogs?: number;
  OtherCogs?: number;
}

interface AmazonRefunds {
  id?: number;
  AmazonOrderId: string;
  SellerOrderId: string;
  MarketplaceName: string;
  PostedDate: string;
  SellerSKU?: string;
  OrderItemId?: string;
  QuantityShipped?: number;
  PrincipalAmount?: number;
  TaxAmount?: number;
  CommissionAmount?: number;
  RefundCommissionAmount?: number;
  MarketplaceFacilitatorTaxPrincipalAmount?: number;
}

interface AmazonAds {
  id?: number;
  PostedDate: string;
  TransactionValueAmount?: number;
  TransactionValueCurrency?: string;
  DailyVersionDate: string;
  DailyTransactionValueAmount?: number;
  DailyTransactionValueCurrency?: string;
}

interface AmazonFees {
  id?: number;
  AmazonOrderId: string;
  PostedDate: string;
  FBACustomerReturnPerUnitFeeValueAmount?: number | null;
  FBACustomerReturnPerUnitFeeValueCurrency?: string | null;
  FBADisposalFeeValueAmount?: number | null;
  FBADisposalFeeValueCurrency?: string | null;
  FBAInboundTransportationFeeValueAmount?: number | null;
  FBAInboundTransportationFeeValueCurrency?: string | null;
  FBAStorageFeeValueAmount?: number | null;
  FBAStorageFeeValueCurrency?: string | null;
  SubscriptionValueAmount?: number | null;
  SubscriptionValueCurrency?: string | null;
  FBARemovalFeeValueAmount?: number | null;
  FBARemovalFeeValueCurrency?: string | null;
  FBALongTermStorageFeeValueAmount?: number | null;
  FBALongTermStorageFeeValueCurrency?: string | null;
  ReversalReimbursementAdjustmentValueAmount?: number | null;
  ReversalReimbursementAdjustmentValueCurrency?: string | null;
  CompensatedClawbackAdjustmentValueAmount?: number | null;
  CompensatedClawbackAdjustmentValueCurrency?: string | null;
  FreeReplacementRefundItemsAdjustmentValueAmount?: number | null;
  FreeReplacementRefundItemsAdjustmentValueCurrency?: string | null;
  WarehouseDamageAdjustmentValueAmount?: number | null;
  WarehouseDamageAdjustmentValueCurrency?: string | null;
}

interface TotalAmountsTable {
  totalPrincipalAmount: number;
  totalTaxAmount: number;
  totalGiftWrapAmount: number;
  totalGiftWrapTaxAmount: number;
  totalShippingChargeAmount: number;
  totalShippingTaxAmount: number;
  totalFBAPerOrderFulfillmentFeeAmount: number;
  totalFBAPerUnitFulfillmentFeeAmount: number;
  totalFBAWeightBasedFeeAmount: number;
  totalCommissionAmount: number;
  totalFixedClosingFeeAmount: number;
  totalGiftwrapChargebackAmount: number;
  totalShippingChargebackAmount: number;
  totalVariableClosingFeeAmount: number;
  totalPromotionAmount: number;
  totalMarketplaceFacilitatorTaxPrincipalAmount: number;
  totalProductCogsAmount: number;
  totalShippingCogsAmount: number;
  totalOtherCogsAmount: number;
}

interface TotalRefundAmountsTable {
  totalPrincipalAmount: number;
  totalTaxAmount: number;
  totalCommissionAmount: number;
  totalRefundCommisionAmount: number;
  totalMarketplaceFacilitatorTaxPrincipalAmount: number;
}

interface TotalAdsAmountsTable {
  totalTransactionValueAmount: number;
}
interface TotalFeesAmountsTable {
  totalFBACustomerReturnPerUnitFeeValueAmount: number;
  totalFBADisposalFeeValueAmount: number;
  totalFBAInboundTransportationFeeValueAmount: number;
  totalFBAStorageFeeValueAmount: number;
  totalSubscriptionValueAmount: number;
  totalFBARemovalFeeValueAmount: number;
  totalFBALongTermStorageFeeValueAmount: number;
  totalCompensatedClawbackAdjustmentValueAmount: number;
  totalFreeReplacementRefundItemsAdjustmentValueAmount: number;
  totalWarehouseDamageAdjustmentValueAmount: number;
}

export default function AmazonOrdersTable() {
  const [marketplaceName, setMarketplaceName] = useState("Amazon.com");
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(new Date().getTime())
  );
  const [orders, setOrders] = useState<AmazonOrders[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalAmounts, setTotalAmounts] = useState<TotalAmountsTable | null>(
    null
  );
  const [refunds, setRefunds] = useState<AmazonRefunds[]>([]);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [totalRefundAmounts, setTotalRefundAmounts] =
    useState<TotalRefundAmountsTable | null>(null);
  const [ads, setAds] = useState<AmazonAds[]>([]);
  const [totalAds, setTotalAds] = useState<TotalAdsAmountsTable | null>(null);
  const [fees, setFees] = useState<AmazonFees[]>([]);
  const [totalFees, setTotalFees] = useState<TotalFeesAmountsTable | null>(
    null
  );
  const [activeTab, setActiveTab] = useState("orders"); // "orders" veya "refunds" olabilir.

  const url = process.env.REACT_APP_API_URL;
  const fetchOrders = async () => {
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? startDate.toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? endDate.toISOString().split("T")[0]
        : "";

      // Parametrelerin sıralamasını düzeltin: postedAfter < postedBefore
      const response = await axios.get(`${url}/api/amazon/amazon-orders`, {
        params: {
          postedAfter: formattedStartDate, // Başlangıç tarihi
          postedBefore: formattedEndDate, // Bitiş tarihi
          marketplaceName,
        },
      });
      setOrders(response.data.data.events);
      setTotalOrders(response.data.data.total);
    } catch (error) {
      console.error("Order fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchTotalAmounts = async () => {
    try {
      const formattedStartDate = startDate
        ? startDate.toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? endDate.toISOString().split("T")[0]
        : "";
      const response = await axios.get(`${url}/api/amazon/total-amounts`, {
        params: {
          postedAfter: formattedStartDate, // Başlangıç tarihi
          postedBefore: formattedEndDate, // Bitiş tarihi
          marketplaceName,
        },
      });
      setTotalAmounts(response.data.data);
    } catch (error) {
      console.error("Total amounts fetch failed:", error);
    }
  };

  const fetchRefunds = async () => {
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? startDate.toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? endDate.toISOString().split("T")[0]
        : "";

      // Parametrelerin sıralamasını düzeltin: postedAfter < postedBefore
      const response = await axios.get(`${url}/api/amazon/amazon-refunds`, {
        params: {
          postedAfter: formattedStartDate, // Başlangıç tarihi
          postedBefore: formattedEndDate, // Bitiş tarihi
          marketplaceName,
        },
      });
      setRefunds(response.data.data.events);
      setTotalRefunds(response.data.data.total);
    } catch (error) {
      console.error("Refund fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTotalRefundAmounts = async () => {
    try {
      const formattedStartDate = startDate
        ? startDate.toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? endDate.toISOString().split("T")[0]
        : "";
      const response = await axios.get(
        `${url}/api/amazon/total-refund-amounts`,
        {
          params: {
            postedAfter: formattedStartDate, // Başlangıç tarihi
            postedBefore: formattedEndDate, // Bitiş tarihi
            marketplaceName,
          },
        }
      );
      setTotalRefundAmounts(response.data.data);
    } catch (error) {
      console.error("Total amounts fetch failed:", error);
    }
  };

  const fetchAds = async () => {
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? startDate.toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? endDate.toISOString().split("T")[0]
        : "";

      const response = await axios.get(`${url}/api/amazon/amazon-ads`, {
        params: {
          postedAfter: formattedStartDate,
          postedBefore: formattedEndDate,
          marketplaceName,
        },
      });
      setAds(response.data.data.events);
    } catch (error) {
      console.error("Ads fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTotalAdsAmounts = async () => {
    try {
      const formattedStartDate = startDate
        ? startDate.toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? endDate.toISOString().split("T")[0]
        : "";
      const response = await axios.get(`${url}/api/amazon/total-ads`, {
        params: {
          postedAfter: formattedStartDate, // Başlangıç tarihi
          postedBefore: formattedEndDate, // Bitiş tarihi
          marketplaceName,
        },
      });
      setTotalAds(response.data.data);
    } catch (error) {
      console.error("Total amounts fetch failed:", error);
    }
  };

  const fetchFees = async () => {
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? startDate.toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? endDate.toISOString().split("T")[0]
        : "";

      // Parametrelerin sıralamasını düzeltin: postedAfter < postedBefore
      const response = await axios.get(`${url}/api/amazon/amazon-fees`, {
        params: {
          postedAfter: formattedStartDate, // Başlangıç tarihi
          postedBefore: formattedEndDate, // Bitiş tarihi
          marketplaceName,
        },
      });
      setFees(response.data.data.events);
    } catch (error) {
      console.error("Refund fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchTotalFeeAmounts = async () => {
    try {
      const formattedStartDate = startDate
        ? startDate.toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? endDate.toISOString().split("T")[0]
        : "";
      const response = await axios.get(`${url}/api/amazon/total-fees`, {
        params: {
          postedAfter: formattedStartDate, // Başlangıç tarihi
          postedBefore: formattedEndDate, // Bitiş tarihi
          marketplaceName,
        },
      });
      setTotalFees(response.data.data);
    } catch (error) {
      console.error("Total amounts fetch failed:", error);
    }
  };

  useEffect(() => {
    fetchAds();
    fetchRefunds();
    fetchOrders();
    fetchFees();
    fetchTotalAdsAmounts();
    fetchTotalAmounts();
    fetchTotalRefundAmounts();
    fetchTotalFeeAmounts();
  }, [marketplaceName]);

  // Function to add currency symbol with handling for negative values

  if (loading) return <div>Veriler Geliyor...</div>;

  return (
    <div>
      <div className="flex justify-between py-4 space-x-2">
        <div className="flex items-center text-lg font-semibold mx-4">
          <select
            value={marketplaceName}
            onChange={(e) => setMarketplaceName(e.target.value)}
            className="form-select rounded-md"
          >
            <option value="Amazon.com">Amazon.com</option>
            <option value="Amazon.ca">Amazon.ca</option>
            <option value="Amazon.com.mx">Amazon.com.mx</option>
            <option value="Non-Amazon">Non-Amazon</option>
          </select>
        </div>
        <div className="flex items-center text-lg font-semibold">
          <h2 className="text-lg font-semibold mr-2">
            Toplam Sipariş: {totalOrders}
          </h2>
          <h2>Toplam İade: {totalRefunds}</h2>
          <h2 className="text-lg font-semibold px-2">
            {" "}
            İade Oranı: {((totalRefunds / totalOrders) * 100).toFixed(2)}%
          </h2>
        </div>

        <div className="flex rounded-md space-x-2">
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="yyyy/MM/dd"
            className="form-input rounded-md w-full text-center"
          />
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            dateFormat="yyyy/MM/dd"
            className="form-input rounded-md w-full text-center"
          />
          <button
            onClick={() => {
              fetchAds();
              fetchRefunds();
              fetchOrders();
              fetchFees();
              fetchTotalAdsAmounts();
              fetchTotalAmounts();
              fetchTotalRefundAmounts();
              fetchTotalFeeAmounts();
            }}
            className="bg-yellow-400 hover:bg-blue-700 text-black font-bold py-2 px-4 rounded-md"
          >
            Filtrele
          </button>
        </div>
      </div>
      <div className="mt-4">
        {totalAmounts && (
          <div className="grid grid-cols-4 justify-between gap-2">
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Toplam Ciro:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalPrincipalAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Toplam FBA Fee:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalFBAPerUnitFulfillmentFeeAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Toplam Komisyon:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalCommissionAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Toplam Reklam:{" "}
                {addCurrencySymbol(
                  totalAds?.totalTransactionValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Total ACOS:{" "}
                {(
                  ((totalAds?.totalTransactionValueAmount || 0) /
                    totalAmounts.totalPrincipalAmount) *
                  -100
                ).toFixed(2)}{" "}
                %
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Toplam Vergi (+):{" "}
                {addCurrencySymbol(
                  totalAmounts.totalTaxAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Toplam Vergi (-):{" "}
                {addCurrencySymbol(
                  totalAmounts.totalMarketplaceFacilitatorTaxPrincipalAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Promosyon:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalPromotionAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Shipping Charge:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalShippingChargeAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Shipping Chargeback:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalShippingChargebackAmount,
                  marketplaceName
                )}
              </h3>
            </div>

            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Shipping Tax:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalShippingTaxAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Product Cogs:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalProductCogsAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Shipping Cogs:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalShippingCogsAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Other Cogs:{" "}
                {addCurrencySymbol(
                  totalAmounts.totalOtherCogsAmount,
                  marketplaceName
                )}
              </h3>
            </div>
          </div>
        )}
      </div>

      <div className="mt-8">
        {totalRefundAmounts && (
          <div className="grid grid-cols-4 justify-between gap-2">
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Toplam İade:{" "}
                {addCurrencySymbol(
                  totalRefundAmounts.totalPrincipalAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Toplam Komisyon:{" "}
                {addCurrencySymbol(
                  totalRefundAmounts.totalCommissionAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                İade Komisyonu:{" "}
                {addCurrencySymbol(
                  totalRefundAmounts.totalRefundCommisionAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                İade Vergi:{" "}
                {addCurrencySymbol(
                  totalRefundAmounts.totalTaxAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                İade Vergi:{" "}
                {addCurrencySymbol(
                  totalRefundAmounts.totalMarketplaceFacilitatorTaxPrincipalAmount,
                  marketplaceName
                )}
              </h3>
            </div>
          </div>
        )}
      </div>

      <div className="mt-8">
        {totalFees && (
          <div className="grid grid-cols-4 justify-between gap-2">
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                FBA Disposal:{" "}
                {addCurrencySymbol(
                  totalFees.totalFBADisposalFeeValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                FBA Inbound Transportation:{" "}
                {addCurrencySymbol(
                  totalFees.totalFBAInboundTransportationFeeValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                FBA Storage:{" "}
                {addCurrencySymbol(
                  totalFees.totalFBAStorageFeeValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Subscription:{" "}
                {addCurrencySymbol(
                  totalFees.totalSubscriptionValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                FBA Removal:{" "}
                {addCurrencySymbol(
                  totalFees.totalFBARemovalFeeValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Long Term Storage:{" "}
                {addCurrencySymbol(
                  totalFees.totalFBALongTermStorageFeeValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Compensated Clawback:{" "}
                {addCurrencySymbol(
                  totalFees.totalCompensatedClawbackAdjustmentValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Free Replacement Refund Items:{" "}
                {addCurrencySymbol(
                  totalFees.totalFreeReplacementRefundItemsAdjustmentValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                Warehouse Damage:{" "}
                {addCurrencySymbol(
                  totalFees.totalWarehouseDamageAdjustmentValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <h3 className="text-base font-semibold">
                FBA Customer Return Per Unit:{" "}
                {addCurrencySymbol(
                  totalFees.totalFBACustomerReturnPerUnitFeeValueAmount,
                  marketplaceName
                )}
              </h3>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end gap-2 py-4">
        <button
          className={`mr-2 ${
            activeTab === "orders"
              ? "bg-yellow-400 text-black font-semibold py-2 px-4 rounded-md "
              : "bg-yellow-100 text-black py-2 px-4 rounded-md"
          }`}
          onClick={() => setActiveTab("orders")}
        >
          Siparişler
        </button>
        <button
          className={`${
            activeTab === "refunds"
              ? "bg-yellow-400 text-black font-semibold py-2 px-4 rounded-md "
              : "bg-yellow-100 text-black py-2 px-4 rounded-md"
          }`}
          onClick={() => setActiveTab("refunds")}
        >
          İadeler
        </button>
        <button
          className={`${
            activeTab === "ads"
              ? "bg-yellow-400 text-black font-semibold py-2 px-4 rounded-md "
              : "bg-yellow-100 text-black py-2 px-4 rounded-md"
          }`}
          onClick={() => setActiveTab("ads")}
        >
          Reklamlar
        </button>
        <button
          className={`${
            activeTab === "fees"
              ? "bg-yellow-400 text-black font-semibold py-2 px-4 rounded-md "
              : "bg-yellow-100 text-black py-2 px-4 rounded-md"
          }`}
          onClick={() => setActiveTab("fees")}
        >
          Kesintiler
        </button>
      </div>
      {activeTab === "orders" && (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sipariş Numarası
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Satış Fiyatı
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  FBA ücreti
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Komisyon
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Cogs (Ürün)
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Cogs (Kargo)
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Cogs (Diğer)
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Vergi (+)
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Vergi (-)
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Pazaryeri
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sipariş Tarihi
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Ürün SKU
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Adet
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Gift Wrap Amount
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Gift Wrap Tax
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Promosyon
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Shipping Charge
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Shipping Chargeback
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Shipping Charge Tax
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Order Fulfillment Fee
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Weight Based Fee
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Fixed Closing Fee
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Gift wrap Chargeback
                </th>

                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Variable Closing Fee
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index} className="bg-white">
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.AmazonOrderId}
                  </td>

                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      order.PrincipalAmount,
                      order.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      order.FBAPerUnitFulfillmentFeeAmount,
                      order.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      order.CommissionAmount,
                      order.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      order.ProductCogs,
                      order.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      order.ShippingCogs,
                      order.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(order.OtherCogs, order.MarketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(order.TaxAmount, order.MarketplaceName)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      order.MarketplaceFacilitatorTaxPrincipalAmount,
                      order.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.MarketplaceName}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {formatDateTR(order.PostedDate)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.SellerSKU}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.QuantityShipped}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.GiftWrapAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.GiftWrapTaxAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.PromotionAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.ShippingChargeAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.ShippingChargebackAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.ShippingTaxAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.FBAPerOrderFulfillmentFeeAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.FBAWeightBasedFeeAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.FixedClosingFeeAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.GiftwrapChargebackAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {order.VariableClosingFeeAmount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {activeTab === "refunds" && (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sipariş Numarası
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Satış Fiyatı
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Vergi
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Komisyon
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  İade Komisyonu
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Vergi (-)
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Pazaryeri
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sipariş Tarihi
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Ürün SKU
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Adet
                </th>
              </tr>
            </thead>
            <tbody>
              {refunds.map((refund, index) => (
                <tr key={index} className="bg-white">
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {refund.AmazonOrderId}
                  </td>

                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      refund.PrincipalAmount,
                      refund.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      refund.TaxAmount,
                      refund.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      refund.CommissionAmount,
                      refund.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      refund.RefundCommissionAmount,
                      refund.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {addCurrencySymbol(
                      refund.MarketplaceFacilitatorTaxPrincipalAmount,
                      refund.MarketplaceName
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {refund.MarketplaceName}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {formatDateTR(refund.PostedDate)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {refund.SellerSKU}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {refund.QuantityShipped}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {activeTab === "ads" && (
        <div className="overflow-x-auto">
          <table className="divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Tarih
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Reklam Ücreti
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Para Birimi
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Günlük Reklam
                </th>
              </tr>
            </thead>
            <tbody>
              {ads.map((ad, index) => (
                <tr key={index} className="bg-white">
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {formatDateTR(ad.PostedDate)}
                  </td>

                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {ad.TransactionValueAmount}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {ad.TransactionValueCurrency}
                  </td>

                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {Number(ad.DailyTransactionValueAmount).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {activeTab === "fees" && (
        <div className="overflow-x-auto">
          <table className="divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sipariş Numarası
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Tarih
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  FBA Customer Return Per Unit
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  FBA Disposal Fee
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  FBA Inbound Transportation
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  FBA Storage
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Subscription
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  FBA Removal Fee
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  FBA LongTerm Storage Fee
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Reversal Reimbursement Adjustment
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Compensated Clawback Adjustment
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Free Replacement Refund Items Adjustment
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Warehouse Damage Adjustment
                </th>
              </tr>
            </thead>
            <tbody>
              {fees.map((fee, index) => (
                <tr key={index} className="bg-white">
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {fee.AmazonOrderId ? fee.AmazonOrderId : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    {formatDateTR(fee.PostedDate)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.FBACustomerReturnPerUnitFeeValueAmount
                      ? fee.FBACustomerReturnPerUnitFeeValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.FBADisposalFeeValueAmount
                      ? fee.FBADisposalFeeValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.FBAInboundTransportationFeeValueAmount
                      ? fee.FBAInboundTransportationFeeValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.FBAStorageFeeValueAmount
                      ? fee.FBAStorageFeeValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.SubscriptionValueAmount
                      ? fee.SubscriptionValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.FBARemovalFeeValueAmount
                      ? fee.FBARemovalFeeValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.FBALongTermStorageFeeValueAmount
                      ? fee.FBALongTermStorageFeeValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.ReversalReimbursementAdjustmentValueAmount
                      ? fee.ReversalReimbursementAdjustmentValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.CompensatedClawbackAdjustmentValueAmount
                      ? fee.CompensatedClawbackAdjustmentValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.FreeReplacementRefundItemsAdjustmentValueAmount
                      ? fee.FreeReplacementRefundItemsAdjustmentValueAmount
                      : "-"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-center text-gray-900">
                    {fee.WarehouseDamageAdjustmentValueAmount
                      ? fee.WarehouseDamageAdjustmentValueAmount
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
