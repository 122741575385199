import ActiveMembersChart from "../components/members/ActiveMembersChart";
import AverageDailyMembers from "../components/members/AverageDailyMembers";
import MemberForecast from "../components/forecast/MemberForecast";
import MemberStats from "../components/members/MemberStats";
import WeeklyRevenueChart from "../components/payment/RevenueChart";
import RevenueStats from "../components/payment/RevenueStats";
import PortfolioStats from "../components/portfolio/portfolioStats";
import MrrForecast from "../components/forecast/MrrForecast";
import ValuationForecast from "../components/forecast/ValuationForecast";
import ChurnRateStats from "../components/metrics/ChurnRates";
import LtvCacStats from "../components/metrics/LtvCac";
import DailyRevenueChart from "../components/payment/DailyChart";
import GrowthStats from "../components/metrics/MonthlyGrowth";
import RevenueGrowthStats from "../components/metrics/RevenueGrowth";
import UserMetrics from "../components/marketing/UserMetrics";

export default function Dashboard() {
  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
            Rexven Dashboard
          </h1>
        </div>
        <RevenueStats />
        <div className="mt-10">
          <WeeklyRevenueChart />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Kullanıcı Metriklerimiz
        </h1>
        <div className="py-4">
          <UserMetrics />
        </div>
        {/* <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Aylık Büyüme Oranı (Ciro)
        </h1>
        <p className="text-center">(Sadece Ekosistem gelirlerinin büyüme oranı gösterilmektedir. Hizmet sağlayıcı ve diğer gelirler dahil değildir.)</p>
        <div className="py-4">
          <RevenueGrowthStats />
        </div> */}
        <div className="mt-10">
          <DailyRevenueChart />
        </div>
        
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Rexven Üye Sayılarımız
        </h1>
        <div className="py-4">
          <MemberStats />
        </div>
        <div className="py-4">
          <PortfolioStats />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Günlük Yeni Aktif Üye Sayısı Grafiği
        </h1>
        <div className="py-4">
          <ActiveMembersChart />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Günlük Aktif Yeni Üye Artış Sayısı
        </h1>
        <div className="py-4">
          <AverageDailyMembers />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Aylık Büyüme Oranı (Aktif Üye Sayısı)
        </h1>
        <div className="py-4">
          <GrowthStats />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Churn Rate
        </h1>
        <p className="text-center">(O ay üyeliğe devam etmeyen kullanıcı sayısı / Ay başındaki aktif kullanıcı sayısı)</p>
        <div className="py-4">
          <ChurnRateStats />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          LTV - ARPU
        </h1>
        <p className="text-center">(LTV: Life time value, ARPU: Average revenue per user)</p>
        <div className="py-4">
          <LtvCacStats />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Aktif Üye Sayısı
        </h1>
        <div className="py-4">
          <MemberForecast />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          MRR - ARR (2023 - 2030)
        </h1>
        <div className="py-4">
          <MrrForecast />
        </div>
        <h1 className="text-4xl text-center mt-4 font-bold tracking-tight text-gray-900">
          Rexven Değerlemesi
        </h1>
        <div className="py-4">
          <ValuationForecast />
        </div>
      </main>
    </div>
  );
}
