import { useState } from "react";
import httpClient from "../utils/httpClient";
import { useQuery } from "@tanstack/react-query";
import InventorySummary from "../components/amazon/InventorySummary";
import AmazonInventoryTable from "../components/amazon/InventoryTable";
import NestedDropDown from "../components/amazon/NestedDropDown";

export default function AmazonInventoryDashboard() {
  const [marketplaceName, setMarketplaceName] = useState<any>();

  const {
    isLoading: inventoryStatsLoading,
    error,
    data: inventoryStats,
  } = useQuery<any, Error>({
    queryKey: ["inventoryStats", marketplaceName],
    queryFn: () =>
      httpClient.get(
        `/api/amazon/inventory?marketplace=${
          marketplaceName.marketplace
        }&amazonStoreId=${marketplaceName?.storeId || ""}`
      ),
    staleTime: Infinity,
  });

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Inventory Dashboard
          </h1>
        </div>
        <div className="w-full flex justify-between py-4">
          {/* <select
            value={marketplaceName}
            onChange={(e) => setMarketplaceName(e.target.value)}
            className="border-[#6F7AC3] rounded-md text-slate-600 "
          >
            <option value="Amazon.com">Amazon.com</option>
            <option value="Amazon.ca">Amazon.ca</option>
            <option value="Amazon.com.mx">Amazon.com.mx</option>
            <option value="Non-Amazon">Non-Amazon</option>
          </select> */}
          <NestedDropDown
            marketplaceName={marketplaceName}
            setMarketplaceName={setMarketplaceName}
          />
        </div>
        <InventorySummary
          data={inventoryStats}
          isLoading={inventoryStatsLoading}
        />
        <div>
          {inventoryStatsLoading ? (
            <p></p>
          ) : inventoryStats.data.status === "success" ? (
            <AmazonInventoryTable
              marketplaceName={marketplaceName.marketplace}
              inventoryStats={inventoryStats.data?.data}
            />
          ) : (
            <p>Hata</p>
          )}
        </div>
      </main>
    </div>
  );
}
