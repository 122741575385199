import { useState, useEffect } from "react";
import axios from "axios";
import { formatDate } from "../../helpers/date";
import Pagination from "../general/Pagination";
import { Link } from "react-router-dom";
import { useAuth, AuthContextValue } from "../../context/AuthContext";
import { Member } from "./portfolioMembers";
import SearchBar from "../general/SearchBar";

export default function PortfolioByUser() {
  const [members, setMembers] = useState<Member[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(300);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  const [search, setSearch] = useState("");

  const { user } = useAuth() as AuthContextValue;
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (user) {
      const fetchClients = async (): Promise<void> => {
        const response = await axios.get<{
          data: { total: number; clients: Member[] };
        }>(
          `${url}/api/portfolio/${user.id}/clients?page=${page}&limit=${limit}`
        );
        setMembers(response.data.data.clients);
        setTotalPageCount(Math.ceil(response.data.data.total / limit));
      };

      fetchClients();
    }
  }, [user, page, limit, url]);

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();

    setFilteredMembers(
      members.filter(
        (member) =>
          member.firstName.toLowerCase().includes(lowercasedSearch) ||
          member.lastName.toLowerCase().includes(lowercasedSearch) ||
          member.email.toLowerCase().includes(lowercasedSearch)||
          member.gsmNumber?.toLowerCase().includes(lowercasedSearch)||
          member.billingAddresses[0]?.gsmNumber.toLowerCase().includes(lowercasedSearch)
      )
    );
  }, [search, members]);

  const onSearch = (searchValue: any) => {
    setSearch(searchValue);
  };
  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <SearchBar onSearch={onSearch} />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Sıra No
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İsim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Soyisim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Katılış Tarihi
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Telefon
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Aktif mi?
                    </th>

                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Ay/Yıl
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Geçirdiği Süre
                    </th>
                    <th
                      scope="col"
                      className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Detay</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {filteredMembers.map((member) => (
                    <tr key={member.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        {member.id}
                      </td>
                      <td className="px-2 py-2 text-sm font-medium text-gray-900">
                      <Link
                          to={`/members/${member.id}`}
                          className="text-red-600 hover:text-red-900"
                        >
                          {member.firstName}
                          <span className="sr-only">, {member.id}</span>
                        </Link>
                        
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {member.lastName}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.email}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {formatDate(member.createdAt)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.gsmNumber
                          ? member.gsmNumber
                          : member.billingAddresses[0].gsmNumber
                          ? member.billingAddresses[0].gsmNumber
                          : " - "}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userPackages && member.userPackages.length > 0
                          ? member.userPackages[member.userPackages.length - 1].status === "active"
                            ? "Aktif"
                            : "Pasif"
                          : "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userPackages.length > 0 &&
                        member.userPackages[0].packagePlan
                          ? member.userPackages[0].packagePlan.duration === 1
                            ? "Aylık"
                            : member.userPackages[0].packagePlan.duration === 12
                            ? "Yıllık"
                            : member.userPackages[0].packagePlan.duration
                          : "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {member.userTracks[0]?.totalTimes} dakika
                      </td>

                      {/* <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <Link
                          to={`/members/${member.id}`}
                          className="text-red-600 hover:text-red-900"
                        >
                          Detay
                          <span className="sr-only">, {member.id}</span>
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          totalPageCount={totalPageCount}
        />
      </div>
    </div>
  );
}
