import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import Header from "./header";
import Sidebar from "./sidebar";
import { Link, useLocation } from "react-router-dom";
import {
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ChevronRightIcon,
  ListBulletIcon,
  EnvelopeIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

function classNames(...classes: String[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props {
  children?: React.ReactNode;
}

export interface NavigationItem {
  name: string;
  href: string;
  icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
  current: boolean;
  children?: any[];
}

const MasterLayout: React.FC<Props> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [current, setCurrent] = useState("/dashboard");
  const [portfolioSystemOpen, setPortfolioSystemOpen] = useState(true);
  const location = useLocation();
  const navigation: NavigationItem[] = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: false,
    },
    // {
    //   name: "Finans",
    //   href: "/finance",
    //   icon: CurrencyDollarIcon,
    //   current: false,
    // },
    {
      name: "Ödemeler",
      href: "/payments",
      icon: FolderIcon,
      current: false,
    },
    {
      name: "Satış",
      href: "",
      icon: CalendarIcon,
      current: false,
      children: [
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Bütün Portföy </span>
            </p>
          ),
          href: "/portfolio",
          icon: ChevronRightIcon,
          current: false,
        },
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Portföy Takibi </span>
            </p>
          ),
          href: "/users-portfolio-stats",
          icon: ChevronRightIcon,
          current: false,
        },
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Benim Portföyüm </span>
            </p>
          ),
          href: "/my-portfolio",
          icon: ChevronRightIcon,
          current: false,
        },
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Data Dağıtım </span>
            </p>
          ),
          href: "/all-data",
          icon: ChevronRightIcon,
          current: false,
        },
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Benim Datam </span>
            </p>
          ),
          href: "/my-data",
          icon: ChevronRightIcon,
          current: false,
        },
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Randevularım </span>
            </p>
          ),
          href: "/my-appointments",
          icon: ChevronRightIcon,
          current: false,
        },
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Data Analizi </span>
            </p>
          ),
          href: "/data-analysis",
          icon: ChevronRightIcon,
          current: false,
        },
      ],
    },
    {
      name: "Destek",
      href: "/support",
      icon: ListBulletIcon,
      current: false,
    },
    {
      name: "Pazarlama",
      href: "/page-views",
      icon: EnvelopeIcon,
      current: false,
    },
    {
      name: "Kullanıcılarımız",
      href: "/all-members",
      icon: UsersIcon,
      current: false,
    },
    {
      name: "Amazon",
      href: "",
      icon: CurrencyDollarIcon,
      current: false,
      children: [
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Kârlılık Paneli </span>
            </p>
          ),
          href: "/profit-dashboard",
          icon: ChevronRightIcon,
          current: false,
        },
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Envanter Paneli </span>
            </p>
          ),
          href: "/amazon-inventory",
          icon: ChevronRightIcon,
          current: false,
        },
        {
          name: (
            <p className="w-full flex justify-between items-center ">
              <span className="block text-xs"> Entegrasyon </span>
            </p>
          ),
          href: "/integration",
          icon: ChevronRightIcon,
          current: false,
        },
      ],
    },
  ];

  const subNavigation: NavigationItem[] = [
    {
      name: "Bütün Portföy",
      href: "/portfolio",
      icon: ChevronRightIcon,
      current: false,
    },
    {
      name: "Portföy Takibi",
      href: "/users-portfolio-stats",
      icon: ChevronRightIcon,
      current: false,
    },
    {
      name: "Benim Portföyüm",
      href: "/my-portfolio",
      icon: ChevronRightIcon,
      current: false,
    },
    {
      name: "Data Dağıtım",
      href: "/all-data",
      icon: ChevronRightIcon,
      current: false,
    },
    {
      name: "Benim Datam",
      href: "/my-data",
      icon: ChevronRightIcon,
      current: false,
    },
    {
      name: "Randevularım",
      href: "/my-appointments",
      icon: ChevronRightIcon,
      current: false,
    },
    {
      name: "Data Analizi",
      href: "/data-analysis",
      icon: ChevronRightIcon,
      current: false,
    },
  ];
  return (
    <>
      <div>
        {/* Mobile sidebar for desktop */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900 bg-opacity-95 w-[300px]" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-primary pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 border-b-[1px] pb-5 border-secondary items-center px-4">
                    <img
                      className="h-14 w-auto"
                      src="/assets/img/logo.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="mt-10  h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {navigation.map((tool) =>
                        !tool?.children ? (
                          <Link
                            key={tool.name}
                            to={tool.href}
                            // onClick={() => handleClick(tool.href)}
                            className={classNames(
                              location.pathname === tool.href
                                ? "bg-secondary text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            )}
                          >
                            <tool.icon
                              className={classNames(
                                location.pathname === tool.href
                                  ? "text-gray-300"
                                  : "text-gray-400 group-hover:text-gray-300",
                                "mr-3 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {tool.name}
                          </Link>
                        ) : (
                          <Disclosure
                            as="div"
                            key={tool.name}
                            className="space-y-1"
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    "text-gray-300 hover:bg-gray-700 hover:text-white",
                                    "group flex w-full items-center rounded-md px-4 py-2 pr-2 text-left text-sm font-medium focus:outline-none focus:ring-2"
                                  )}
                                >
                                  <svg
                                    className={classNames(
                                      open
                                        ? "rotate-90 text-gray-400"
                                        : "text-gray-300",
                                      "mr-2 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                                    )}
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M6 6L14 10L6 14V6Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                  {tool.name}
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-1">
                                  {tool?.children?.map((subItem) => (
                                    <Link
                                      key={subItem.name}
                                      // onClick={() => setCurrent(subItem.href)}
                                      to={subItem.href}
                                      className={classNames(
                                        location.pathname.includes(subItem.href)
                                          ? "bg-secondary text-white"
                                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                        "group  w-full flex items-center px-10 py-2 text-sm font-medium rounded-md"
                                      )}
                                    >
                                      -{subItem.name}
                                    </Link>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )
                      )}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <Sidebar
          setCurrent={setCurrent}
          current={current}
          navigation={navigation}
          subNavigation={subNavigation}
          portfolioSystemOpen={portfolioSystemOpen}
        />

        <div className="flex flex-col lg:pl-60">
          <Header setSidebarOpen={setSidebarOpen} />
          <div className="min-h-screen">{children}</div>
        </div>
      </div>
    </>
  );
};
export default MasterLayout;
