import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { ApexOptions } from 'apexcharts';

interface ReasonStatistic {
  [reason: string]: number;
}

const CancelReasonChart = () => {
  const [chartData, setChartData] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      height: 550,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      title: {
        text: 'İptal Sayısı',
      },
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    series: [{
      name: 'İptal Sayısı',
      data: [],
    }],
  });

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get<{status: string, data: { reasonStats: ReasonStatistic }}>(`${url}/api/members/cancel-reasons`);
      const reasonStats = data.data.reasonStats;

      setChartData(prevChartData => ({
        ...prevChartData,
        xaxis: {
          ...prevChartData.xaxis,
          categories: Object.keys(reasonStats),
        },
        series: [{
          name: 'İptal Sayısı',
          data: Object.values(reasonStats),
        }],
      }));
    };

    fetchData();
  }, []);

  return (
    <Chart options={chartData} series={chartData.series} type="bar" height={550} />
  );
};

export default CancelReasonChart;
