import { useState, useEffect } from "react";
import axios from "axios";
import { formatDate, formatDateTR } from "../../helpers/date";
import { Link } from "react-router-dom";
import { useAuth, AuthContextValue } from "../../context/AuthContext";
import SearchBar from "../general/SearchBar";

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  gsmNumber: string;
  userLabel: UserLabel;
  userTracks: UserTracks[];
}
export interface UserTracks {
  totalTimes: string;
  page: string;
}

export interface UserLabel {
  description: string | null;
  label: string | null;
}

export interface Appointment {
  id: number;
  description: string | null;
  label: string | null;
  appointment: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  user: User;
}

export default function AppointmentsByUser() {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [filteredAppointments, setFilteredAppointments] = useState<
    Appointment[]
  >([]);
  const [search, setSearch] = useState("");

  const { user } = useAuth() as AuthContextValue;

  const url = process.env.REACT_APP_API_URL;

  const fetchAppointments = async (): Promise<void> => {
    const response = await axios.get<{ data: Appointment[] }>(
      `${url}/api/labels/get-all-appointments/${user?.id}`
    );
    setAppointments(response.data.data);
  };

  useEffect(() => {
    fetchAppointments();
  }, [user]);

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();

    setFilteredAppointments(
      appointments.filter(
        (appointment) =>
          appointment.user?.firstName
            .toLowerCase()
            .includes(lowercasedSearch) ||
          appointment.user?.lastName.toLowerCase().includes(lowercasedSearch) ||
          appointment.user?.email.toLowerCase().includes(lowercasedSearch) ||
          appointment.user?.gsmNumber
            ?.toLowerCase()
            .includes(lowercasedSearch) ||
          appointment.label
            ?.toLowerCase()
            .includes(lowercasedSearch)
      )
    );
  }, [search, appointments]);

  const onSearch = (searchValue: any) => {
    setSearch(searchValue);
  };

  return (
    <div className="bg-white">
      <div className="px-4 py-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <SearchBar onSearch={onSearch} />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Sıra No
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İsim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Soyisim
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Telefon
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Geçirdiği Süre
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Randevu Tarihi
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Sonuç Kodları
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Açıklama
                    </th>

                    <th
                      scope="col"
                      className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Detay</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {filteredAppointments
                    .filter((appointment) => appointment.appointment !== null) // Show only appointments with an appointment date
                    .map((appointment) => {
                      return (
                        <tr key={appointment.id}>
                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                            {appointment.user?.id}
                          </td>
                          <td className="px-2 py-2 text-sm font-medium text-gray-900">
                            <Link
                              to={`/members/${appointment.user?.id}`}
                              className="text-red-600 hover:text-red-900"
                            >
                              {appointment.user?.firstName}
                              <span className="sr-only">
                                , {appointment.user?.id}
                              </span>
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {appointment.user?.lastName}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {appointment.user?.email}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {appointment.user?.gsmNumber}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {appointment.user?.userTracks[0] ? appointment.user?.userTracks[0].totalTimes: "0"} dakika
                          </td>
                          <td className="px-2 py-2 text-sm font-medium text-red-600">
                            {formatDateTR(appointment.appointment)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {appointment.label ? appointment.label : " --- "}
                          </td>
                          <td
                            className="whitespace-normal px-2 py-2 text-sm text-gray-900 overflow-hidden max-w-sm"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 3,
                              overflow: "hidden",
                            }}
                          >
                            {appointment.description
                              ? appointment.description
                              : " --- "}
                          </td>

                          <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <Link
                              to={`/members/${appointment.user?.id}`}
                              className="text-red-600 hover:text-red-900"
                            >
                              Detay
                              <span className="sr-only">
                                , {appointment.user?.id}
                              </span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
