import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { AuthContext, AuthContextValue } from "../context/AuthContext";
import Notification from "../components/general/Notification";
import { Link } from "react-router-dom";

interface Credentials {
  email: string;
  password: string;
}

export default function Login() {
  const { login, forgotPassword } =
    useContext(AuthContext) ?? ({} as AuthContextValue);
  const [credentials, setCredentials] = useState<Credentials>({
    email: "",
    password: "",
  });
  const [showNotification, setShowNotification] = useState(false); // Notification'ı göstermek için bir state tanımlayın
  const [errorMessage, setErrorMessage] = useState(""); // Hata mesajını saklamak için bir state tanımlayın

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await login(credentials);
    } catch (error: any) {
      console.log("hata oluştu!");
      if (error.response && error.response.status === 400) {
        setErrorMessage("E-posta veya şifre hatalı."); // Hata mesajını ayarlayın
      } else {
        setErrorMessage("Bir hata meydana geldi."); // Genel hata mesajı
      }
      setShowNotification(true); // Notification'ı gösterin
    }
  };

  const handleForgotPassword = async () => {
    if (!credentials.email) {
      alert("Lütfen e-posta adresinizi girin");
      return;
    }
    try {
      await forgotPassword(credentials.email);
      alert("Şifre sıfırlama e-postası gönderildi");
    } catch (error) {
      console.log("Şifre sıfırlama isteği başarısız oldu");
    }
  };

  return (
    <>
      <Notification // Notification bileşenini ekleyin
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        title="Hata"
        message={errorMessage}
      />

      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/assets/img/logo.png"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Rexven dünyasına hoşgeldin!
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600"></p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email adresi
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={handleChange}
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Şifre
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={handleChange}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Beni hatırla
                  </label>
                </div>

                <div className="text-sm">
                  <button
                    type="button"
                    onClick={handleForgotPassword}
                    className="font-medium text-red-600 hover:text-red-500"
                  >
                    Şifremi unuttum?
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-yellow-400 text-black py-2 px-4 text-sm font-medium shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Giriş Yap
                </button>
              </div>
            </form>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Henüz üye değil misiniz?
                  </span>
                </div>
              </div>
              <div className="mt-6">
                <Link
                  className="flex w-full justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                  to="/register"
                >
                  {" "}
                  Kayıt Ol
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
