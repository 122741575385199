import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ResetPassword from './components/auth/ResetPassword';
import MemberDetails from './components/members/MemberDetails';
import { AuthProvider } from './context/AuthContext';
import AllMembers from './pages/AllMembers';
import AmazonDashboardPage from './pages/AmazonDashboard';
import AmazonInventoryDashboard from './pages/AmazonInventory';
import Dashboard from './pages/Dashboard';
import Data from './pages/Data';
import DataAnalysisPage from './pages/DataAnalysis';
import Error from './pages/Error';
import Finance from './pages/Finance';
import FinanceRecords from './pages/financeRecord';
import HabitPage from './pages/Habit';
import Home from './pages/Home';
import IntegrationPage from './pages/Integration';
import Login from './pages/Login';
import PageViews from './pages/Marketing';
import MessagePage from './pages/Message';
import MyAppointments from './pages/MyAppointments';
import MyData from './pages/MyData';
import MyPortfolio from './pages/MyPortfolio';
import Payments from './pages/Payments';
import Portfolio from './pages/Portfolio';
import Profile from './pages/Profile';
import AmazonProfitDashboard from './pages/ProfitDashboard';
import Register from './pages/Register';
import Support from './pages/Support';
import Todo from './pages/Todo';
import UsersPortfolioStats from './pages/UsersPortfolioStats';
import AuthRouter from './routes/AuthRouter';
import UserRouter from './routes/UserRouter';

// Since your component doesn't have any specific props, you can use an empty interface
interface AppProps { }
const queryClient = new QueryClient();
const App: FC<AppProps> = () => {
  return (
    <div>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route element={<AuthRouter />}>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/reset-password/:resetToken"
                element={<ResetPassword />}
              />
            </Route>
            <Route path="*" element={<Error />} />

            <Route element={<UserRouter />}>
              <Route path="/dashboard" index element={<Dashboard />} />
              <Route index element={<Navigate to="/dashboard" />} />
              <Route path="/all-members" element={<AllMembers />} />
              <Route path="/my-profile" element={<Profile />} />
              <Route path="/members/:id" element={<MemberDetails />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/my-portfolio" element={<MyPortfolio />} />
              <Route
                path="/users-portfolio-stats"
                element={<UsersPortfolioStats />}
              />
              <Route path="/all-data" element={<Data />} />
              <Route path="/my-data" element={<MyData />} />
              <Route path="/support" element={<Support />} />
              <Route path="/my-todos" element={<Todo />} />
              <Route path="/my-habits" element={<HabitPage />} />
              <Route path="/page-views" element={<PageViews />} />
              <Route path="/data-analysis" element={<DataAnalysisPage />} />
              <Route path="/my-appointments" element={<MyAppointments />} />
              <Route path="/finance-records" element={<FinanceRecords />} />
              <Route path="/finance" element={<Finance />} />
              <Route path="/messages" element={<MessagePage />} />
              <Route path="/amazon-orders" element={<AmazonDashboardPage />} />
              <Route path="/integration" element={<IntegrationPage />} />
              <Route path="/amazon-inventory" element={<AmazonInventoryDashboard />} />
              <Route
                path="/profit-dashboard"
                element={<AmazonProfitDashboard />}
              />
            </Route>
          </Routes>
        </QueryClientProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
