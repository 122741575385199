import TodoList from "../components/todo/TodoList";

export default function Todo() {
  
  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Yapacaklarım
          </h1>
        </div>
        <div className="py-4">
          <TodoList />
        </div>
      </main>
    </div>
  );
}
