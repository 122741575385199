interface AmazonInventoryTableProps {
  marketplaceName: string;
  inventoryStats: any;
}

export default function AmazonInventoryTable({
  marketplaceName,
  inventoryStats,
}: AmazonInventoryTableProps) {

  return (
    <div>
      <div className="flex flex-col flex-shrink-0 bg-white rounded-xl my-8">
        {/* <div className="flow-root"> */}
        <div className="relative overflow-x-auto overflow-y-auto">
          <div className="inline-block min-w-full align-middle"></div>
          <table className="min-w-full leading-normal">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg py-3.5 pl-4 pr-3 text-left text-xs font-bold tracking-wider"
                >
                  Ürün Başlığı - ASIN - SKU
                </th>

                <th
                  scope="col"
                  className="px-2 py-3 text-left  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg text-xs font-medium  tracking-wider"
                >
                  Total Units
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Fulfillable
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  İnbound
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Reserved
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Researching
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Unfulfillable
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Future
                </th>

                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Stok Değeri
                </th>
              </tr>
            </thead>
            <tbody>
              {inventoryStats.inventory?.map((sku: any, index: any) => {
                return (
                  <tr key={index} className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {sku.productName.length > 60
                        ? `${sku.productName.substring(
                            0,
                            40
                          )}...${sku.productName.substring(
                            sku.productName.length - 30
                          )}`
                        : sku.productName}
                      <p>
                        ASIN: {sku.asin} - SKU: {sku.sellerSku}{" "}
                      </p>
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {sku.totalQuantity}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {sku.fulfillableQuantity}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {sku.inboundWorkingQuantity +
                        sku.inboundShippedQuantity +
                        sku.inboundReceivingQuantity}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {sku.totalReservedQuantity}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {sku.totalResearchingQuantity}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {sku.totalUnfulfillableQuantity}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {sku.reservedFutureSupplyQuantity +
                        sku.futureSupplyBuyableQuantity}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      ${sku.stockValuePerUnit.toFixed(2)} ({sku.cogsPerUnit} *{" "}
                      {sku.totalQuantity})
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
