import { useState, ChangeEvent, FormEvent } from "react";
import { Link, useParams } from "react-router-dom";
import authService from "../../services/authService";
import Notification from '../../components/general/Notification';

interface ResetPasswordValues {
  newPassword: string;
  confirmPassword: string;
}

export default function ResetPassword() {
  const [values, setValues] = useState<ResetPasswordValues>({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const { resetToken } = useParams<{ resetToken: string }>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!resetToken) {
        console.log("Reset token bulunamadı.");
        return;
      }

    if (values.newPassword !== values.confirmPassword) {
      console.log("Şifreler eşleşmiyor!");
      return;
    }

    try {
        await authService.resetPassword(resetToken, values.newPassword);
        console.log("Şifre başarıyla sıfırlandı!");
        handlePasswordResetSuccess();
      } catch (error) {
        console.log("Hata oluştu!");
      }
  };

  const handlePasswordResetSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 15000); // burada 15 saniye kullanıldı.
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/assets/img/logo.png"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Türkiye'nin ilk online marka pazar yeri
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600"></p>
        </div>
  
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              {showSuccess ? (
                <div className="text-center text-green-500">
                  Şifreniz başarıyla güncellendi.{" "}
                  <Link to="/login" className="font-bold text-red-600">
                    Giriş Yap
                  </Link>
                </div>
              ) : (
                <>
                  <div>
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Yeni Şifre
                    </label>
                    <div className="mt-1">
                      <input
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        onChange={handleChange}
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                      />
                    </div>
                  </div>
  
                  <div>
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Şifreyi Onayla
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        onChange={handleChange}
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                      />
                    </div>
                  </div>
  
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-yellow-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      Şifreyi Yenile
                    </button>
                  </div>
                </>
              )}
  
              {error && (
                <div className="text-center text-red-500">{error}</div>
              )}
            </form>
          </div>
        </div>
      </div>
      <Notification
        showNotification={showSuccess}
        setShowNotification={setShowSuccess}
        title="Şifre Başarıyla Yenilendi!"
        message="Şifreniz başarıyla güncellendi. Şimdi giriş yapabilirsiniz."
      />
    </>
  );
  
}
