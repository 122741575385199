import API_URL from "../config";
import httpClient from "../utils/httpClient";
import { RegisterValues, LoginValues } from "../context/AuthContext";

class AuthService {
  // register user
  registerUser(values: RegisterValues) {
    return httpClient.post(`${API_URL}/api/auth/register`, values);
  }

  // login user
  loginUser(values: LoginValues) {
    return httpClient.post(`${API_URL}/api/auth/login`, values);
  }

  // check user's auth status
  checkUserAuth() {
    return httpClient.get(`${API_URL}/api/auth/checkauth`);
  }

  // logout user
  logoutUser() {
    return httpClient.get(`${API_URL}/api/auth/logout`);
  }
  // forgot password
  forgotPassword(email: string) {
    return httpClient.post(`${API_URL}/api/auth/forgot-password`, { email });
  }

  // reset password
  resetPassword(resetToken: string, newPassword: string) {
    return httpClient.post(`${API_URL}/api/auth/reset-password`, {
      resetToken,
      newPassword,
    });
  }
}

export default new AuthService();
