import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { ApexOptions } from 'apexcharts';

interface ScoreCount {
  [score: string]: number;
}

const NetPromoterScoreChart = () => {
  const [chartData, setChartData] = useState<ApexOptions>({
    chart: {
      type: 'bar',
      height: 550,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      title: {
        text: '10 - Kesinlikle tavsiye ederim, 1 - Hiç tavsiye etmem',
      },
    },
    yaxis: {
      title: {
        text: 'Net Promoter Score',
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          y2: 0,
          borderColor: '#000',
          fillColor: '#FEB019',
          label: {
            text: '',
            style: {
              color: '#fff',
              background: '#00E396',
            },
          },
        },
      ],
    },
    series: [{
      name: 'Kullanıcı Sayısı',
      data: [],
    }],
  });

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get<{status: string, data: { scoreCount: ScoreCount, NPS: number }}>(`${url}/api/members/nps`);
      const { scoreCount, NPS } = data.data;

      setChartData(prevChartData => ({
        ...prevChartData,
        xaxis: {
          ...prevChartData.xaxis,
          categories: Object.keys(scoreCount),
        },
        annotations: {
          yaxis: [
            {
              y: NPS,
              borderColor: '#000',
              fillColor: '#FEB019',
              label: {
                text: `NPS: ${NPS.toFixed(2)}`,
                style: {
                  color: '#000000',
                  background: '#ffff00',
                  fontSize: '16px',
                },
              },
            },
          ],
        },
        series: [{
          name: 'Kullanıcı Sayısı',
          data: Object.values(scoreCount),
        }],
      }));
    };

    fetchData();
  }, []);

  return (
    <Chart options={chartData} series={chartData.series} type="bar" height={550} />
  );
};

export default NetPromoterScoreChart;
