import PortfolioByUser from "../components/portfolio/portfolioByUser";
import PortfolioStatsByUser from "../components/portfolio/portfolioStatsByUser";


export default function MyPortfolio() {

  return (
    <div className="bg-white">
      <main className="mx-auto w-full px-4 mt-4 sm:px-6 lg:w-full lg:px-10">
        <div className="border-b border-gray-200 pb-10">
          <h1 className="text-4xl text-center font-bold tracking-tight text-gray-900">
            Benim Portföyüm
          </h1>
        </div>
        <div className="py-4"><PortfolioStatsByUser /></div>
        <PortfolioByUser />
      </main>
    </div>
  );
}