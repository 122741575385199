import axios from "axios";
import { useEffect, useState } from "react";
import { CostInputModal } from "./CogsModal";
import { PencilIcon } from "@heroicons/react/24/outline";
import httpClient from "../../utils/httpClient";
import { addCurrencySymbol } from "./AmazonOrders";

interface CogsData {
  id?: string;
  sellerSku: string;
  purchaseDate: string;
  quantity: number;
  purchasePrice: number;
  shippingCost: number;
  otherCost: number;
  cogsPerUnit?: number;
}

interface AmazonProfitTableProps {
  marketplaceName: any;
  selectedDate: {
    startDate: string;
    endDate: string;
  };
  // setProductOptions: React.Dispatch<any>;
  // setProductOptionsBase: React.Dispatch<any>;
  selectedSku: string;
}

export default function SkuStatsTable({
  marketplaceName,
  selectedDate,
  // setProductOptions,
  // setProductOptionsBase,
  selectedSku,
}: AmazonProfitTableProps) {
  const [skuData, setSkuData] = useState<any[]>([]);
  const [cogsData, setCogsData] = useState<any[]>([]);
  const [totalOrder, setTotalOrder] = useState<number>();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSku, setCurrentSku] = useState("");
  const [selectedCogsData, setSelectedCogsData] = useState<CogsData[]>([]);

  const url = process.env.REACT_APP_API_URL;

  const handleOpenModal = (sku: any) => {
    const cogsForSku = cogsData.filter((cogs) => cogs.sellerSku === sku);
    const totalcount = skuData?.find((item: any) => {
      return item.sellerSku === sku;
    }).totalOrderCount;
    setTotalOrder(totalcount);
    setSelectedCogsData(cogsForSku);
    setCurrentSku(sku);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleSaveCogs = async (cogsDataArray: CogsData[]) => {
    try {
      // `cogsData` array'ini ve `sellerSku`'yu birlikte gönder
      await httpClient.post(`${url}/api/amazon/create-cogs`, {
        cogsData: cogsDataArray.map((cogs) => ({
          ...cogs,
          sellerSku: currentSku, 
          amazonStoreId: marketplaceName.storeId,
        })),
      });

      alert("COGS data successfully saved.");
      handleCloseModal();
      // Başarılı kayıttan sonra COGS verilerini yeniden çekmek için getAllCogs fonksiyonunu çağırabilirsiniz.
      getAllCogs();
    } catch (error) {
      console.error("Error saving COGS data:", error);
      alert("Failed to save COGS data.");
    }
  };

  const getAllCogs = async () => {
    try {
      const res = await httpClient.get("/api/amazon/all-cogs", {
        params: {
          amazonStoreId: marketplaceName.storeId,
          },
          });
      setCogsData(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await httpClient.get(
          `/api/amazon/sku-stats?marketplace=${marketplaceName.marketplace}&postedAfter=${selectedDate.startDate}&postedBefore=${selectedDate.endDate}&sellerSku=${selectedSku}&amazonStoreId=${marketplaceName.storeId}`
        );

        const tempData = res.data.data.skuRevenueData;
        const tempRefund = res.data.data.skuRefundData;
        const result = tempData.map((item: any) => {
          const refundItem = tempRefund.find(
            (refund: any) => refund.sellerSku === item.sellerSku
          );
          if (refundItem) {
            return {
              ...item,
              totalRefunds: refundItem.totalRefunds,
              totalRefundCommission: refundItem.totalRefundCommission,
              refundCount: refundItem.refundCount,
            };
          } else {
            return {
              ...item,
              totalRefunds: 0,
              totalRefundCommission: 0,
              refundCount: 0,
            };
          }
        });

        setSkuData(result);
      } catch (error) {
        console.error("Fetch data failed:", error);
      }
    };

    fetchData();
    getAllCogs();
  }, [
    marketplaceName,
    selectedDate.startDate,
    selectedDate.endDate,
    selectedSku,
  ]);

  return (
    <div className="py-4 shadow-xl mb-8 rounded-xl">
      <CostInputModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        onSave={handleSaveCogs}
        sellerSku={currentSku}
        cogsData={selectedCogsData}
        totalCount={totalOrder!}
      />
      <div className="relative overflow-x-auto overflow-y-auto">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full leading-normal">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg py-3.5 pl-4 pr-3 text-left text-xs font-bold tracking-wider"
                >
                  Ürün Başlığı - ASIN - SKU
                </th>

                <th
                  scope="col"
                  className="px-2 py-3 text-left  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg text-xs font-medium  tracking-wider"
                >
                  Ciro
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Sipariş
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  İade
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  İade Oranı
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  İade Maliyeti
                </th>

                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Amazon Kesintileri
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Ürün Maliyeti
                </th>

                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium  border-b-2 bg-[#1F254B] text-white first:rounded-tl-lg last:rounded-tr-lg tracking-wider"
                >
                  Net Kâr
                </th>
              </tr>
            </thead>
            <tbody>
              {skuData?.map((order, index) => {
                return (
                  <tr key={index} className="bg-white">
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {order.productName.length > 60
                        ? `${order.productName.substring(
                            0,
                            40
                          )}...${order.productName.substring(
                            order.productName.length - 30
                          )}`
                        : order.productName}
                      <p>
                        ASIN: {order.asin} - SKU: {order.sellerSku} - Stok:{" "}
                        {order.totalQuantity}
                      </p>
                    </td> 
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {addCurrencySymbol(order.totalRevenue, marketplaceName)}
                    </td>                    
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {order.orderCount}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {order.refundCount}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      %
                      {((order.refundCount / order.orderCount) * 100).toFixed(
                        2
                      )}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {addCurrencySymbol(
                        Number(order.totalRefunds) +
                          Number(order.totalRefundCommission),
                        marketplaceName
                      )}
                    </td>

                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {addCurrencySymbol(
                        Number(order.totalFBAFees) +
                          Number(order.totalCommission),
                        marketplaceName
                      )}
                    </td>

                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      <div className="flex items-center">
                        <p>
                          {addCurrencySymbol(
                            Number(order.totalProductCogs) +
                              Number(order.totalShippingCogs) +
                              Number(order.totalOtherCogs),
                            marketplaceName
                          )}
                        </p>
                        <button
                          onClick={() => handleOpenModal(order.sellerSku)}
                          className="ml-2"
                        >
                          <PencilIcon className="h-4 w-4 text-blue-500 hover:text-blue-700" />
                        </button>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm border-b border-gray-200 text-gray-900">
                      {addCurrencySymbol(
                        Number(
                          (
                            Number(order.totalRevenue) +
                            Number(order.totalFBAFees) +
                            Number(order.totalProductCogs) +
                            Number(order.totalShippingCogs) +
                            Number(order.totalCommission) +
                            Number(order.totalOtherCogs) +
                            Number(order.totalRefunds) +
                            Number(order.totalRefundCommission)
                          ).toFixed(2)
                        ),
                        marketplaceName
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
