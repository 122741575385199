import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import Notification from "../../components/general/Notification";

interface DatePickerProps {
  selected: {
    title: string;
    current: boolean;
  };
  setSelected: React.Dispatch<
    React.SetStateAction<{
      title: string;
      current: boolean;
    }>
  >;
  showDatePicker: boolean;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDate: React.Dispatch<
    React.SetStateAction<{
      startDate: string;
      endDate: string;
    }>
  >;
  handleOptionSelect: (option: any) => void;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  dayDiff: number;
  setIsDaily: React.Dispatch<React.SetStateAction<boolean>>;
}

const publishingOptions = [
  { title: "Aylık", current: false },

  { title: "Günlük", current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const CustomDatePickerDaily: React.FC<DatePickerProps> = ({
  selected,
  setSelected,
  showDatePicker,
  setShowDatePicker,
  setSelectedDate,
  handleOptionSelect,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dayDiff,
  setIsDaily,
}) => {
  //to set datepickeres end date
  const [show, setShow] = useState(false);
  const baseDate = new Date();
  baseDate.setDate(baseDate.getDate() - dayDiff);

  // make date-pickers language to tr
  registerLocale("tr", tr);
  setDefaultLocale("tr");

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setSelectedDate({
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      });

      setSelected({
        title: `${start.toLocaleDateString("tr-TR")} - ${end.toLocaleDateString(
          "tr-TR"
        )}`,
        current: true,
      });
      setShowDatePicker(false);
      setIsDaily(true);
    }
  };

  return (
    <div className="relative ">
      <Notification
        showNotification={show}
        setShowNotification={setShow}
        title="Hata!"
        message="Beklenmeyen bir hata oluştu!"
      />
      <Listbox value={selected} onChange={handleOptionSelect}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">
              Change published status
            </Listbox.Label>
            <div className="inline-flex divide-x divide-[#6F7AC3]rounded-md shadow-sm  justify-end">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-[#6F7AC3] px-3 py-2 text-white shadow-sm min-w-[200px]">
                <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                <p className="text-sm font-semibold">{selected.title}</p>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-[#6F7AC3] p-2 hover:bg-[#6F7AC3] focus:outline-none focus:ring-2 focus:ring-[#6F7AC3] focus:ring-offset-2 focus:ring-offset-gray-50">
                <span className="sr-only">Change published status</span>
                <ChevronDownIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                  onClick={() => setShowDatePicker(false)}
                />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {publishingOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-[#6F7AC3] text-white" : "text-gray-900",
                        "cursor-default select-none p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? "font-semibold" : "font-normal"
                            }
                          >
                            {option.title}
                          </p>
                          {selected && (
                            <span
                              className={
                                active ? "text-white" : "text-[#6F7AC3]"
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
      {showDatePicker && (
        <div className="absolute top-12 right-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-2 p-2 z-10">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            // minDate={baseDate}
            onBlur={() => setShow(false)}
            maxDate={new Date()}
            endDate={endDate}
            selectsRange
            inline
            monthsShown={1}
            locale="tr"
          />
        </div>
      )}
    </div>
  );
};
export default CustomDatePickerDaily;
