import { useState, FormEvent } from "react";
import axios from "axios";

interface HabitFormProps {
  onHabitAdded?: () => void;
  userId?: string;
}

const HabitForm: React.FC<HabitFormProps> = ({ onHabitAdded,userId }) => {
  const [name, setName] = useState("");

  let url = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${url}/api/habits`, {
        name,
        user: userId,
      });
  
      // Reset form fields
      setName("")
  
      console.log("Habit created:", response.data.data);
  
      // Notify parent component that a transaction was added
      if (onHabitAdded) {
        onHabitAdded();
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error creating habit:", error.message);
      } else {
        console.error("Error creating habit:", error);
      }
    }
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-11/12 px-4 py-8 mx-auto">
        <div className="flex gap-4 items-end">
          <div className="flex-grow">
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Takip Etmek İstediğiniz Alışkanlık Başlığı
            </label>
            <input
              type="text"
              id="title"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
              placeholder="10 sayfa kitap oku"
            />
          </div>
    
          <button
            type="submit"
            className="px-3 py-1.5 text-sm font-medium text-black bg-yellow-400 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
          >
            Ekle
          </button>
        </div>
      </div>
    </form>
  );
};

export default HabitForm;
